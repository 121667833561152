import React, { useEffect } from 'react';
import '../MerchantEdit/MerchantDataForm.scss';
import { identity } from "../../utils/getIdentity";
import '../../layout/Modal.scss';
import { Button, Form, Input, Modal } from "antd";
import { useTranslation } from "react-i18next";

const SecurityVerifyModal = ({
  isVisible,
  handleCancel,
  handleSubmit,
  twoFactorToken,
  twoFactorTokens,
  emailOtp,
  emailOtps,
  verifyCode,
  verifyCodes,
  setTwoFactorTokens,
  setEmailOtps,
  setVerifyCodes,
  merchantSecurityChange
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  useEffect(() => {
    if (merchantSecurityChange) {
      form.setFieldsValue({
        verifyCode: '',
        emailOtp: '',
        twoFactorToken: ''
      })
    }
    handleCancel();
  }, [merchantSecurityChange]);

  const onCancel = () => {
    form.setFieldsValue({
      verifyCode: '',
      emailOtp: '',
      twoFactorToken: ''
    })
    handleCancel();
  }

  return (
    <Modal
      width={534}
      visible={isVisible}
      onOk={handleSubmit}
      onCancel={onCancel}
      footer={null}
      className={`modal modal-${identity}`}
    >
      <Form className="security-modal" form={form}>
        {verifyCode && !emailOtp && !twoFactorToken && (
          <Form.Item
            label={t('auth.сodeFromSms')}
            name="verifyCode"
            className="modal-form__label security-modal__wrap"
            rules={[{ required: true, message: t('validation.required') }]}
          >
            <Input
              placeholder={t('auth.сodeFromSms')}
              className="modal-form__input"
              maxLength="6"
              onChange={e => setVerifyCodes(e.target.value)}
            />
          </Form.Item>
        )}
        {emailOtp && !twoFactorToken && (
          <Form.Item
            label={t('auth.keyFromEmail')}
            name="emailOtp"
            className="modal-form__label security-modal__wrap"
            rules={[{ required: true, message: t('validation.required') }]}
          >
            <Input
              placeholder={t('auth.keyFromEmail')}
              className="modal-form__input"
              maxLength="6"
              onChange={e => setEmailOtps(e.target.value)}
            />
          </Form.Item>
        )}
        {twoFactorToken && (
          <Form.Item
            label={t('auth.keyFromGA')}
            name="twoFactorToken"
            className="modal-form__label security-modal__wrap"
            rules={[{ required: true, message: t('validation.required') }]}
          >
            <Input
              placeholder={t('auth.keyFromGA')}
              className="modal-form__input"
              maxLength="6"
              onChange={e => setTwoFactorTokens(e.target.value)}
            />
          </Form.Item>
        )}

        <Button
          type="primary"
          className={`modal__button modal__button-create modal__button modal__button-create-${identity} modal__button-create=${identity}`}
          onClick={handleSubmit}
          disabled={
            (verifyCode && !emailOtp && !twoFactorToken && verifyCodes?.length < 6) ||
            (emailOtp && !twoFactorToken && emailOtps?.length < 6) ||
            (twoFactorToken && twoFactorTokens?.length < 6)
          }
        >
          Submit
        </Button>
      </Form>
    </Modal>
  )
};

export default SecurityVerifyModal;