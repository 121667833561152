import React, { useState, useEffect,useRef } from 'react';
import { connect } from 'react-redux';
import { Form } from 'antd';
import Card from '../../components/Card';
import TEXT from '../../components/Text';
import { formValidate } from './formValidate';
import { RangePicker, InputSelect } from '../../components/FormFields';
import { getCurrencyPriceById } from '../../../redux/actions/adminPanel/adminCurrencyPrice';
import { ButtonPrimary } from '../../components/Button';
import CurrencyDropdown from '../../components/CurrencyDropdown';
import './style.scss';
import { setAdminFiltersPriceChart } from '../../../redux/actions/adminPanel/adminPageFilters';
import Graph from '../../components/Graph';
import {scrollContainerIntoView} from "../../helpers/scrollIntoView";

const errorInitialState = { dateForFiltering: null, currencyId: null };

const PriceChart = ({
  typeNetwork,
  getCurrencyPriceById,
  currencyIsFetched,
  currencyRatesEntities,
  currencyChosen,
  dateFromChosen,
  dateToChosen,
  setAdminFiltersPriceChart,
  adminFiltersPagePriceChart,
  permissions
}) => {
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState(null);
  const [dataType, setDataType] = useState(adminFiltersPagePriceChart?.typeOfData || 'amountUSD');
  const [error, setError] = useState({ ...errorInitialState });
  const [chosenCurrencyTitle, setCurrencyTitle] = useState(null);
  const [currencyObject, setCurrencyObject] = useState(null);
  const priceChartRef  = useRef(null);

  const [currencyId, setCurrencyId] = useState(adminFiltersPagePriceChart?.currencyId || undefined);
  const [dateFilter, setDateFilter] = useState(adminFiltersPagePriceChart?.dateForFiltering || undefined)
  const [showChart, setShowChart] = useState(currencyRatesEntities);

  const setCurrencies = values => {
    form.setFieldsValue({ currencyId: values });
  };

  const setCurrencyName = currencyName => {
    setCurrencyTitle(currencyName);
  };

  const handleClick = () => {
    if (!formValidate(setError, form, errorInitialState)) {
      return;
    }

    setAdminFiltersPriceChart({
      ...form.getFieldsValue(),
      currencyTitle: chosenCurrencyTitle,
      selectedCurrencyObj: currencyObject,
      typeOfData: dataType,
    });

    getCurrencyPriceById({
      ...form.getFieldsValue(true),
      typeNetwork,
      typeOfData: undefined,
      currencyTitle: chosenCurrencyTitle,
    });
  };

  const handleChange = e => {
    setDataType(e);
    setAdminFiltersPriceChart({ typeOfData: e });
  };

  useEffect(() => {
    if(currencyRatesEntities){
      setShowChart(currencyRatesEntities)
    }
  }, [currencyRatesEntities])

  useEffect(() => {
    if (error) {
      setTimeout(() => setError(null), 2000);
    }
  }, [error]);

  useEffect(() => {
    if (adminFiltersPagePriceChart) {
      getCurrencyPriceById({
        ...adminFiltersPagePriceChart,
        typeNetwork,
        typeOfData: undefined,
        selectedCurrencyObj: undefined,
      });
    }
  }, []);

  useEffect(()=>{
    scrollContainerIntoView(priceChartRef);
  },[]);

  const clearFilters = () => {
    form.resetFields();
    setAdminFiltersPriceChart({
      currencyId: '',
      dateForFiltering: undefined,
      currencyTitle: '',
      selectedCurrencyObj: undefined,
      typeOfData: "amountUSD"
    });
    setDateFilter(undefined)
    setCurrencyTitle(null)
    setCurrencyId('')
    setCurrencyObject(undefined)
    setShowChart([])
   }

  return (
    <>
      <div className="admin-price-chart" ref={priceChartRef}>
        <Card>
          <TEXT.pageTitle>Price chart</TEXT.pageTitle>
          <div className="admin-price-chart__filters">
            <Form
              form={form}
              name="admin-price-chart__form"
              initialValues={{
                dateForFiltering: dateFilter,
                typeOfData: adminFiltersPagePriceChart?.typeOfData || 'amountUSD',
                currencyId: currencyId,
              }}
              onFieldsChange={() => {
                setFormValues(form.getFieldsValue(true));
              }}
              className="admin-price-chart__form"
            >
              <div className="admin-price-chart__form-wrapper">
                <Form.Item name="currencyId" valuePropName="merchantCurrencies">
                  <CurrencyDropdown
                    setFunc={setCurrencies}
                    noTokens={false}
                    setCurrencyName={setCurrencyName}
                    setCurrencyObject={setCurrencyObject}
                    preselectedCurrency={adminFiltersPagePriceChart?.selectedCurrencyObj}
                  />
                </Form.Item>

                {error?.currencyId && (
                  <div className="admin-price-chart__form-error">
                    <p>Please, choose a currency</p>
                  </div>
                )}
              </div>

              <div className="admin-price-chart__form-wrapper">
                <RangePicker
                  name="dateForFiltering"
                  placeholder="Choose date for filtering"
                  submitAction={form.submit}
                  formValues={formValues}
                  withInitial={adminFiltersPagePriceChart?.dateForFiltering}
                  clearAction={() => {
                    form.setFieldsValue({ dateForFiltering: '' });
                    setAdminFiltersPriceChart({ dateForFiltering: '' });
                  }}
                />

                {error?.dateForFiltering && (
                  <div className="admin-price-chart__form-error">
                    <p>Please, choose the dates</p>
                  </div>
                )}
              </div>

              {showChart?.length ? (
                <InputSelect
                  name="typeOfData"
                  dropdownMatchSelectWidth={false}
                  placeholder="Choose type of data"
                  options={[
                    { value: 'amount', label: 'Amount' },
                    { value: 'amountUSD', label: 'Amount USD' },
                  ]}
                  onChange={handleChange}
                  style={{
                    width: 180,
                  }}
                  value={dataType}
                />
              ) : null}

              <div className="admin-users__add-user__button-container">
                <ButtonPrimary
                  className="admin-users__add-user-button"
                  onClick={handleClick}
                >
                  Get info
                </ButtonPrimary>
              </div>
            </Form>
          </div>

          {!currencyIsFetched && showChart?.length ? (
            <div className="admin-price-chart__data-wrapper">
              <p>Currency: {currencyChosen}</p>
              <p>Date from: {dateFromChosen}</p>
              <p>Date to: {dateToChosen}</p>
              <p style={{cursor: 'pointer', fontWeight: '600'}} onClick={() => clearFilters()}>Clear filters</p>
            </div>
          ) : null}

          <div style={{ width: '950px' }}>
            {showChart?.length ? (
              <Graph data={showChart} dataKey={dataType} labelForXAxis="Dates" />
            ) : null}
          </div>
        </Card>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  typeNetwork: state.networkFilter,
  currencyIsFetched: state.adminCurrencyPrice.fetching,
  currencyRatesEntities: state.adminCurrencyPrice.entities,
  currencyChosen: state.adminCurrencyPrice.currentCurrency,
  dateFromChosen: state.adminCurrencyPrice.currentDateFrom,
  dateToChosen: state.adminCurrencyPrice.currentDateTo,
  adminFiltersPagePriceChart: state.adminFilters.priceChart,
  permissions: state.adminSettings.permissions,
});

const mapDispatchToProps = {
  getCurrencyPriceById,
  setAdminFiltersPriceChart,
};

export default connect(mapStateToProps, mapDispatchToProps)(PriceChart);
