import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import Button from '../Button';

import { showWelcomeModal, showAppTour } from '../../redux/actions/user';

import logoCpay from '../../img/footer/footerLogo.svg';
import logoNfg from '../../img/nfgpay-svg/nfg_logo_black.svg';
import logoFinvaro from '../../img/finvaro/finvaro-logo-black.png';
import logoClarnium from '../../img/clarnium/clarnium-logo-color.png';
import '../../layout/Modal.scss';
import './style.scss';
import { AppConfig } from '../../config';
import { identity } from '../../utils/getIdentity';

const logos = {
  cpay: logoCpay,
  nfg: logoNfg,
  finvaro: logoFinvaro,
  clarnium: logoClarnium
}

const WelcomeModal = ({ isShowWelcomeModal, setShowWelcomeModal, setShowAppTour }) => {
  const { t } = useTranslation();

  const closeModal = () => {
    if (identity !== 'finvaro' && identity !== 'clarnium') {
      setShowWelcomeModal(false);
      setShowAppTour(true);
    } else {
      setShowWelcomeModal(false);
    }
  }

  return (
    <Modal
      width={820}
      visible={isShowWelcomeModal}
      onCancel={closeModal}
      footer={null}
      className={`modal modal-${identity} welcome-modal`}
    >
      <div className={`welcome-modal__logo welcome-modal__logo-${identity}`}>
        <img src={logos[AppConfig.identity]} alt="Logo" />
      </div>
      <span className={`welcome-modal__title welcome-modal__title-${identity}`}>{t('welcomeModal.title')}</span>
      <p className="welcome-modal__text">{t('welcomeModal.textMain')} {identity ==='cpay' && t('welcomeModal.textSecondary')}</p>
      {identity === 'cpay' && (
        <a href="https://docs.cpay.world/" target="_blank" rel="noopener noreferrer">
        <Button type="primary" className="welcome-modal__button">
          {t('welcomeModal.help')}
        </Button>
      </a>
      )}
      <Button
        type="secondary"
        className="welcome-modal__button"
        onClick={closeModal}
      >
        {t('welcomeModal.ok')}
      </Button>
    </Modal>
  );
};

const mapStateToProps = state => ({
  isShowWelcomeModal: state.user.showWelcomeModal,
});

const mapDispatchToProps = dispatch => ({
  setShowWelcomeModal: data => dispatch(showWelcomeModal(data)),
  setShowAppTour: data => dispatch(showAppTour(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WelcomeModal);
