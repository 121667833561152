import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, Dropdown, Radio, Checkbox, Spin } from 'antd';
import { getUserTransactions, getMoreUserTransactions } from '../../../redux/actions/adminPanel/adminUserTransactions';
import { InputSearch, RangePicker } from '../FormFields';
import CurrencyDropdown from '../CurrencyDropdown';
import { DownOutlined, LoadingOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import {
  getAdminTransactionsByUser,
  getAdminMoreTransactionsByUser,
} from '../../../redux/actions/adminPanel/adminTransactionsByUser';
import { downloadAdminTransactionsInfo } from '../../../redux/actions/transactions';
import UserTransactionsMarkUpList from './UserTransactionsMarkUpList';
import { dateFormatterForRequest } from './helpers';
import { setAdminFiltersTransactions } from '../../../redux/actions/adminPanel/adminPageFilters';
import InputFeeForFilterTransactions from './InputFeeForFilterTransactions';
import './style.scss';
import { getUserFeeTransactions } from '../../../redux/actions/adminPanel/adminDashboard';
import { sanitizeSearchInputDisable } from '../../../utils/sanitizeInput';
import Button from '../../../components/Button';
import { ReactComponent as ImportSVG } from '../../../img/default-svg/import.svg';

const UserTransactionsList = ({
  transactions,
  getUserTransactions,
  transactionsFetching,
  getMoreUserTransactions,
  moreTransactionsFetching,
  countItem,
  page,
  typeNetwork,
  userSearchParams,
  getAdminTransactionsByUser,
  isFromTransactionsByUser,
  entitiesOfTransactionsByUser,
  fetchingTransactionsByUser,
  countTransactionsByUser,
  pageTransactionsByUser,
  fetchingMoreTransactionsByUser,
  getAdminMoreTransactionsByUser,
  userName,
  setAdminFiltersTransactions,
  adminFiltersTransactions,
  getUserFeeTransactions,
  getUserFeeTransactionsState,
  userId,
  transInfoDownloadFetching,
  downloadAdminTransactionsInfo,
  permissions,
  changeDate
}) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const { data: userFeeData } = getUserFeeTransactionsState;
  // eslint-disable-next-line no-unused-vars
  const [chosenCurrencyTitle, setCurrencyTitle] = useState(null);
  const [currencyObject, setCurrencyObject] = useState(null);
  const [error, setError] = useState(null);
  const timerRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    const formValues = form.getFieldsValue();
    let formattedData;

    if (adminFiltersTransactions) {
      handleTransactionsOnFiltersChange({ ...adminFiltersTransactions, selectedCurrencyObj: undefined });
      return;
    }

    if (formValues?.dateRange?.length > 0) {
      formattedData = dateFormatterForRequest(formValues);
    }

    if (!isFromTransactionsByUser) {
      formValues?.dateRange?.length > 0
        ? getUserTransactions({ ...formattedData, typeNetwork })
        : getUserTransactions({ ...formValues, typeNetwork });
    }

    if (userSearchParams && isFromTransactionsByUser) {
      formValues?.dateRange?.length > 0
        ? getAdminTransactionsByUser({ ...formattedData, user: userSearchParams, typeNetwork })
        : getAdminTransactionsByUser({ ...formValues, user: userSearchParams, typeNetwork });
    }
  }, [typeNetwork, userSearchParams]);

  const setCurrency = value => {
    form.setFieldsValue({ currencyId: value });
  };

  const setCurrencyName = currencyName => {
    setCurrencyTitle(currencyName);
  };

  const handleLoadMoreClick = () => {
    const formValues = form.getFieldsValue();
    const formattedData =
      formValues.dateRange && formValues.dateRange.length ? dateFormatterForRequest(formValues) : formValues;

    if (isFromTransactionsByUser && !fetchingMoreTransactionsByUser) {
      formValues?.dateRange?.length > 0
        ? getAdminMoreTransactionsByUser({
            ...formattedData,
            user: userSearchParams,
            typeNetwork,
            page: pageTransactionsByUser + 1,
          })
        : getAdminMoreTransactionsByUser({
            ...formValues,
            user: userSearchParams,
            typeNetwork,
            page: pageTransactionsByUser + 1,
          });
      return;
    }

    if (!isFromTransactionsByUser && !moreTransactionsFetching) {
      formValues?.dateRange?.length > 0
        ? getMoreUserTransactions({
            ...formattedData,
            onlyNft: formattedData.onlyNft || undefined,
            nftType: formattedData.onlyNft ? formattedData.nftType : undefined,
            page: page + 1,
            typeNetwork,
          })
        : getMoreUserTransactions({
            ...formValues,
            onlyNft: formValues.onlyNft || undefined,
            nftType: formValues.onlyNft ? formValues.nftType : undefined,
            page: page + 1,
            typeNetwork,
          });
    }
  };

  const handleTransactionsOnFiltersChange = data => {
    if (data?.dateRange?.length > 0) {
      const formattedData = dateFormatterForRequest(data);
      !isFromTransactionsByUser
        ? getUserTransactions({
            ...formattedData,
            onlyNft: formattedData.onlyNft || undefined,
            nftType: formattedData.onlyNft ? formattedData.nftType : undefined,
            typeNetwork,
          })
        : getAdminTransactionsByUser({
            ...formattedData,
            onlyNft: formattedData.onlyNft || undefined,
            nftType: formattedData.onlyNft ? formattedData.nftType : undefined,
            typeNetwork,
            user: userSearchParams,
          });
      return;
    }

    !isFromTransactionsByUser
      ? getUserTransactions({
          ...data,
          onlyNft: data.onlyNft || undefined,
          nftType: data.onlyNft ? data.nftType : undefined,
          typeNetwork,
        })
      : getAdminTransactionsByUser({
          ...data,
          onlyNft: data.onlyNft || undefined,
          nftType: data.onlyNft ? data.nftType : undefined,
          typeNetwork,
          user: userSearchParams,
        });
  };

  const [initialFormState, setInitialFormState] = useState({
    search: adminFiltersTransactions?.search || '',
    order: adminFiltersTransactions?.order || 'DESC',
    dateRange: adminFiltersTransactions?.dateRange || [],
    type:
      adminFiltersTransactions?.type ||
      'replenishment,withdrawal,multisend,systemFee,Error,refund,InternalTransfer,ExternalCall',
    currencyId: adminFiltersTransactions?.currencyId,
    onlyNft: undefined,
    nftType: undefined,
  });

  useEffect(() => {
    const unlisten = history.listen(() => {
      console.log('User is leaving the page');
      setAdminFiltersTransactions({
        dateRange: [],
        search: '',
        order: 'DESC',
        type: 'replenishment,withdrawal,multisend,systemFee,Error,refund,InternalTransfer,ExternalCall',
        currencyId: undefined,
        selectedCurrencyObj: undefined,
        onlyNft: undefined,
        nftType: undefined,
      });
    });

    return () => {
      console.log('Component is unmounting');
      unlisten();
      setAdminFiltersTransactions({
        dateRange: [],
        search: '',
        order: 'DESC',
        type: 'replenishment,withdrawal,multisend,systemFee,Error,refund,InternalTransfer,ExternalCall',
        currencyId: undefined,
        selectedCurrencyObj: undefined,
        onlyNft: undefined,
        nftType: undefined,
      });
    };
  }, [history]);

  useEffect(() => {
    const formValues = form.getFieldsValue();
    let formattedData;
    if (formValues?.dateRange?.length > 0) {
      formattedData = dateFormatterForRequest(formValues);
    }
    if (userId) {
      if (userSearchParams && isFromTransactionsByUser) {
        if (userSearchParams && isFromTransactionsByUser) {
          getUserFeeTransactions({
            from: formattedData?.formattedDate[0] || undefined,
            to: formattedData?.formattedDate[1] || undefined,
            user: userId,
            typeNetwork,
            currencyId: adminFiltersTransactions?.currencyId || undefined,
          });
        }
      }
    }
  }, [
    userId,
    typeNetwork,
    userSearchParams,
    adminFiltersTransactions?.dateRange,
    adminFiltersTransactions?.currencyId,
  ]);

  const handleFormSubmit = e => {
    e.preventDefault();
    form?.getFieldsValue(true)?.dateRange?.length < 2 || !form.getFieldsValue().dateRange
      ? setError(true)
      : downloadAdminTransactionsInfo(form.getFieldsValue(true), typeNetwork);
  };

  useEffect(() => {
    timerRef.current = null;
    if (form.getFieldsValue()?.dateRange?.length === 2 && error) {
      timerRef.current = setTimeout(() => setError(null), 100);
    }
  }, [form.getFieldsValue(), error]);

  useEffect(() => {
    return () => {
      clearTimeout(timerRef.current);
    };
  }, []);

  useEffect(() => {
    if (form.getFieldValue('dateRange') && isFromTransactionsByUser) {
      changeDate(form.getFieldValue('dateRange'))
    }
  }, [form.getFieldValue('dateRange')]);

  return (
    <div className="fee-transactions">
      <Form
        form={form}
        name="admin-fee-transactions-form"
        initialValues={initialFormState}
        onFinish={values => {
          setAdminFiltersTransactions({
            dateRange: form.getFieldValue('dateRange'),
            search: form.getFieldValue('search'),
            order: form.getFieldValue('order'),
            type: form.getFieldValue('type'),
            currencyId: form.getFieldValue('currencyId'),
            onlyNft: form.getFieldValue('onlyNft'),
            nftType: form.getFieldValue('nftType'),
            selectedCurrencyObj: currencyObject,
          });

          handleTransactionsOnFiltersChange({ ...values });
        }}
      >
        <div className={`fee-transactions__filters ${!isFromTransactionsByUser ? 'fee-transactions__filters__custom' : ''}`}>
          <InputSearch
            name="search"
            onSearch={() => {
              // form.submit();
              const searchWords = form?.getFieldsValue()?.search;
              if (searchWords) {
                !sanitizeSearchInputDisable(searchWords) && form.submit();

                if (sanitizeSearchInputDisable(searchWords)) {
                  form.setFieldsValue({ search: '' });
                  setAdminFiltersTransactions({ search: '' });
                  form.submit();
                }
              }
            }}
            onReset={() => {
              form.setFieldsValue({ search: '' });
              setAdminFiltersTransactions({ search: '' });
              form.submit();
            }}
            // disabled={isFromTransactionsByUser}
          />

          <div className="transaction-filters__sorting">
            <Dropdown
              overlay={
                <div className="transaction-filters__currencies-dropdown">
                  <Form.Item name="order">
                    <Radio.Group onChange={form.submit}>
                      <Radio value="DESC">Newest on top</Radio>
                      <Radio value="ASC">Oldest on top</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <div className="dropdown-divider"></div>
                  <Form.Item name="type">
                    <Radio.Group onChange={form.submit}>
                      <Radio value="replenishment,withdrawal,multisend,systemFee,Error,refund,InternalTransfer,ExternalCall">
                        All
                      </Radio>
                      <Radio value="replenishment">Replenishment</Radio>
                      <Radio value="withdrawal">Withdrawal</Radio>
                      <Radio value="multisend">Multisend</Radio>
                      <Radio value="systemFee">System Fee</Radio>
                      <Radio value="Error">Error</Radio>
                      <Radio value="refund">Refund</Radio>
                      <Radio value="InternalTransfer">Internal Transfer</Radio>
                      <Radio value="ExternalCall">External Call</Radio>
                    </Radio.Group>
                  </Form.Item>

                  <div className="dropdown-divider"></div>

                  <Form.Item className="form-item__no-margin" name="onlyNft" valuePropName="checked">
                    <Checkbox onChange={form.submit}>Only NFT</Checkbox>
                  </Form.Item>
                  <Form.Item className="form-item__no-margin" name="nftType">
                    <Radio.Group onChange={form.submit} disabled={!form.getFieldValue('onlyNft')}>
                      <Radio value={undefined}>All</Radio>
                      <Radio value="erc721">NFT ERC-721</Radio>
                      <Radio value="erc1155">NFT ERC-1155</Radio>
                    </Radio.Group>
                  </Form.Item>
                </div>
              }
            >
              <div className="transaction-filters__currencies-button">
                Sorting <DownOutlined />
              </div>
            </Dropdown>
          </div>
          <Form.Item name="currencyId" valuePropName="merchantCurrencies">
            <CurrencyDropdown
              setFunc={setCurrency}
              onChangeAction={form.submit}
              setCurrencyName={setCurrencyName}
              setCurrencyObject={setCurrencyObject}
              preselectedCurrency={adminFiltersTransactions?.selectedCurrencyObj}
            />
          </Form.Item>
          <div className="fee-transactions__dateRange-wrapper">
            <RangePicker
              formValues={form.getFieldValue('dateRange')}
              name="dateRange"
              placeholder="Choose date of transaction"
              submitAction={form.submit}
              clearAction={() => {
                form.setFieldsValue({ dateRange: '' });
                setAdminFiltersTransactions({ dateRange: '' });
              }}
            />
            {!isFromTransactionsByUser && error && (
              <div className="fee-transactions__dateRange-wrapper_download-error">
                <p>{t('validation.required')}</p>
              </div>
            )}
          </div>
          {!isFromTransactionsByUser && (
            <div className="transaction-filters__download_container">
              <Button
                className="fee-transactions__download_button"
                type="submit"
                onClick={handleFormSubmit}
                disabled={permissions && permissions?.length > 0 && !permissions[5]?.edit}
              >
                {!transInfoDownloadFetching ? (
                  <ImportSVG />
                ) : (
                  <div className="fee-transactions__download_button__loader">
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 16, color: 'white' }} spin />} />
                  </div>
                )}
              </Button>
            </div>
          )}
        </div>
        {isFromTransactionsByUser && (
          <div className="wrapperForInputFeeFilter">
            <InputFeeForFilterTransactions title={'Replenishment'} amount={userFeeData?.replenishment} />
            <InputFeeForFilterTransactions title={'Withdrawal'} amount={userFeeData?.withdrawal} />
            <InputFeeForFilterTransactions title={'System Fee'} amount={userFeeData?.systemFee} />
          </div>
        )}

        {!isFromTransactionsByUser ? (
          <UserTransactionsMarkUpList
            arrayData={transactions}
            fetchingFlag={transactionsFetching}
            typeNetwork={typeNetwork}
            form={form}
            page={page}
            countItem={countItem}
            handleLoadMoreClick={handleLoadMoreClick}
          />
        ) : (
          <UserTransactionsMarkUpList
            arrayData={entitiesOfTransactionsByUser}
            fetchingFlag={fetchingTransactionsByUser}
            typeNetwork={typeNetwork}
            form={form}
            page={pageTransactionsByUser}
            countItem={countTransactionsByUser}
            handleLoadMoreClick={handleLoadMoreClick}
            isFromTransactionsByUser
            userSearchParams={userSearchParams}
            userName={userName}
          />
        )}
      </Form>
    </div>
  );
};

const mapStateToProps = state => ({
  transactions: state.adminUserTransactions.entities,
  transactionsFetching: state.adminUserTransactions.fetching,
  moreTransactionsFetching: state.adminUserTransactions.fetchingMore,
  countItem: state.adminUserTransactions.countItem,
  page: state.adminUserTransactions.page,
  typeNetwork: state.networkFilter,
  currentUserTransactionsData: state.adminTransactionsByUser,
  entitiesOfTransactionsByUser: state.adminTransactionsByUser.entities,
  fetchingTransactionsByUser: state.adminTransactionsByUser.fetching,
  countTransactionsByUser: state.adminTransactionsByUser.countItem,
  pageTransactionsByUser: state.adminTransactionsByUser.page,
  fetchingMoreTransactionsByUser: state.adminTransactionsByUser.fetchingMore,
  adminFiltersTransactions: state.adminFilters.transactions,
  getUserFeeTransactionsState: state.adminDashboard.getUserFeeTransactions,
  transInfoDownloadFetching: state.transactions.download.fetching,
  permissions: state.adminSettings.permissions,
});

const mapDispatchToProps = {
  getUserTransactions,
  getMoreUserTransactions,
  getAdminTransactionsByUser,
  getAdminMoreTransactionsByUser,
  setAdminFiltersTransactions,
  getUserFeeTransactions,
  downloadAdminTransactionsInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserTransactionsList);
