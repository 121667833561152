import React, { useState, useEffect } from "react";
import { Row, Col, Checkbox } from "antd";
import { useTranslation } from "react-i18next";
import useWindowSize from "../../utils/resizeHook";
import { identity } from "../../utils/getIdentity";
import Button from "../Button";

const UnsubscribeLetter = ({ mailSubscriptionStatus, notifySubscriptionStatus, unsubscribeEmail, getUser }) => {
  const { t } = useTranslation();
  const { currentWidth } = useWindowSize();

  const [mailStatus, setMailStatus] = useState(false);
  const [notifyStatus, setNotifyStatus] = useState(false);

  useEffect(() => {
    setMailStatus(mailSubscriptionStatus);
    setNotifyStatus(notifySubscriptionStatus);
  }, [mailSubscriptionStatus, notifySubscriptionStatus]);

  const updateSubscription = () => {
    if (mailStatus !== mailSubscriptionStatus) {
      unsubscribeEmail();
    }

    if (notifyStatus !== notifySubscriptionStatus) {
      unsubscribeEmail({ isNotify: true });
    }

    setTimeout(() => {
      getUser();
    }, 300)
  };

  return (
    <>
      <div className="profile-block">
        <span className={`profile__title profile__title-${identity}`}>
          {t("profile.subscriptions")}
        </span>
        <div>
          <div>
            <Checkbox checked={mailStatus} onChange={e => setMailStatus(e.target.checked)} />
            <span className="profile-block--text">{t("profile.unsubscribeInfo")}</span>
          </div>
          <div>
            <Checkbox checked={notifyStatus} onChange={e => setNotifyStatus(e.target.checked)}  />
            <span className="profile-block--text">{t("profile.unsubscribeNotify")}</span>
          </div>
        </div>
        <Row gutter={[20, 0]}>
          <Col span={currentWidth >= 1024 ? 4 : 24}>
            <Button type="primary" className="form__button" onClick={updateSubscription}>
              {t("profile.updateSubscriptions")}
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default UnsubscribeLetter;