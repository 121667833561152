import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, InputNumber, Empty } from 'antd';
import { cryptoApi } from '../../../service/cryptopay-api';
import { truncate } from '../../../utils/truncateFunction';
import { checkAutosignStatus } from "../../../redux/actions/withdraw";
import { notificationsSuccessShow, notificationsErrorShow } from '../../../redux/actions/notifications';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from './style.module.scss';
import { identity } from '../../../utils/getIdentity';
import ResendPhone2FA from '../../../ResendPhone2FA';
import ResendEmail2FA from '../../ResendEmail2FA';
import UploadAutosignWrapper from "../../Withdraw/UploadAutosignWrapper";
import uploadFile from "../../../img/cpay-svg/upload.svg";
import Button from '../../Button';

const NftWithdraw = ({
  merchantId,
  selectedNft,
  setSelectedNft,
  notificationsSuccessShow,
  notificationsErrorShow,
  onSuccess,
  checkAutosignStatus,
  autosignStatus,
  autosignStep
}) => {
  const [isOpenAutosignModal, setIsOpenAutosignModal] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (selectedNft) {
      checkAutosignStatus(merchantId, selectedNft?.walletId);
    }
  }, [selectedNft]);

  const [estimate, setEstimate] = useState(null);
  const [showApp2fa, setShowApp2fa] = useState(false);
  const [showEmail2fa, setShowEmail2fa] = useState(false);
  const [showSMS2fa, setShowSMS2fa] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [sign, setSign] = useState('');
  const [password, setPassword] = useState('');
  const [form] = Form.useForm();

  const uploadAutosign = data => {
    const { password, sign } = data;
    setPassword(password || undefined);
    setSign(sign || undefined);

    setIsOpenAutosignModal(false);
  };

  const handleCancel = () => {
    setSign('');
    setPassword('');
    setSelectedNft(null);
    setEstimate(null);
    setShowApp2fa(false);
    setShowEmail2fa(false);
    setIsFetching(false);
    setShowSMS2fa(false);
    form.resetFields();
  };

  const estimateNft = async (address, amount) => {
    setIsFetching(true);

    try {
      const response = await cryptoApi.nftEstimateWithdraw(merchantId, selectedNft.walletId, {
        to: address,
        amount: amount.toString(),
        contractAddress: selectedNft.contractAddress,
        tokenId: selectedNft.tokenId,
        type: selectedNft.network,
        sign: sign || undefined,
        password: password || undefined
      });

      setEstimate(response.data);
    } catch (error) {
      notificationsErrorShow({ message: error.data.message });
    }

    setIsFetching(false);
  };

  const withdraw = async values => {
    await form.validateFields();
    setIsFetching(true);

    try {
      const response = await cryptoApi.nftWithdraw(merchantId, selectedNft.walletId, {
        to: values.address,
        amount: values.amount.toString(),
        contractAddress: selectedNft.contractAddress,
        tokenId: selectedNft.tokenId,
        type: selectedNft.network,
        twoFactorToken: values.twoFactorToken || undefined,
        emailOtp: values.emailOtp || undefined,
        verifyCode: values.verifyCode || undefined,
        sign: sign || undefined,
        password: password || undefined
      });

      if (response.data.twoFactorToken) {
        setIsFetching(false);
        setShowEmail2fa(false);
        setShowSMS2fa(false);
        setShowApp2fa(true);
        return;
      }

      if (response.data.emailSent) {
        setIsFetching(false);
        setShowApp2fa(false);
        setShowSMS2fa(false);
        setShowEmail2fa(true);
        return;
      }

      if (response.data.codeSent) {
        setIsFetching(false);
        setShowSMS2fa(true);
        setShowApp2fa(false);
        setShowEmail2fa(false);
        return;
      }

      notificationsSuccessShow({ message: 'Success' });
      onSuccess && onSuccess();
      handleCancel();
    } catch (error) {
      notificationsErrorShow({ message: error.data.message });
    }

    setIsFetching(false);
  };

  return (
    <>
      <Modal
        className={styles[`nft-withdraw-modal nft-withdraw-modal-${identity}`]}
        title={t('transfer')}
        width={380}
        visible={selectedNft}
        footer={null}
        onCancel={handleCancel}
      >
        <div className={styles['nft-asset']}>
          {selectedNft?.imageURL ? (
            <img className={styles['nft-asset__image']} src={selectedNft?.imageURL} alt={selectedNft?.name ?? 'NFT'} />
          ) : (
            <Empty description="" />
          )}
          <span className={styles['nft-asset__name']}>{selectedNft && selectedNft?.name}</span>
        </div>

        {!autosignStatus && (
          <div className="withdraw__autosign" onClick={() => setIsOpenAutosignModal(true)}>
            <img src={uploadFile} alt="upload" />
            <span>{!sign ? t('withdraw.uploadAutosign') : `${sign.slice(0, 15)}...`}</span>
          </div>
        )}

        <Form
          name="nftWithdraw"
          form={form}
          initialValues={{
            address: '',
            amount: '',
            twoFactorToken: '',
            emailOtp: '',
            verifyCode: '',
          }}
          onFinish={values => {
            estimate ? withdraw(values, values) : estimateNft(values.address, values.amount);
          }}
          autoComplete="off"
        >
          <div className={styles['nft-withdraw-form']}>
            {!estimate && <span className={styles['nft-withdraw-form__label']}>{t('nft.walletAddress')}</span>}
            <Form.Item name="address" rules={[{ required: true, message: t('required') }]} hidden={estimate}>
              <Input className={styles['nft-withdraw-form__input']} placeholder={t('nft.recipientWallet')} />
            </Form.Item>

            {!estimate && <span className={styles['nft-withdraw-form__label']}>{t('amount')}</span>}
            <Form.Item
              name="amount"
              rules={[
                { required: true, message: t('required') },
                {
                  message: t('nft.amountMax'),
                  validator: (_, value) => {
                    if (value <= selectedNft?.balance) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(t('nft.amountMax'));
                    }
                  },
                },
              ]}
              hidden={estimate}
            >
              <InputNumber
                className={styles['nft-withdraw-form__input']}
                min={0}
                max={selectedNft?.balance}
                placeholder="0"
                formatter={value => value.split('.').join('')}
              />
            </Form.Item>

            {estimate && (
              <div className={styles['withdraw-estimate']}>
                <div className={`${styles['withdraw-estimate__header']} ${styles['withdraw-estimate__item']}`}>
                  <span
                    className={`${styles['withdraw-estimate__title']}  ${
                      styles[`withdraw-estimate__title-${identity}`]
                    }`}
                  >
                    {t('total')}
                  </span>
                  <span
                    className={`${styles['withdraw-estimate__edit']}  ${styles[`withdraw-estimate__edit-${identity}`]}`}
                    onClick={() => {
                      form.resetFields(['verifyCode', 'twoFactorToken', 'emailOtp']);
                      setShowApp2fa(false);
                      setShowEmail2fa(false);
                      setShowSMS2fa(false);
                      setEstimate(null);
                    }}
                  >
                    {t('edit')}
                  </span>
                </div>
                <div className={styles['withdraw-estimate__item']}>
                  <span className={styles['withdraw-estimate__name']}>{t('address')}:</span>{' '}
                  <span className={styles['withdraw-estimate__value']}>
                    {truncate(form.getFieldValue('address'), 15, '...')}
                  </span>
                </div>
                <div className={styles['withdraw-estimate__item']}>
                  <span className={styles['withdraw-estimate__name']}>{t('amount')}:</span>{' '}
                  <span className={styles['withdraw-estimate__value']}>{form.getFieldValue('amount')}</span>
                </div>
                <div className={styles['withdraw-estimate__item']}>
                  <span className={styles['withdraw-estimate__name']}>{t('minerFee')}:</span>{' '}
                  <span className={styles['withdraw-estimate__value']}>
                    {estimate.minerFee} {estimate.currencySystemFee}
                  </span>
                </div>
                <div className={styles['withdraw-estimate__item']}>
                  <span className={styles['withdraw-estimate__name']}>{t('systemFee')}:</span>{' '}
                  <span className={styles['withdraw-estimate__value']}>
                    {estimate.systemFee} {estimate.currencyMinerFee}
                  </span>
                </div>
                <div className={styles['withdraw-estimate__item']}>
                  <span className={styles['withdraw-estimate__name']}>{t('totalFee')}:</span>{' '}
                  <span className={styles['withdraw-estimate__value']}>{estimate.fee}</span>
                </div>
              </div>
            )}

            {showApp2fa && <span className={styles['nft-withdraw-form__label']}>{t('auth.keyFromGA')}</span>}
            <Form.Item
              name="twoFactorToken"
              rules={[{ required: showApp2fa, message: t('validation.twoFaRequired') }]}
              hidden={!showApp2fa}
            >
              <Input className={styles['nft-withdraw-form__input']} placeholder={t('auth.keyFromGA')} />
            </Form.Item>

            {showEmail2fa && <span className={styles['nft-withdraw-form__label']}>{t('auth.keyFromEmail')}</span>}
            <Form.Item
              name="emailOtp"
              rules={[{ required: showEmail2fa, message: t('validation.emailTwoFaRequired') }]}
              hidden={!showEmail2fa}
            >
              <Input className={styles['nft-withdraw-form__input']} placeholder={t('auth.keyFromEmail')} />
            </Form.Item>
            {showEmail2fa && <ResendEmail2FA />}

            {showSMS2fa && <span className={styles['nft-withdraw-form__label']}>{t('auth.сodeFromSms')}</span>}
            <Form.Item
              name="verifyCode"
              rules={[{ required: showSMS2fa, message: t('validation.phoneTwoFaRequired') }]}
              hidden={!showSMS2fa}
            >
              <Input className={styles['nft-withdraw-form__input']} placeholder={t('auth.сodeFromSms')} />
            </Form.Item>
            {showSMS2fa && <ResendPhone2FA />}

            <Button
              loading={isFetching}
              type="primary"
              htmlType="submit"
              className={`${styles['nft-withdraw-form__submit']}  ${styles[`nft-withdraw-form__submit-${identity}`]}`}
            >
              {estimate ? t('withdraw.withdraw') : t('estimate')}
            </Button>
          </div>
        </Form>
      </Modal>

      <UploadAutosignWrapper
        step={autosignStep}
        onCancel={() => setIsOpenAutosignModal(false)}
        uploadAutosign={uploadAutosign}
        isOpen={isOpenAutosignModal}
        sign={sign}
      />
    </>
  );
};

const mapStateToProps = state => ({
  autosignStatus: state.withdraw.autosignStatus,
  autosignStep: state.withdraw.autosignStep,
});

const mapDispatchToProps = dispatch => ({
  notificationsSuccessShow: error => dispatch(notificationsSuccessShow(error)),
  notificationsErrorShow: error => dispatch(notificationsErrorShow(error)),
  checkAutosignStatus: checkAutosignStatus(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(NftWithdraw);
