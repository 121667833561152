import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import MerchantDeleteButton from '../Merchant/MerchantDeleteModal';
import { useTranslation } from 'react-i18next';
import { Dropdown, Radio } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import './Merchant.scss';
import { LoadingOutlined } from '@ant-design/icons';
import cn from 'classnames';
import useWindowSize from '../../utils/resizeHook';
import { ReactComponent as MoreSVG } from '../../img/default-svg/more.svg';
import { identity } from '../../utils/getIdentity';

const Merchant = ({ merchant, isLastMerchant, userRole, quickNetworkSwitcherState, changeMerchantNetwork }) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const { isSm } = useWindowSize();
  const { t } = useTranslation();

  return (
    <div
      className={cn('merchant-item', {
        testnet: merchant.typeNetwork.toLowerCase() === 'testnet',
        mainnet: merchant.typeNetwork.toLowerCase() === 'mainnet',
      })}
    >
      <div className="merchant-item__name">
        <h2>{merchant.name}</h2>
        <p>{merchant.notificationEmail ? merchant.notificationEmail : t('merchants.notificationEmailNotSpecified')}</p>
      </div>
      {!isSm && (
        <div className="merchant-item__network">
          <Dropdown
            visible={isDropdownVisible}
            onVisibleChange={isVisible => setIsDropdownVisible(isVisible)}
            overlay={
              <div className="merchant-item__networks-dropdown">
                <Radio.Group
                  defaultValue={merchant.typeNetwork}
                  onChange={e => {
                    changeMerchantNetwork(merchant._id, { typeNetwork: e.target.value });
                  }}
                >
                  <Radio value="mainnet">{t('mainnet')}</Radio>
                  <Radio value="testnet">{t('testnet')}</Radio>
                </Radio.Group>
              </div>
            }
          >
            <div
              className={`merchant-item__networks-button merchant-item__networks-button-${identity} ${
                isDropdownVisible ? 'merchant-item__networks-button_hover' : ''
              } merchant-item__networks-button_${merchant.typeNetwork.toLowerCase()} merchant-item__networks-button_${merchant.typeNetwork.toLowerCase()}-${identity}`}
            >
              {t(merchant.typeNetwork.toLowerCase())}
              {quickNetworkSwitcherState.fetching && quickNetworkSwitcherState.merchantId === merchant._id ? (
                <LoadingOutlined />
              ) : (
                <DownOutlined />
              )}
            </div>
          </Dropdown>
        </div>
      )}
      {!isSm && (
        <div className="merchant-item__date">
          {t(moment(merchant.createdAt).format('MMMM').toLowerCase())}{' '}
          {moment(merchant.createdAt).format('D, YYYY, HH:mm')}
        </div>
      )}
      <div className="merchant-item__wallets">
        <Link to={`/merchants/wallets/${merchant._id}`} className={`merchant-item__wallets-button merchant-item__wallets-button-${identity}`}>
          {t('merchants.merchantWallets')}
        </Link>
        {userRole.includes('corporate') && (
          <Link to={`/merchants/clients/${merchant._id}`} className={`merchant-item__wallets-button merchant-item__wallets-button-${identity}`}>
            {t('merchants.clientWallets')}
          </Link>
        )}
      </div>
      {/*{!isSm && (
        <div className={`merchant-item__type merchant-item__type-${identity}`}>{userRole.includes('corporate') ? t('business') : t('individual')}</div>
      )}*/}
      <div className="merchant-item__more">
        <Dropdown
          trigger={['click']}
          destroyPopupOnHide={true}
          placement="bottomRight"
          overlay={
            <div className="merchant-item__more-dropdown">
              <Link to={`/merchants/edit/${merchant._id}`} className="merchant-item__more-dropdown-edit">
                {t('edit')}
              </Link>
              <div className="merchant-item__more-dropdown-delete" onClick={() => setShowDelete(true)}>
                {t('delete')}
              </div>
            </div>
          }
        >
          <div className="merchant-item__more-icon">
            <MoreSVG />
          </div>
        </Dropdown>
      </div>
      <MerchantDeleteButton
        merchantId={merchant._id}
        merchantName={merchant.name}
        isLastMerchant={isLastMerchant}
        showDelete={showDelete}
        setShowDelete={setShowDelete}
      />
    </div>
  );
};

export default Merchant;
