import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Form, Row, Col, Input, notification } from 'antd';
import Button from '../Button';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useTranslation } from 'react-i18next';
import { editUser, getUser } from '../../redux/actions/user';
import useWindowSize from '../../utils/resizeHook';
import { ModalPhone } from './ModalPhone';

import './style.scss';
import { cryptoApi } from '../../service/cryptopay-api';
import { identity } from '../../utils/getIdentity';
import { sanitizeInputDisable, sanitizeInputValidator } from '../../utils/sanitizeInput';

const PersonalInfo = ({ userData, userDataFetching, editUser }) => {
  const [userEmail, setUserEmail] = useState(userData.email);
  const [userPhone, setUserPhone] = useState(userData.phone);
  const [userName, setUserName] = useState(userData.name);
  const [userSurname, setUserSurname] = useState(userData.surname);
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const [confirmVerify, setConfirmVerify] = useState(false)
  const [disableResend, setDisableResend] = useState(false)
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { currentWidth } = useWindowSize();

  useEffect(() => {
    setUserEmail(userData.email);
    setUserPhone(userData.phone);
    setUserName(userData.name);
    setUserSurname(userData.surname);

    form.resetFields();
  }, [userData]);

  useEffect(() => {
    if (confirmVerify === true) {
      getUser()
    }
  }, [confirmVerify])

  const handleFormSubmit = () => {
    const formData = {
      email: userEmail,
      phone: (userPhone === '' || userPhone === '+') ? "" : userPhone,
      name: userName,
      surname: userSurname,
    };
   editUser(formData);
  };

  const handleDisableResend = () => {
   const timeout = setTimeout(() => {
      setDisableResend(false)
    }, 60000)

  return () => clearTimeout(timeout)
  }

  const openVerifyModal = async () => {
    try {
    await cryptoApi.verifyPhone({})
    setOpenConfirmModal(true)
    setDisableResend(true)
    handleDisableResend()
    } catch (error) {
      notification.error({
        message: error.data.message
      })
    }
  }

  const changePhone = userData?.phone !== undefined

  const handleConfirmModal = async () => {
    setOpenConfirmModal(false)
  }

  const handleUserNameChange = e => {
    const { value } = e.target;
    if (value.length <= 30) {
      setUserName(value);
    }
  };
  const handleUserSurnameChange = e => {
    const { value } = e.target;
    if (value.length <= 30) {
      setUserSurname(value);
    }
  };

  return (
    <>
    <Form
      form={form}
      layout="vertical"
      className="form"
      initialValues={{
        userEmail: userData.email,
        userPhone: userData.phone,
        userName: userData.name,
        userSurname: userData.surname,
      }}
    >
      <span className={`form__title form__title-${identity}`}>{t('profile.emailAndPhone')}</span>
      <span className={`form__subtitle form__subtitle-${identity}`}>{t('profile.verifiedEmailUsedToSignIn')}</span>
      <span className={`form__subtitle form__subtitle-${identity}`}>
        {t('profile.yourCurrentEmail')} <b>{userData.email}</b>
      </span>
      <Row gutter={[20, 0]}>
        <Col span={currentWidth >= 1024 ? 6 : 24}>
          <Form.Item name="userEmail" label={t('newEmail')} rules={[{ type: 'email' }]} className="form__item">
            <Input className="form__input" placeholder={t('newEmail')} onChange={e => setUserEmail(e.target.value)} />
          </Form.Item>
        </Col>

        <Col span={currentWidth >= 1024 ? 6 : 24}>
          <Form.Item label={t('phone')} name="userPhone" className="form__item">
            <PhoneInput
              containerClass="form__input form__input-phone"
              country={'us'}
              onChange={phone => setUserPhone(`+${phone}`)}
            />
          </Form.Item>
          {!userData.phoneConfirmed &&
          <div className='phone-info'>
           {userData.phone && <p>Phone is not confirmed.</p>}
           {userData.phone && <p className={`phone-info__code phone-info__code-${identity}`} onClick={openVerifyModal}> Send verification code</p>}
          </div>
         }
        </Col>
      </Row>

      <span className={`form__title form__title-${identity}`}>{t('profile.personalInfo')}</span>
      <Row gutter={[20, 0]}>
        <Col span={currentWidth >= 1024 ? 6 : 24}>
          <Form.Item label={t('firstName')} 
          rules={[ sanitizeInputValidator, ({ setFieldsValue }) => ({
            validator: (rule, value) => {
              if (value.length > 30) {
                setFieldsValue({
                  userName: value.slice(0, 30),
                });
                return Promise.reject(new Error(t('maxLength30')));
              }

              return Promise.resolve();
            },
          })]}
          name="userName" className="form__item">
            <Input className="form__input" placeholder={t('firstName')} onChange={handleUserNameChange} />
          </Form.Item>
        </Col>
        <Col span={currentWidth >= 1024 ? 6 : 24}>
          <Form.Item
            rules={[ sanitizeInputValidator, ({ setFieldsValue }) => ({
              validator: (rule, value) => {
                if (value.length > 30) {
                  setFieldsValue({
                    userSurname: value.slice(0, 30),
                  });
                  return Promise.reject(new Error(t('maxLength30')));
                }
  
                return Promise.resolve();
              },
            })]}
           label={t('lastName')} name="userSurname" className="form__item">
            <Input className="form__input" placeholder={t('lastName')} onChange={handleUserSurnameChange} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[20, 0]}>
        <Col span={currentWidth >= 1024 ? 4 : 24}>
          <Button
            type="primary"
            className="form__button"
            onClick={handleFormSubmit}
            disabled={userDataFetching || sanitizeInputDisable(userName) || sanitizeInputDisable(userSurname)}
            loading={userDataFetching}
          >
            {t('submit')}
          </Button>
        </Col>
      </Row>
    </Form>
    {openConfirmModal && <ModalPhone
    openConfirmModal={openConfirmModal}
    handleConfirmModal={handleConfirmModal}
    phone={userData.phone}
    disableResend={disableResend}
    setDisableResend={setDisableResend}
    handleDisableResend={handleDisableResend}
    userData={userData}
    editUser={editUser}
    changePhone={changePhone}
    setConfirmVerify={setConfirmVerify}
    />}
    </>
  );
};

const mapStateToProps = state => ({
  userData: state.user.data,
  userDataFetching: state.user.fetching,
});

const mapDispatchToProps = dispatch => ({
  editUser: editUser(dispatch),
  getUser: getUser(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInfo);
