import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';
import { identity } from "../../../utils/getIdentity";
import { ReactComponent as CloseSVG } from '../../../img/default-svg/close-black.svg';
import './style.scss';
import Button from "../../Button";
import '../../../layout/Modal.scss';

const DeactivatePlan = ({ visible, close, currentValues, changePlan }) => {
  return (
    <Modal
      width={520}
      title=""
      visible={visible}
      onCancel={close}
      footer={null}
      className={`checkout-modal checkout-modal-${identity}`}
      centered
      closeIcon={<CloseSVG />}
      destroyOnClose
      maskClosable={false}
    >
      <div className="checkout-modal-content">
        <div className="checkout-modal-content__titlePlan">
          Do you want to deactivate plan?
        </div>
        <div className="checkout-modal-content__subtitle">
          You have {currentValues?.activeSubscriptions} active subscriptions
        </div>
        <div className="checkout-modal-content__body">
          <div className="checkout-modal-content__body--button">
            <Button onClick={close} type="secondary">Back</Button>
            <Button onClick={() => changePlan(false)} type="danger">Deactivate Plan</Button>
          </div>
        </div>
      </div>
    </Modal>
  )
};

export default DeactivatePlan;