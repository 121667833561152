import {
  //--GET CHECKOUTS LIST
  GET_CHECKOUTS_LIST_REQUEST,
  GET_CHECKOUTS_LIST_SUCCESS,
  GET_CHECKOUTS_LIST_FAIL,
  //--CREATE CHECKOUT DONATION
  CREATE_CHECKOUT_DONATION_REQUEST,
  CREATE_CHECKOUT_DONATION_SUCCESS,
  CREATE_CHECKOUT_DONATION_FAIL,
  //--CREATE CHECKOUT SALE
  CREATE_CHECKOUT_SALE_REQUEST,
  CREATE_CHECKOUT_SALE_SUCCESS,
  CREATE_CHECKOUT_SALE_FAIL,
  //--CREATE CHECKOUT SELL CURRENCY
  CREATE_CHECKOUT_SELL_CURRENCY_REQUEST,
  CREATE_CHECKOUT_SELL_CURRENCY_SUCCESS,
  CREATE_CHECKOUT_SELL_CURRENCY_FAIL,
  //--CREATE CHECKOUT CART
  CREATE_CHECKOUT_CART_REQUEST,
  CREATE_CHECKOUT_CART_SUCCESS,
  CREATE_CHECKOUT_CART_FAIL,
  //--DELETE CHECKOUT
  DELETE_CHECKOUT_REQUEST,
  DELETE_CHECKOUT_SUCCESS,
  DELETE_CHECKOUT_FAIL,
  //--PATCH CHECKOUT
  PATCH_CHECKOUT_REQUEST,
  PATCH_CHECKOUT_SUCCESS,
  PATCH_CHECKOUT_FAIL,
  //--SET DETAILED CHECKOUT
  SET_DETAILED_CHECKOUT,
  SET_EDIT_CHECKOUT,
  //--GET SUPPORTED CURRENCIES LIST
  GET_SUPPORTED_CURRENCIES_LIST_REQUEST,
  GET_SUPPORTED_CURRENCIES_LIST_SUCCESS,
  GET_SUPPORTED_CURRENCIES_LIST_FAIL,
  //--GET SUPPORTED WALLETS LIST
  GET_SUPPORTED_WALLETS_LIST_REQUEST,
  GET_SUPPORTED_WALLETS_LIST_SUCCESS,
  GET_SUPPORTED_WALLETS_LIST_FAIL,
  //GET PLANS LIST
  GET_PLANS_LIST_REQUEST,
  GET_PLANS_LIST_SUCCESS,
  GET_PLANS_LIST_FAIL,
  // PATCH PLAN
  PATCH_PLAN_REQUEST,
  PATCH_PLAN_SUCCESS,
  PATCH_PLAN_FAIL,
  // GET SUBSCRIPTIONS
  GET_SUBSCRIPTIONS_REQUEST,
  GET_SUBSCRIPTIONS_SUCCESS,
  GET_SUBSCRIPTIONS_FAIL,
  // GET ACTIVITIES
  GET_ACTIVITIES_REQUEST,
  GET_ACTIVITIES_SUCCESS,
  GET_ACTIVITIES_FAIL,
  // GET MERCHANT SECURITY
  GET_MERCHANT_SECURITY_REQUEST,
  GET_MERCHANT_SECURITY_SUCCESS,
  GET_MERCHANT_SECURITY_FAIL,
  CHANGE_MERCHANT_SECURITY_SUCCESS,
  SET_MERCHANT_SECURITY_TWO_FACTOR_TOKEN,
  SET_MERCHANT_SECURITY_EMAIL,
  SET_MERCHANT_SECURITY_VERIFY_CODE
} from '../actions/checkouts';

const defaultState = {
  get: { data: null, fetching: false, page: 1, pages: null, countItem: null },
  plans: { data: null, fetching: false, page: 1, pages: null, countItem: null },
  subscriptions: { data: null, fetching: false, page: 1, pages: null, countItem: null },
  activities: { data: null, fetching: false, page: 1, pages: null, countItem: null },
  merchantSecurity: '',
  merchantSecurityChange: false,
  twoFactorToken: false,
  emailOtp: false,
  verifyCode: false,
  create: { data: null, fetching: false },
  delete: { data: null, fetching: false },
  patch: { data: null, fetching: false },
  currencies: { data: null, fetching: false, page: 1, pages: null, countItem: null },
  wallets: { data: null, fetching: false, page: 1, pages: null, countItem: null },
  detailedCheckout: null,
  editCheckout: null,
  limit: 5,
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case GET_CHECKOUTS_LIST_REQUEST:
      return {
        ...state,
        get: { ...state.get, fetching: true },
      };
    case GET_CHECKOUTS_LIST_SUCCESS:
      const { entities, page, pages, countItem } = action.payload.data;
      return {
        ...state,
        get: { ...state.get, data: entities, fetching: false, page, pages, countItem },
      };
    case GET_CHECKOUTS_LIST_FAIL:
      return {
        ...state,
        fetching: false,
      };
    case CREATE_CHECKOUT_DONATION_REQUEST:
    case CREATE_CHECKOUT_SALE_REQUEST:
    case CREATE_CHECKOUT_SELL_CURRENCY_REQUEST:
    case CREATE_CHECKOUT_CART_REQUEST:
      return {
        ...state,
        create: { ...state.create, fetching: true },
      };
    case CREATE_CHECKOUT_DONATION_SUCCESS:
    case CREATE_CHECKOUT_SALE_SUCCESS:
    case CREATE_CHECKOUT_SELL_CURRENCY_SUCCESS:
    case CREATE_CHECKOUT_CART_SUCCESS:
      return {
        ...state,
        create: { ...state.create, fetching: false },
        detailedCheckout: action.payload.data,
      };
    case CREATE_CHECKOUT_DONATION_FAIL:
    case CREATE_CHECKOUT_SALE_FAIL:
    case CREATE_CHECKOUT_SELL_CURRENCY_FAIL:
    case CREATE_CHECKOUT_CART_FAIL:
      return {
        ...state,
        create: { ...state.create, fetching: false },
      };
    case DELETE_CHECKOUT_REQUEST:
      return {
        ...state,
        // fetching: true,
      };
    case DELETE_CHECKOUT_SUCCESS:
      return {
        ...state,
      };
    case DELETE_CHECKOUT_FAIL:
      return {
        ...state,
        // fetching: false,
      };
    case PATCH_CHECKOUT_REQUEST:
      return {
        ...state,
        patch: { ...state.patch, fetching: true },
      };
    case PATCH_CHECKOUT_SUCCESS:
      return {
        ...state,
        get: {
          ...state.get,
          data: state.get.data.map(el => (el._id === action.payload.data._id ? action.payload.data : el)),
        },
        patch: { ...state.patch, fetching: false },
      };
    case PATCH_CHECKOUT_FAIL:
      return {
        ...state,
        patch: { ...state.patch, fetching: false },
      };
    case SET_DETAILED_CHECKOUT:
      return {
        ...state,
        detailedCheckout: action.payload,
      };
    case SET_EDIT_CHECKOUT:
      return {
        ...state,
        editCheckout: action.payload,
      };
    case GET_SUPPORTED_CURRENCIES_LIST_REQUEST:
      return {
        ...state,
        currencies: { ...state.currencies, fetching: true },
      };
    case GET_SUPPORTED_CURRENCIES_LIST_SUCCESS:
      return {
        ...state,
        currencies: {
          ...state.currencies,
          data: action.payload.currencies,
          fetching: false,
          page: action.payload.page,
          pages: action.payload.pages,
          countItem: action.payload.countItem
        },
      };
    case GET_SUPPORTED_CURRENCIES_LIST_FAIL:
      return {
        ...state,
        fetching: false,
      };
    case GET_SUPPORTED_WALLETS_LIST_REQUEST:
      return {
        ...state,
        wallets: { ...state.wallets, fetching: true },
      };
    case GET_SUPPORTED_WALLETS_LIST_SUCCESS:
      return {
        ...state,
        wallets: {
          ...state.wallets,
          data: action.payload.entities,
          fetching: false,
          page: action.payload.page,
          pages: action.payload.pages,
          countItem: action.payload.countItem
        },
      };
    case GET_SUPPORTED_WALLETS_LIST_FAIL:
      return {
        ...state,
        fetching: false,
      };
    case GET_PLANS_LIST_REQUEST:
      return {
        ...state,
        plans: { ...state.plans, fetching: true },
      };
    case GET_PLANS_LIST_SUCCESS:
      return {
        ...state,
        plans: {
          ...state.plans,
          data: action.payload.entities,
          fetching: false,
          page: action.payload.page,
          pages: action.payload.pages,
          countItem: action.payload.countItems
        },
      };
    case GET_PLANS_LIST_FAIL:
      return {
        ...state,
        fetching: false,
      };
    case PATCH_PLAN_REQUEST:
      return {
        ...state,
        patch: { ...state.patch, fetching: true },
      };
    case PATCH_PLAN_SUCCESS:
      return {
        ...state,
        plans: {
          ...state.plans,
          data: state.plans.data.map(el => (el._id === action.payload.data._id ? action.payload.data : el)),
        },
        patch: { ...state.patch, fetching: false },
      };
    case PATCH_PLAN_FAIL:
      return {
        ...state,
        patch: { ...state.patch, fetching: false },
      };
    case GET_SUBSCRIPTIONS_REQUEST:
      return {
        ...state,
        subscriptions: { ...state.subscriptions, fetching: true },
      };
    case GET_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          data: action.payload.entities,
          fetching: false,
          page: action.payload.page,
          pages: action.payload.pages,
          countItem: action.payload.countItems
        },
      };
    case GET_SUBSCRIPTIONS_FAIL:
      return {
        ...state,
        fetching: false,
      };
    case GET_ACTIVITIES_REQUEST:
      return {
        ...state,
        activities: { ...state.activities, fetching: true },
      };
    case GET_ACTIVITIES_SUCCESS:
      return {
        ...state,
        activities: {
          ...state.activities,
          data: action.payload.entities,
          fetching: false,
          page: action.payload.page,
          pages: action.payload.pages,
          countItem: action.payload.countItems
        },
      };
    case GET_ACTIVITIES_FAIL:
      return {
        ...state,
        fetching: false,
      };
    case GET_MERCHANT_SECURITY_SUCCESS:
      return {
        ...state,
        merchantSecurity: action.payload
      }
    case SET_MERCHANT_SECURITY_TWO_FACTOR_TOKEN:
      return {
        ...state,
        twoFactorToken: action.payload
      }
    case SET_MERCHANT_SECURITY_EMAIL:
      return {
        ...state,
        emailOtp: action.payload
      }
    case SET_MERCHANT_SECURITY_VERIFY_CODE:
      return {
        ...state,
        verifyCode: action.payload
      }
    case CHANGE_MERCHANT_SECURITY_SUCCESS:
      return {
        ...state,
        merchantSecurityChange: action.payload
      }
    default:
      return state;
  }
}
