import React, { useState } from 'react';
import { Modal } from 'antd';
import cn from 'classnames';
import Button from '../Button';
import BackupPhraseStepOne from './Steps/BackupPhraseStepOne';
import BackupPhraseStepTwo from './Steps/BackupPhraseStepTwo';
import BackupPhraseStepThree from './Steps/BackupPhraseStepThree';
import { useTranslation } from 'react-i18next';
import '../../layout/Modal.scss';
import './BackupPhraseModal.scss';
import { identity } from "../../utils/getIdentity";

const BackupPhraseModal = ({ isBackupEnabled, isUserHasEmail }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [backupStep, setBackupStep] = useState(0);
  const [backupWords, setBackupWords] = useState([]);
  const { t, i18n } = useTranslation();

  const handleOpen = () => {
    setIsVisible(true);
  };

  const handleCancel = e => {
    setIsVisible(false);
    setBackupStep(0);
  };

  const handleNext = () => {
    if (backupStep < 2) {
      setBackupStep(backupStep + 1);
    } else {
      setIsVisible(false);
    }
  };

  const backupPhraseModals = [
    {
      title: t('profile.yourSecretBackupPhrase'),
      component: (
        <BackupPhraseStepOne
          backupWords={backupWords}
          setBackupWords={setBackupWords}
          okButtonAction={handleNext}
          handleCancel={handleCancel}
        />
      ),
    },
    {
      title: t('profile.сonfirmPhrase'),
      component: (
        <BackupPhraseStepTwo backupWords={backupWords} okButtonAction={handleNext} handleCancel={handleCancel} />
      ),
    },
    {
      title: t('profile.backupPhraseSuccess'),
      component: <BackupPhraseStepThree okButtonAction={handleNext} handleCancel={handleCancel} />,
    },
  ];

  return (
    <React.Fragment>
      <Button
        type="primary"
        disabled={isBackupEnabled || !isUserHasEmail}
        className={cn("form__button",{"es":i18n.language === 'es'})}
        onClick={handleOpen}
      >
        {t('profile.startBackup')}
      </Button>
      <Modal
        width={534}
        title={backupPhraseModals[backupStep].title}
        visible={isVisible}
        onOk={handleOpen}
        onCancel={handleCancel}
        footer={null}
        className={`modal modal-${identity} modal-backup`}
      >
        <div className='backup-info'>
         {backupPhraseModals[backupStep].component}
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default BackupPhraseModal;
