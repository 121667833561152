import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowIcon } from '../../img/cpay-svg/arrow-back.svg';
import { ReactComponent as ArrowIconNFG } from '../../img/nfgpay-svg/arrow-back-nfg.svg';
import { ReactComponent as ArrowIconFinvaro } from '../../img/finvaro/arrow-back-finvaro.svg';
import { ReactComponent as ArrowIconClarnium } from '../../img/clarnium/arrow-back-clarnium.svg';
import LoginForm from '../LoginForm/LoginForm';
import LoginPhoneForm from '../LoginPhoneForm';
import LoginSocialMedia from '../LoginSocialMedia';
import './style.scss';
import { identity } from '../../utils/getIdentity';


const copyIcon = {
  cpay: <ArrowIcon />,
  nfg: <ArrowIconNFG />,
  finvaro: <ArrowIconFinvaro />,
  clarnium: <ArrowIconClarnium />
};

const LoginOptions = () => {
  const [loginOption, setLoginOption] = useState(0);
  const [userType, setUserType] = useState('corporate');
  const { t } = useTranslation();

  return (
    <>
      {loginOption === 0 ? (
        <div className={`login-form login-form-${identity} login-options`}>
          {identity !== 'finvaro' && <span className="login-options__title">{t('auth.signUpOrLogIn')}</span>}
          <div className="login-options__items">
            <div className={`login-options__item login-options__item-${identity}`} onClick={() => setLoginOption(1)}>
              <span>{t('auth.continueWithEmail')}</span>
              {copyIcon[identity]}
            </div>
            <div className={`login-options__item login-options__item-${identity}`} onClick={() => setLoginOption(2)}>
              <span>{t('auth.continueWithPhone')}</span>
              {copyIcon[identity]}
            </div>

            {identity !== 'finvaro' && (
              <div className="login-options__divider">Or</div>
            )}
            {identity !== 'finvaro' && (
              <LoginSocialMedia userType={userType} />
            )}
          </div>

          {identity !== 'finvaro' && (
            <div className={`login-form__text login-form__text_center login-form__text-${identity}`}>
              {t('auth.dontHaveAccount')} <Link to="/sign-up">{t('signUp')}!</Link>
            </div>
          )}
        </div>
      ) : loginOption === 1 ? (
        <LoginForm backAction={() => setLoginOption(0)} />
      ) : (
        <LoginPhoneForm backAction={() => setLoginOption(0)} />
      )}
    </>
  );
};

export default LoginOptions;
