import React, { useState, useEffect, useRef } from 'react';
import { Popover, Tooltip, Button, Modal } from 'antd';
import QRCode from 'qrcode.react';
import { useTranslation } from 'react-i18next';
import TokenIcon from '../../TokenIcon';
import formatCurrency from '../../../utils/currencyFormatter';
import { ReactComponent as QRIcon } from '../../../img/default-svg/bx_qr.svg';
import { ReactComponent as Copy } from '../../../img/default-svg/copy.svg';
import './style.scss';
import { identity } from '../../../utils/getIdentity';


const CurrencyItem = ({
  currencyId,
  setCurrencyId,
  currencyBalance,
  title,
  currencies,
  resetFiltersChangeCurrency
}) => {
  const [showPopover, setShowPopover] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showQRModal, setShowQRModal] = useState(null);
  const [showAddressPopover, setShowAddressPopover] = useState(false);
  const addressPopoverTimer = useRef(null);

  const timer = useRef(null);
  const { t } = useTranslation();

  const getMinReplenishValue = () => {
    const currency = currencies.find(currency => currency._id === currencyBalance.currency.id);
    if (!currency) return '-';
    return `0.${new Array(currency.decimals).join(0)}1`;
  };

  useEffect(() => () => {
    clearTimeout(timer);
    timer.current = null;
  });

  useEffect(() => {
    showPopover && setShowTooltip(false);
  }, [showPopover]);

  const copyHandler = (text, event) => {
    event.stopPropagation();
    navigator.clipboard.writeText(text);
    setShowPopover(true);
    timer.current = setTimeout(() => setShowPopover(false), 1000);
  };

  const openModal = (event, currencyBalance) => {
    event.stopPropagation();
    setShowQRModal(currencyBalance);
  };

  const addressCopyHandler = () => {
    navigator.clipboard.writeText(currencyBalance.wallet);
    setShowAddressPopover(true);
    addressPopoverTimer.current = setTimeout(() => setShowAddressPopover(false), 1000);
  };

  useEffect(() => {
    return () => {
      clearTimeout(addressPopoverTimer);
      addressPopoverTimer.current = null;
    };
  });

  const truncate = function (fullStr = '', strLen, separator) {
    if (fullStr.length <= strLen) return fullStr;

    separator = separator || '...';

    var sepLen = separator.length,
      charsToShow = strLen - sepLen,
      frontChars = Math.ceil(charsToShow / 2),
      backChars = Math.floor(charsToShow / 2);

    return fullStr.substring(0, frontChars) + separator + fullStr.substring(fullStr.length - backChars);
  };

  return (
    <>
      <div
        className={`currency-item currency-item-${identity} ${currencyId === currencyBalance.currency.id && 'currency-item_active'}`}
        onClick={() => {
          document.getElementsByClassName('main-layout__content-wrap')[0].scrollTo({
            top: 0,
            behavior: 'smooth',
          });
          setCurrencyId(currencyBalance.currency.id);
          localStorage.setItem('currId', currencyBalance.currency.id)
          resetFiltersChangeCurrency()
        }}
      >
        <div className="currency-item__inner">
          <div className="currency-item_left">
            <div className="currency-item__name-wrap">
              <TokenIcon className="currency-item__logo" tokenName={currencyBalance.currency.name} />
              <div className="currency-item__title">{title}</div>
            </div>
            <div className="currency-item__qr-wrap">
              <QRIcon onClick={event => openModal(event, currencyBalance)} />
              <Tooltip
                title={t('clickToCopy')}
                visible={showTooltip}
                onVisibleChange={isVisible => setShowTooltip(isVisible)}
              >
                <div>
                  <Popover content={t('copied')} trigger="click" visible={showPopover} hidden={true}>
                    <div
                      className={`currency-item__wallet currency-item__wallet-${identity}`}
                      onClick={event => copyHandler(currencyBalance.wallet, event)}
                    >
                      {truncate(currencyBalance.wallet, 14, '..')}
                    </div>
                  </Popover>
                </div>
              </Tooltip>
            </div>
          </div>

          <div className="currency-item_right">
            <div className={`currency-item__amount currency-item__amount-${identity}`}>
              {currencyBalance.balance.value} {currencyBalance.currency.name}
            </div>
            <div className="currency-item__usd">{formatCurrency(currencyBalance.balance.usd)}</div>
          </div>
        </div>
        {/* <div className="currency-item__min-replenish">Minimum deposit amount for wallet is {getMinReplenishValue()}</div> */}
      </div>

      {showQRModal !== null && currencyBalance.wallet ? (
        <Modal
          className={`qr-modal qr-modal-${identity}`}
          title={t('merchants.replenish')}
          width={380}
          visible={showQRModal}
          footer={null}
          onCancel={() => setShowQRModal(null)}
        >
          <div className="main">
            <div className="qr-block">
              <Popover
                title=""
                content={t('copied')}
                trigger="click"
                visible={showAddressPopover}
                className="detailed-checkout__popover"
              >
                <div className="qr-block__address" onClick={addressCopyHandler}>
                  <div>{`${showQRModal.currency.name} ${t('address')}`}</div>
                  <div className={`qr-block__copy-block qr-block__copy-block-${identity}`}>
                    <div>{`${showQRModal.wallet.slice(0, 4)}...${showQRModal.wallet.slice(-4)}`}</div>
                    <Copy />
                  </div>
                </div>
              </Popover>
            </div>
            <QRCode value={showQRModal.wallet} size={205} />

            <Button onClick={() => setShowQRModal(null)}>Ok</Button>
          </div>
        </Modal>
      ) : (
        <></>
      )}
    </>
  );
};

export default CurrencyItem;
