import React, { useState, useEffect,useRef } from 'react';
import { connect } from 'react-redux';
import Card from '../../components/Card';
import Text from '../../components/Text';
import { Form } from 'antd';
import { InputSearch, InputSelect } from '../../components/FormFields';
import CurrencyDropdown from '../../components/CurrencyDropdown';
import WalletsList from '../../components/WalletsList';
import { ButtonPrimary } from '../../components/Button';
import { getAdminFeeWallets, getMoreAdminFeeWallets } from '../../../redux/actions/adminPanel/adminFeeWallets';
import AddWalletModal from '../../components/AddWalletModal';
import {scrollContainerIntoView} from '../../helpers/scrollIntoView';
import './style.scss';
import { sanitizeSearchInputDisable } from '../../../utils/sanitizeInput';

const FeeWallets = ({
  feeWallets,
  feeWalletsFetching,
  feeWalletsFetchingMore,
  countItem,
  page,
  typeNetwork,
  getAdminFeeWallets,
  getMoreAdminFeeWallets,
  permissions
}) => {
  const [showAddNewWalletModal, setShowAddNewWalletModal] = useState(false);
  const [form] = Form.useForm();
  const feeWalletsRef = useRef();

  useEffect(() => {
    getAdminFeeWallets({ order: 'DESC', typeNetwork, main: true, limit:5 });
  }, [typeNetwork]);

  useEffect(() => {
    scrollContainerIntoView(feeWalletsRef);
  }, []);

  const setCurrencies = values => {
    form.setFieldsValue({ currencyIds: values.length ? values.join(',') : undefined });
  };
  
  return (
    <div className="fee-wallets" ref={feeWalletsRef}>
      <Card>
        <Text.pageTitle>System fee wallets</Text.pageTitle>
        <Form
          form={form}
          name="fee-wallets-form"
          initialValues={{
            search: '',
            order: 'DESC',
            currencyIds: undefined,
          }}
          onFinish={values => getAdminFeeWallets({ ...values, typeNetwork, main: true, limit:5 })}
        >
          <div className="fee-wallets__filters">
            <InputSearch
              name="search"
              // onSearch={form.submit}
              onSearch={() => {
                const searchWords = form?.getFieldsValue()?.search
                if (searchWords) {
                  !sanitizeSearchInputDisable(searchWords) && form.submit();
                 if (sanitizeSearchInputDisable(searchWords)) {
                  form.resetFields();
                  form.submit();
                 }
                }
              }}
              onReset={() => {
                form.resetFields();
                form.submit();
              }}
            />
            <div className="flex-auto"></div>
            <InputSelect
              name="order"
              options={[
                { value: 'DESC', label: 'Newest on top' },
                { value: 'ASC', label: 'Oldest on top' },
              ]}
              onChange={form.submit}
            />
            <Form.Item name="currencyIds" valuePropName="merchantCurrencies">
              <CurrencyDropdown setFunc={setCurrencies} multiple={true} onChangeAction={form.submit} />
            </Form.Item>
          </div>
        </Form>
        <div className="fee-wallets__add-new-wrap">
          <ButtonPrimary disabled={permissions && permissions?.length > 0 && !permissions[3]?.edit} onClick={() => setShowAddNewWalletModal(true)}>Create new wallet</ButtonPrimary>
        </div>
        <WalletsList withdrawWallets={feeWallets} walletsFetching={feeWalletsFetching} />
        {feeWallets.length && feeWallets.length < countItem ? (
          <div className="fee-wallets__load-more">
            <ButtonPrimary
              loading={feeWalletsFetchingMore}
              onClick={() =>
                getMoreAdminFeeWallets({ ...form.getFieldsValue(), page: +page + 1, typeNetwork, main: true })
              }
            >
              Load More
            </ButtonPrimary>
          </div>
        ) : null}
      </Card>
      <AddWalletModal
        showAddNewWalletModal={showAddNewWalletModal}
        setShowAddNewWalletModal={setShowAddNewWalletModal}
        updateWalletsList={() => form.submit()}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  feeWallets: state.adminFeeWallets.entities,
  feeWalletsFetching: state.adminFeeWallets.fetching,
  feeWalletsFetchingMore: state.adminFeeWallets.fetchingMore,
  countItem: state.adminFeeWallets.countItem,
  page: state.adminFeeWallets.page,
  permissions: state.adminSettings.permissions,
  typeNetwork: state.networkFilter,
});

const mapDispatchToProps = {
  getAdminFeeWallets,
  getMoreAdminFeeWallets,
};

export default connect(mapStateToProps, mapDispatchToProps)(FeeWallets);
