import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Row, Col, Modal } from 'antd';
import cn from 'classnames';
import TokenIcon from '../../../../components/TokenIcon';
import formatCurrency from '../../../../utils/currencyFormatter';
import CopyWrapper from '../../../../components/CopyWrapper';
import { ReactComponent as CopyIconCpay } from '../../../../img/cpay-svg/copy-blue.svg';
import { tokenNodeTypeObj, tokenTypeEnd } from '../../../../utils/tokenNodeTypes';
import './style.scss';
import { getHashFromLink } from '../../../../utils/getHashFromLink';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CopyIconNfg } from '../../../../img/nfgpay-svg/copy-blue-nfg.svg';
import { ReactComponent as CopyIconFinvaro } from '../../../../img/finvaro/copy-blue-finvaro.svg';
import { ReactComponent as CopyIconClarnium } from '../../../../img/clarnium/copy-blue-clarnium.svg';
import './style.scss';
import { identity } from '../../../../utils/getIdentity';

const copyIcon = {
  cpay: <CopyIconCpay />,
  nfg: <CopyIconNfg />,
  finvaro: <CopyIconFinvaro />,
  clarnium: <CopyIconClarnium />,
};

const Transaction = ({
  transaction,
  isFromTransactionsByUser,
  userSearchParams,
  networkFilter,
  userName,
  currencies,
}) => {
  const [isOpened, setIsOpened] = useState(false);
  const [showMultiAddressesModal, setShowMultiAddressesModal] = useState(false);
  const [currencyTitle, setCurrencyTitle] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    const foundCurrency = currencies.find(currency => currency._id === transaction.info.currencyId);
    foundCurrency &&
      setCurrencyTitle(foundCurrency.currencyType === 'token' ? foundCurrency.title : foundCurrency.name);
  }, [currencies, transaction]);

  const getTypeForTransactions = (transaction, isBackground = false) => {
    return isBackground &&
      identity === 'nfg' &&
      (transaction.systemStatus === 'Failed' || transaction.systemStatus === 'Error')
      ? 'Error'
      : identity === 'nfg' && transaction.swap
      ? 'Swap'
      : transaction.type;
  };

  return (
    <>
      {transaction.info.multisend && (
        <Modal
          centered={true}
          onCancel={() => setShowMultiAddressesModal(false)}
          title="Multisend transactions:"
          wrapClassName={`transaction__modal transaction__modal-${identity}`}
          width={400}
          visible={showMultiAddressesModal}
        >
          <div className="transaction__modal-wrap">
            {transaction.info.multisend.map(item => (
              <div className="transaction__modal-item">
                <div className="transaction__modal-label">Address:</div>
                <div className="transaction__modal-value">
                  {item.address} <CopyWrapper copyContent={item.address}>{copyIcon[identity]}</CopyWrapper>
                </div>
                <div className="transaction__modal-label">Amount:</div>
                <div>
                  <span className="transaction__modal-value">{`${item.value} ${transaction.info.currency}`}</span>
                  {' - '}
                  <span className="transaction__modal-value">{formatCurrency(+item.usd)}</span>
                </div>
              </div>
            ))}
          </div>
        </Modal>
      )}
      <div
        className={`fee-transaction fee-transaction-${identity}-${getTypeForTransactions(
          transaction,
          true
        ).toLowerCase()}`}
      >
        {!isFromTransactionsByUser ? (
          <Row align="middle" onClick={() => setIsOpened(!isOpened)}>
            <Col span={1}>
              <div className="fee-transaction__logo">
                <TokenIcon tokenName={transaction.info.currency} />
              </div>
            </Col>
            <Col span={4}>
              <div className="fee-transaction__type">
                {getTypeForTransactions(transaction)} {transaction?.isRecurringPayment && '(Recurring payment)'}{' '}
                {identity !== 'nfg' && transaction.swap && '(SWAP)'}
              </div>
            </Col>
            <Col span={5} className="fee-transaction__email">{transaction?.userEmail || '-'}</Col>
            <Col span={5}>
              <div className="fee-transaction__date">{moment(transaction.createdAt).format('MMM D, YYYY, HH:mm;')}</div>
            </Col>
            <Col span={2}>
              <div className={`transaction__status transaction__status_${transaction.systemStatus.toLowerCase()} transaction__status_${transaction.systemStatus.toLowerCase()}-${identity}`}>
                {transaction.systemStatus}
              </div>
            </Col>
            <Col span={7}>
              <div className="fee-transaction__amount">
                {transaction.info.nft && Object.keys(transaction.info.nft).length ? (
                  <div className="fee-transaction__amount_tokens">
                    {transaction.info.amount.value} {transaction.info.nft.type}
                  </div>
                ) : (
                  <>
                    <div className="fee-transaction__amount_tokens">
                      {transaction.info.amount.value}{' '}
                      {transaction.info.amount.value || transaction.info.amount.value === 0
                        ? transaction.info.currency
                        : ''}{' '}
                      {transaction.info.currencyType === 'token' ? tokenNodeTypeObj[transaction.info.nodeType] : null}
                      {tokenTypeEnd(
                        transaction.info.currencyType,
                        transaction.info.nodeType,
                        transaction.info.currency
                      )}
                    </div>
                    <div className="fee-transaction__amount_usd">${formatCurrency(transaction.info.amount.usd)}</div>
                  </>
                )}
              </div>
            </Col>
          </Row>
        ) : (
          <Row align="middle" onClick={() => setIsOpened(!isOpened)}>
            <Col span={5}>
              <div className="fee-transaction__profile">{userName}</div>
            </Col>
            <Col span={1}>
              <div className={cn('fee-transaction__logo', { 'user-transactions': isFromTransactionsByUser })}>
                <TokenIcon tokenName={transaction.info.currency} />
              </div>
            </Col>

            <Col span={4}>
              <div className="fee-transaction__type">
                {getTypeForTransactions(transaction)} {transaction?.isRecurringPayment && '(Recurring payment)'}{' '}
                {identity !== 'nfg' && transaction.swap && '(SWAP)'}
              </div>
            </Col>
            <Col span={5}>
              <div className="fee-transaction__date">{moment(transaction.createdAt).format('MMM D, YYYY, HH:mm;')}</div>
            </Col>
            <Col span={2}>
              <div
                className={`transaction__status transaction__status_${transaction.systemStatus.toLowerCase()} transaction__status_${transaction.systemStatus.toLowerCase()}-${identity}`}
              >
                {transaction.systemStatus}
              </div>
            </Col>
            <Col span={7}>
              <div className="fee-transaction__amount">
                {transaction.info.nft && Object.keys(transaction.info.nft).length ? (
                  <div className="fee-transaction__amount_tokens">
                    {transaction.info.amount.value} {transaction.info.nft.type}
                  </div>
                ) : (
                  <>
                    <div className="fee-transaction__amount_tokens">
                      {transaction.info.amount.value}{' '}
                      {transaction.info.amount.value || transaction.info.amount.value === 0
                        ? transaction.info.currency
                        : ''}{' '}
                      {transaction.info.currencyType === 'token' ? tokenNodeTypeObj[transaction.info.nodeType] : null}
                      {tokenTypeEnd(
                        transaction.info.currencyType,
                        transaction.info.nodeType,
                        transaction.info.currency
                      )}
                    </div>
                    <div className="fee-transaction__amount_usd">${formatCurrency(transaction.info.amount.usd)}</div>
                  </>
                )}
              </div>
            </Col>
          </Row>
        )}

        {transaction.errorMessage && (
          <div className="fee-transaction__error-wrapper">
            <div className="fee-transaction__error-title">Transaction error</div>
            <div className="fee-transaction__error-description">{transaction.errorMessage}</div>
          </div>
        )}
        <div className={`transaction__details ${isOpened && 'transaction__details_opened'}`}>
          <Row>
            <Col>
              <div className="fee-transaction__details-id">
                <b>Payment ID</b>: {transaction._id}
              </div>
              {transaction.info.to && (
                <div className="transaction__details-to">
                  <b>to</b>: {transaction.info.to}
                  <CopyWrapper copyContent={transaction.info.to}>{copyIcon[identity]}</CopyWrapper>
                </div>
              )}

              {transaction.info.multisend && transaction.info.multisend.length ? (
                <div className="transaction__details-to">
                  <b>to</b>:{' '}
                  <span className="transaction__modal-toggle" onClick={() => setShowMultiAddressesModal(true)}>
                    Show multisend addresses
                  </span>
                </div>
              ) : (
                <></>
              )}
              <div className="fee-transaction__details-from">
                <b>From</b>: {transaction.info.from}
                <CopyWrapper copyContent={transaction.info.from}>{copyIcon[identity]}</CopyWrapper>
              </div>

              {transaction.info.nft && Object.keys(transaction.info.nft).length ? (
                <>
                  <div className="transaction-details">
                    <b>Amount</b>: {transaction.info.amount.value}
                  </div>
                  <div className="transaction-details">
                    <b>Type</b>: {transaction.info.nft.type}
                  </div>
                </>
              ) : (
                <></>
              )}

              <div className="fee-transaction__details-fee">
                <b>Miner Fee</b>: {transaction.info.minerFee.value} {transaction.info.minerFee.currency}{' '}
                {transaction.info.currencyType === 'token' ? tokenNodeTypeObj[transaction.info.nodeType] : null}
                {tokenTypeEnd(transaction.info.currencyType, transaction.info.nodeType, transaction.info.currency)}⸺ $
                {formatCurrency(transaction.info.minerFee.usd)}
              </div>
              {transaction.info.systemFee && (
                <div className="fee-transaction__details-fee">
                  <b>System Fee</b>: {transaction.info.systemFee.amount} {transaction.info.systemFee.currency}{' '}
                  {transaction.info.currencyType === 'token' ? tokenNodeTypeObj[transaction.info.nodeType] : null}
                  {tokenTypeEnd(transaction.info.currencyType, transaction.info.nodeType, transaction.info.currency)} ⸺
                  ${formatCurrency(transaction.info.systemFee.amountUSD)}
                </div>
              )}
              {transaction.checkout.charge && transaction.checkout.charge.outsideOrderId && (
                <div className="transaction__details-click-id">
                  <b>clickId</b>: <span>{transaction.checkout.charge.outsideOrderId}</span>
                </div>
              )}
              {transaction.type === 'ExternalCall' && (
                <div className="transaction__details-external-call">
                  {!!Object.keys(transaction.info.call).length && (
                    <>
                      <b>contract Address</b>: <span>{transaction.info.call?.contractAddress}</span>{' '}
                      <CopyWrapper copyContent={transaction.info.call?.contractAddress}>
                        {copyIcon[identity]}
                      </CopyWrapper>
                      <br />
                      <div className="transaction__details-external-call-item">
                        <b>method</b>: <span>{transaction.info.call?.method}</span>
                      </div>
                    </>
                  )}

                  <div className="transaction__details-external-call-item">
                    <b>arguments</b>: <br />
                    <ul className="transaction__details-external-call-arguments">
                      {!!Object.keys(transaction.info.call).length &&
                        transaction.info.call?.arguments?.map((item, index) => (
                          <>
                            <li>
                              {item} {index === 0 && <CopyWrapper copyContent={item}>{copyIcon[identity]}</CopyWrapper>}
                            </li>
                          </>
                        ))}
                      {Object.keys(transaction.info.call).length === 0 &&
                        !!Object.keys(transaction.info?.solanaMint) &&
                        Object.entries(transaction.info?.solanaMint)?.map(([key, value], index) => (
                          <li key={index}>
                            {key} - {value}{' '}
                            {index === 0 && <CopyWrapper copyContent={value}>{copyIcon[identity]}</CopyWrapper>}
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              )}
              {transaction.info.explorerUrl.current ? (
                <div className={`fee-transaction__details-hash fee-transaction__details-hash-${identity}`}>
                  <>
                    <b>Hash:</b>{' '}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={transaction.type === 'InternalTransfer' ? null : `${transaction.info.explorerUrl.current}`}
                      className={`link link-${identity}`}
                    >
                      {getHashFromLink(transaction.info.explorerUrl.current)}
                    </a>
                    <CopyWrapper copyContent={getHashFromLink(transaction.info.explorerUrl.current)}>
                      {copyIcon[identity]}
                    </CopyWrapper>
                  </>
                </div>
              ) : null}

              {transaction.info.explorerUrl.incoming ? (
                <div className={`fee-transaction__details-hash fee-transaction__details-hash-${identity}`}>
                  <>
                    <b>{t('homeTransactions.incomingHash')}</b>:{' '}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`${transaction.info.explorerUrl.incoming}`}
                      className={`link link-${identity}`}
                    >
                      {getHashFromLink(transaction.info.explorerUrl.incoming)}
                    </a>
                    <CopyWrapper copyContent={getHashFromLink(transaction.info.explorerUrl.incoming)}>
                      {copyIcon[identity]}
                    </CopyWrapper>
                  </>
                </div>
              ) : null}
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = state => ({
  networkFilter: state.networkFilter,
  currencies: state.currencies.data,
});

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(Transaction);
