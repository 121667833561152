import React, { useEffect, useState } from 'react'
import { Row, Col } from 'antd';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import TransactionFilters from '../components/TransactionFilters/TransactionFilters'
import TransactionHistory from '../components/TransactionHistory/TransactionHistory';
import useWindowSize from '../utils/resizeHook';

import { AppConfig } from '../config';
import { downloadTransactionsInfo, getMoreTransactions, getTransactions } from '../redux/actions/transactions';
import { getMerchantBalances } from '../redux/actions/merchantData';
import moment from "moment/moment";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const TransactionsHistory = ({
  merchantId,
  networkFilter,
  transactions,
  transactionsFetching,
  moreTransactionsFetching,
  countItem,
  merchantBalances,
  currencies,
  getTransactions,
  getMoreTransactions,
  getMerchantBalances,
  downloadTransactionsInfo,
  transInfoFetching,
}) => {
  const [time, setTime] = useState([]);

  const setTimes = (value) => setTime(value);

  const dateFrom = time?.length === 2 && Date.parse(moment(time[0]).format('YYYY-MM-DD') + 'T00:00:00');
  const dateTo = time?.length === 2 && Date.parse(moment(time[1]).format('YYYY-MM-DD') + 'T23:59:59');

  const query = useQuery();
  const defaultParams = {
    search: query.get('checkoutId') || '',
    order: 'DESC',
    currencyId: undefined,
    type: 'replenishment,withdrawal,Error,refund,multisend,InternalTransfer,ExternalCall',
    page: 1,
    limit: 20,
    typeNetwork: networkFilter,
    onlyNft: false,
    nftType: undefined,
    withoutNft: !AppConfig.showNftBlock ? true : JSON.parse(localStorage.getItem('hideNft')) || false
  };

  const [search, setSearch] = useState(defaultParams.search);
  const [order, setOrder] = useState(defaultParams.order);
  const [type, setType] = useState(defaultParams.type);
  const [page, setPage] = useState(defaultParams.page);
  const [onlyNft, setOnlyNft] = useState(defaultParams.onlyNft);
  const [nftType, setNftType] = useState(defaultParams.nftType);
  const [hideNft, setHideNft] = useState(defaultParams.withoutNft);
  const limit = defaultParams.limit;
  const [socket, setSocket] = useState(undefined);

  const { currentWidth } = useWindowSize();

  useEffect(() => {
    if (merchantId) {
      getMerchantBalances(merchantId, networkFilter, true);
      setSearch(defaultParams.search);
      setOrder(defaultParams.order);
      setType(defaultParams.type);
      setPage(defaultParams.page);
      setOnlyNft(defaultParams.onlyNft);
      setNftType(defaultParams.nftType);
      setHideNft(defaultParams.withoutNft);

      getTransactions(merchantId, {
        search,
        order,
        currencyId: defaultParams.currencyId,
        type,
        page,
        limit,
        typeNetwork: networkFilter,
        onlyNft: onlyNft ? true : undefined,
        nftType: onlyNft ? nftType : undefined,
        withoutNft: hideNft ? true : undefined,
        from: dateFrom || undefined,
        to: dateTo || undefined,
      });
    }
  }, [merchantId, networkFilter, time]);

  useEffect(() => {
    localStorage.setItem('search_tr', search)
    localStorage.setItem('order_tr', order)
    localStorage.setItem('type_tr', type)
    localStorage.setItem('page_tr', page)
    localStorage.setItem('nftType_tr', nftType)
    localStorage.setItem('onlyNft_tr', onlyNft)
    localStorage.setItem('hideNft_tr', hideNft)
  }, [search, order, type, page]);


  useEffect(() => {
    setPage(defaultParams.page);
    if (merchantId) {
      getTransactions(merchantId, {
        search,
        order,
        currencyId: defaultParams.currencyId,
        type,
        page: defaultParams.page,
        limit,
        typeNetwork: networkFilter,
        onlyNft: onlyNft ? true : undefined,
        nftType: onlyNft ? nftType : undefined,
        withoutNft: hideNft ? true : undefined,
        from: dateFrom || undefined,
        to: dateTo || undefined,
      });
    }
  }, [order, type, onlyNft, nftType, hideNft, time]);

  useEffect(() => {
    if (page > 1) {
      getMoreTransactions(merchantId, {
        search,
        order,
        currencyId: defaultParams.currencyId,
        type,
        page,
        limit,
        typeNetwork: networkFilter,
        onlyNft: onlyNft ? true : undefined,
        nftType: onlyNft ? nftType : undefined,
        withoutNft: hideNft ? true : undefined,
        from: dateFrom || undefined,
        to: dateTo || undefined,
      });
    }
  }, [page]);

  useEffect(() => {
    socket && socket.disconnect();
    // setSocket(undefined);
    createSocket(merchantId);
}, [merchantId]);

const createSocket = merchantId => {
  const io = window.io;
  const socket = io(process.env.REACT_APP_API_URL, {
    allowEIO3: true,
    withCredentials: true,
  });
  socket.on('connect', () => {
    socket.emit('sign-in-merchant', { token: localStorage.getItem('authToken'), merchantId });
    setSocket(socket);
  });

  socket.on('pendingTransactionStatus', data => {
    if (merchantId) {
      getTransactions(
        merchantId,
        {
          search: localStorage.getItem('search_tr'),
          order: localStorage.getItem('order_tr'),
          currencyId: undefined,
          type: localStorage.getItem('type_tr'),
          page: defaultParams.page,
          limit,
          typeNetwork: networkFilter,
          onlyNft: localStorage.getItem('onlyNft_tr') === 'true' ? true : undefined,
          nftType:
          localStorage.getItem('onlyNft_tr') === 'true' && ['erc721', 'erc1155'].includes(localStorage.getItem('nftType_tr'))
              ? localStorage.getItem('nftType_tr')
              : undefined,
          withoutNft: localStorage.getItem('hideNft_tr') === 'true' ? true : undefined,
          from: dateFrom || undefined,
          to: dateTo || undefined,
        },
        true
      );
    }
  });

  socket.on('confirmedTransaction', data => {
    // console.log('incomingTransaction: ', data);
    if (merchantId  && networkFilter === data.typeNetwork) {
      getTransactions(
        merchantId,
        {
          search: localStorage.getItem('search_tr'),
          order: localStorage.getItem('order_tr'),
          currencyId: undefined,
          type: localStorage.getItem('type_tr'),
          page: defaultParams.page,
          limit,
          typeNetwork: data.typeNetwork,
          onlyNft: localStorage.getItem('onlyNft_tr') === 'true' ? true : undefined,
          nftType: localStorage.getItem('onlyNft_tr') === 'true' && ['erc721', 'erc1155'].includes(localStorage.getItem('nftType_tr')) ? localStorage.getItem('nftType_tr') : undefined,
          withoutNft: localStorage.getItem('hideNft_tr') === 'true' ? true : undefined,
          from: dateFrom || undefined,
          to: dateTo || undefined,
        },
        true
      );

      if (data.systemStatus === 'Done') {
        getMerchantBalances(merchantId, networkFilter, true);
      }
    }
  });
  socket.on('incomingTransaction', data => {
  //  console.log('incomingTransaction: ', data);
    if (merchantId && networkFilter === data.typeNetwork) {
      getTransactions(
        merchantId,
        {
          search: localStorage.getItem('search_tr'),
          order: localStorage.getItem('order_tr'),
          currencyId: localStorage.getItem('currId_tr') || undefined,
          type: localStorage.getItem('type_tr'),
          page: defaultParams.page,
          limit,
          typeNetwork: data.typeNetwork,
          onlyNft: localStorage.getItem('onlyNft_tr') === 'true' ? true : undefined,
          nftType: localStorage.getItem('onlyNft_tr') === 'true' && ['erc721', 'erc1155'].includes(localStorage.getItem('nftType_tr')) ? localStorage.getItem('nftType_tr') : undefined,
          withoutNft: localStorage.getItem('hideNft_tr') === 'true' ? true : undefined,
          from: dateFrom || undefined,
          to: dateTo || undefined,
        },
        true
      );
      if (data.systemStatus === 'Done') {
        getMerchantBalances(merchantId, networkFilter, true);
      }
    }
  });
};

  const submitSearch = search => {
    setSearch(search);
    setPage(defaultParams.page);
    getTransactions(merchantId, {
      search,
      order,
      currencyId: defaultParams.currencyId,
      type,
      typeNetwork: networkFilter,
      page: defaultParams.page,
      limit,
      onlyNft: onlyNft ? true : undefined,
      nftType: onlyNft ? nftType : undefined,
      withoutNft: hideNft ? true : undefined,
      from: dateFrom || undefined,
      to: dateTo || undefined,
    });
  };

  const resetFilters = () => {
    query.delete('checkoutId');
    const newurl = window.location.protocol + '//' + window.location.host + window.location.pathname;
    window.history.pushState({ path: newurl }, '', newurl);

    setSearch('');
    setOrder(defaultParams.order);
    setType(defaultParams.type);
    setPage(defaultParams.page);
    setOnlyNft(defaultParams.onlyNft);
    setNftType(defaultParams.nftType);
    setHideNft(defaultParams.withoutNft);
    setTime([]);

    getTransactions(merchantId, { ...defaultParams, search: '' });
  };


  return (
    <React.Fragment>
       <Row gutter={[20, 20]}>
        <Col span={currentWidth >= 1024 ? 7 : 24}>
        <TransactionFilters
         currencyId={defaultParams.currencyId}
         merchantBalances={merchantBalances}
         transactions={transactions}
         transactionsFetching={transactionsFetching}
         moreTransactionsFetching={moreTransactionsFetching}
         currencies={currencies}
         networkFilter={networkFilter}
         countItem={countItem}
         merchantId={merchantId}
         getTransactions={getTransactions}
         page={page}
         setPage={setPage}
         search={search}
         setSearch={setSearch}
         order={order}
         setOrder={setOrder}
         type={type}
         setType={setType}
         submitSearch={submitSearch}
         resetFilters={resetFilters}
         onlyNft={onlyNft}
         setOnlyNft={setOnlyNft}
         hideNft={hideNft}
         setHideNft={setHideNft}
         nftType={nftType}
         setNftType={setNftType}
         fromPage={'home'}
        />
        </Col>
        <Col span={currentWidth >= 1024 ? 17 : 24}>
        <TransactionHistory
          transactions={transactions}
          transactionsFetching={transactionsFetching}
          moreTransactionsFetching={moreTransactionsFetching}
          currencies={currencies}
          networkFilter={networkFilter}
          countItem={countItem}
          merchantId={merchantId}
          page={page}
          setPage={setPage}
          search={search}
          setSearch={setSearch}
          order={order}
          setOrder={setOrder}
          type={type}
          setType={setType}
          submitSearch={submitSearch}
          resetFilters={resetFilters}
          downloadTransactionsInfo={downloadTransactionsInfo}
          transInfoFetching={transInfoFetching}
          setTime={setTimes}
        />
        </Col>
      </Row>
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  merchantId: state.transactions.merchantId,
  networkFilter: state.networkFilter,
  transactions: state.transactions.data,
  transactionsFetching: state.transactions.fetching,
  moreTransactionsFetching: state.transactions.loadMoreFetching,
  countItem: state.transactions.countItem,
  merchantBalances: state.merchantData.balances.balances || [],
  currencies: state.currencies.data,
  transInfoFetching: state.transactions.download.fetching,
});

const mapDispatchToProps = dispatch => ({
  getTransactions: getTransactions(dispatch),
  getMoreTransactions: getMoreTransactions(dispatch),
  getMerchantBalances: getMerchantBalances(dispatch),
  downloadTransactionsInfo: downloadTransactionsInfo(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TransactionsHistory)