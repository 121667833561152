import { cryptoApi } from '../../service/cryptopay-api';
import { notificationsErrorShow } from './notifications';
import moment from 'moment';
import * as FileSaver from 'file-saver';

// CONSTANTS
export const TRANSACTIONS_GET = 'TRANSACTIONS_GET';
export const TRANSACTIONS_GET_MORE = 'TRANSACTIONS_GET_MORE';
export const TRANSACTIONS_SET = 'TRANSACTIONS_SET';
export const TRANSACTIONS_SET_MORE = 'TRANSACTIONS_SET_MORE';
export const TRANSACTIONS_ERROR = 'TRANSACTIONS_ERROR';
export const TRANSACTIONS_RESET_PAGE = 'TRANSACTIONS_RESET_PAGE';
export const TRANSACTIONS_SET_ACTIVE_MERCHANT = 'TRANSACTIONS_SET_ACTIVE_MERCHANT';

// DOWNLOAD_TRANS_IN_EXCEL
export const GET_TRANSACTIONS_INFO_DOWNL_REQUEST = 'GET_TRANSACTIONS_INFO_DOWNL_REQUEST';
export const GET_TRANSACTIONS_INFO_DOWNL_SUCCESS = 'GET_TRANSACTIONS_INFO_DOWNL_SUCCESS';
export const GET_TRANSACTIONS_INFO_DOWNL_ERROR = 'GET_TRANSACTIONS_INFO_DOWNL_ERROR';

// DOWNLOAD_ADMIN_TRANS_IN_EXCEL
export const GET_ADMIN_TRANSACTIONS_INFO_DOWNL_REQUEST = 'GET_ADMIN_TRANSACTIONS_INFO_DOWNL_REQUEST';
export const GET_ADMIN_TRANSACTIONS_INFO_DOWNL_SUCCESS = 'GET_ADMIN_TRANSACTIONS_INFO_DOWNL_SUCCESS';
export const GET_ADMIN_TRANSACTIONS_INFO_DOWNL_ERROR = 'GET_ADMIN_TRANSACTIONS_INFO_DOWNL_ERROR';

// ACTIONS GENERATORS
export const transactionsGet = () => ({
  type: TRANSACTIONS_GET,
  fetching: true,
});

export const transactionsGetMore = () => ({
  type: TRANSACTIONS_GET_MORE,
  loadMoreFetching: true,
});

export const transactionsSet = data => ({
  type: TRANSACTIONS_SET,
  fetching: false,
  payload: data,
});

export const transactionsSetMore = data => ({
  type: TRANSACTIONS_SET_MORE,
  loadMoreFetching: false,
  payload: data,
});

export const transactionsError = () => ({
  type: TRANSACTIONS_ERROR,
});

export const resetPageCounter = () => ({
  type: TRANSACTIONS_RESET_PAGE,
});

export const transactionsSetActiveMerchant = data => ({
  type: TRANSACTIONS_SET_ACTIVE_MERCHANT,
  payload: data,
});

// DONWLOAD TRANS

export const getTransactionsInfoDownloadRequest = () => ({
  type: GET_TRANSACTIONS_INFO_DOWNL_REQUEST,
});

export const getTransactionsInfoDownloadSuccess = () => ({
  type: GET_TRANSACTIONS_INFO_DOWNL_SUCCESS,
});

export const getTransactionsInfoDownloadError = () => ({
  type: GET_TRANSACTIONS_INFO_DOWNL_ERROR,
});
// DONWLOAD ADMIN TRANS

export const getAdminTransactionsInfoDownloadRequest = () => ({
  type: GET_ADMIN_TRANSACTIONS_INFO_DOWNL_REQUEST,
});

export const getAdminTransactionsInfoDownloadSuccess = () => ({
  type: GET_ADMIN_TRANSACTIONS_INFO_DOWNL_SUCCESS,
});

export const getAdminTransactionsInfoDownloadError = () => ({
  type: GET_ADMIN_TRANSACTIONS_INFO_DOWNL_ERROR,
});

// ASYNC ACTIONS
export const setActiveMerchant = dispatch => async merchantId => {
  localStorage.setItem('merchantId', merchantId);
  dispatch(transactionsSetActiveMerchant(merchantId));
};

export const getTransactions =
  dispatch =>
  async (merchantId, params, fromSockets = false) => {
    !fromSockets && dispatch(transactionsGet());
    let transactions;

    try {
      transactions = await cryptoApi.getTransactions(merchantId, params);
    } catch (err) {
      dispatch(transactionsError());
      dispatch(notificationsErrorShow(err.data));
      return;
    }

    if (transactions && transactions.data) {
      dispatch(transactionsSet(transactions.data));
      return;
    }

    dispatch(transactionsError());
  };

export const getMoreTransactions = dispatch => async (merchantId, params) => {
  dispatch(transactionsGetMore());
  let transactions;

  try {
    transactions = await cryptoApi.getTransactions(merchantId, params);
  } catch (err) {
    dispatch(transactionsError());
    dispatch(notificationsErrorShow(err.data));
  }

  if (transactions && transactions.data) {
    dispatch(transactionsSetMore(transactions.data));
    return;
  }

  dispatch(transactionsError());
};

// DOWNLOAD TRANSACTIONS INFO
export const downloadTransactionsInfo = dispatch => async (body, merchantId, networkFilter) => {
  dispatch(getTransactionsInfoDownloadRequest());

  const data = {
    ...body,
    dateForFiltering: undefined,
    dateFrom:
      body.dateForFiltering && body.dateForFiltering[0]
        ? moment(body.dateForFiltering[0]).format('YYYY-MM-DD') + 'T00:00:01'
        : undefined,
    dateTo:
      body.dateForFiltering && body.dateForFiltering[1]
        ? moment(body.dateForFiltering[1]).format('YYYY-MM-DD') + 'T23:59:59'
        : undefined,
    typeNetwork: networkFilter,
  };

  try {
    const response = await cryptoApi.downloadTransactionsInfo(data, merchantId);
    const file = await response.blob();
    FileSaver.saveAs(file, `transactions-${moment(Date.now()).format('YYYY-MM-DDTHH:mm:ss')}.xlsx`);

    dispatch(getTransactionsInfoDownloadSuccess());
  } catch (error) {
    const notif = await error.json();
    dispatch(notificationsErrorShow(notif.data));
    dispatch(getTransactionsInfoDownloadError());
  }
};
// DOWNLOAD ADMIN TRANSACTIONS INFO
export const downloadAdminTransactionsInfo =
  (body = {}, networkFilter) =>
  async dispatch => {
    dispatch(getAdminTransactionsInfoDownloadRequest());

    const data = {
      dateForFiltering: undefined,
      dateFrom:
        body.dateRange && body.dateRange[0] ? moment(body.dateRange[0]).format('YYYY-MM-DD') + 'T00:00:01' : undefined,
      dateTo:
        body.dateRange && body.dateRange[1] ? moment(body.dateRange[1]).format('YYYY-MM-DD') + 'T23:59:59' : undefined,
      typeNetwork: networkFilter,
    };

    try {
      const response = await cryptoApi.downloadAdminTransactionsInfo(data);
      const file = await response.blob();
      FileSaver.saveAs(file, `transactions-${moment(Date.now()).format('YYYY-MM-DDTHH:mm:ss')}.xlsx`);

      dispatch(getAdminTransactionsInfoDownloadSuccess());
    } catch (error) {
      const notif = await error.json();
      dispatch(notificationsErrorShow(notif.data));
      dispatch(getAdminTransactionsInfoDownloadError());
    }
  };

// DOWNLOAD ADMIN USER TRANSACTIONS INFO

export const downloadAdminUserTransactionsInfo =
  (userId, body = {}, networkFilter) =>
    async dispatch => {
      const data = {
        dateForFiltering: undefined,
        dateFrom:
          body.dateRange && body.dateRange[0] ? moment(body.dateRange[0]).format('YYYY-MM-DD') + 'T00:00:01' : undefined,
        dateTo:
          body.dateRange && body.dateRange[1] ? moment(body.dateRange[1]).format('YYYY-MM-DD') + 'T23:59:59' : undefined,
        typeNetwork: networkFilter,
      };

      try {
        const response = await cryptoApi.downloadAdminUserTransactionsInfo(userId, data);
        const file = await response.blob();
        FileSaver.saveAs(file, `transactions-${moment(Date.now()).format('YYYY-MM-DDTHH:mm:ss')}.xlsx`);

      } catch (error) {
        const notif = await error.json();
        dispatch(notificationsErrorShow(notif.data));
      }
    };
