import React from 'react';
import { Modal } from 'antd';
import { ReactComponent as CloseSVG } from '../../../img/default-svg/close-black.svg';
import './style.scss';
import Button from "../../Button";
import { identity } from "../../../utils/getIdentity";

const DeletePlan = ({ visible, close, removePlan }) => {
  return (
    <Modal
      width={620}
      title=""
      visible={visible}
      onCancel={close}
      footer={null}
      className={`checkout-modal checkout-modal-${identity}`}
      centered
      closeIcon={<CloseSVG />}
      destroyOnClose
      maskClosable={false}
    >
      <div className="checkout-modal-content">
        <div className="checkout-modal-content__title">
          Do you want to delete this plan?
        </div>
        <div className="checkout-modal-content__body">
          <div className="checkout-modal-content__body--button">
            <Button onClick={() => close()} type="secondary">Back</Button>
            <Button onClick={removePlan} type="danger">Delete</Button>
          </div>
        </div>
      </div>
    </Modal>
  )
};

export default DeletePlan;