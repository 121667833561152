import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Form, Input } from 'antd';
import Button from '../Button';
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';
import { useTranslation } from 'react-i18next';
import { resetPassword } from '../../redux/actions/user';
import { notificationsErrorShow, notificationsSuccessShow } from '../../redux/actions/notifications';
import ReCAPTCHA from 'react-google-recaptcha';
import emailIcon from '../../img/default-svg/email.svg';
import { identity } from '../../utils/getIdentity';

const ResetPasswordForm = ({ resetPassword, notificationsErrorShow, notificationsSuccessShow }) => {
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const recaptchaRef = useRef();
  const [words, setWords] = useState([]);
  const { t } = useTranslation();
  const history = useHistory();

  const handleChange = tags => {
    setWords(tags);
  };

  const defaultPasteSplit = data => {
    return data.split(' ').map(d => d.trim());
  };

  const handleSubmit = async () => {
    const recaptcha = await recaptchaRef.current.executeAsync();

    const formData = {
      email: email.trim(),
      newPassword: newPassword.trim(),
      words,
    };

    if (newPassword === confirmNewPassword) {
      const response = await resetPassword(formData, recaptcha)
      if (response?.data?.message === 'ok') {
        history.push('/login');
        notificationsSuccessShow({ message: t('success') });
      }
      recaptchaRef.current.reset();
    } else {
      notificationsErrorShow({ message: t('validation.passwordsShouldMatch') });
      recaptchaRef.current.reset();
    }
  };

  return (
    <Form name="basic" initialValues={{ remember: true }} className={`login-form login-form-${identity}`}>
      <p className={`login-form__label login-form__label-${identity}`}>{t('email')}</p>
      <Form.Item name="email" rules={[{ required: true, message: t('validation.required') }]}>
        <Input
          suffix={<img src={emailIcon} alt="email icon" />}
          placeholder={t('email')}
          className="login-form__input"
          onChange={e => setEmail(e.target.value)}
        />
      </Form.Item>

      <p className={`login-form__label login-form__label-${identity}`}>{t('newPassword')}</p>
      <Form.Item name="new-password" rules={[{ required: true, message: t('validation.required') }]}>
        <Input.Password
          placeholder={t('newPassword')}
          className="login-form__input"
          onChange={e => setNewPassword(e.target.value)}
        />
      </Form.Item>

      <p className={`login-form__label login-form__label-${identity}`}>{t('confirmPassword')}</p>
      <Form.Item name="confirm-new-password" rules={[{ required: true, message: t('validation.required') }]}>
        <Input.Password
          placeholder={t('confirmPassword')}
          className="login-form__input"
          onChange={e => setConfirmNewPassword(e.target.value)}
        />
      </Form.Item>

      <p className={`login-form__label login-form__label-${identity}`}>{t('auth.mnemonicPhrase')}</p>
      <span className="login-form__tip">{t('auth.mnemonicTip')}</span>
      <TagsInput
        className={`login-form__phrase login-form__phrase-${identity}`}
        addKeys={[9, 13, 32]}
        value={words}
        onChange={handleChange}
        maxTags="12"
        addOnPaste={true}
        pasteSplit={defaultPasteSplit}
        inputProps={{
          placeholder: t('auth.addTag'),
        }}
      />
      <Button
        type="primary"
        className={`login-form__button login-form__button-${identity}`}
        onClick={handleSubmit}
        // loading={fetchingUser}
      >
        {t('auth.resetPassword')}
      </Button>
      <div className={`login-form__text login-form__text_space-between login-form__text_space-between-${identity}`}>
        <Link to="/login">{t('logIn')}</Link>
        {identity !== 'finvaro' && <Link to="/sign-up">{t('signUp')}</Link>}
      </div>
      <ReCAPTCHA ref={recaptchaRef} sitekey={process.env.REACT_APP_CAPTCHA_KEY} size="invisible" />
    </Form>
  );
};

const mapDispatchToProps = dispatch => ({
  resetPassword: resetPassword(dispatch),
  notificationsErrorShow: error => dispatch(notificationsErrorShow(error)),
  notificationsSuccessShow: success => dispatch(notificationsSuccessShow(success)),
});

export default connect(null, mapDispatchToProps)(ResetPasswordForm);
