import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import Transaction from "../HomeTransactions/Transaction";
import Button from "../Button";

import "./styles.scss";
import { identity } from "../../utils/getIdentity";
import TransactionFilters from "../HomeTransactions/TransactionFilters";
import EmptyTransactions from "./EmptyTransactions";
import moment from "moment";

const TransactionHistory = ({
  transactions,
  transactionsFetching,
  moreTransactionsFetching,
  currencies,
  networkFilter,
  countItem,
  merchantId,
  page,
  setPage,
  search,
  setSearch,
  order,
  setOrder,
  type,
  setType,
  submitSearch,
  resetFilters,
  downloadTransactionsInfo,
  setTime,
  transInfoFetching
}) => {
  const { t } = useTranslation();

  return (
    <div className="transaction-history">
      <div className="transaction-history__content">
        <div className="transaction-history__title">{t("transactionHistory.transactionHistory")}</div>
        <TransactionFilters
        search={search}
        setTime={setTime}
        setSearch={setSearch}
        order={order}
        setOrder={setOrder}
        type={type}
        setType={setType}
        submitSearch={submitSearch}
        resetFilters={resetFilters}
        downloadTransactionsInfo={downloadTransactionsInfo}
        merchantId={merchantId}
        networkFilter={networkFilter}
        transInfoFetching={transInfoFetching}
        fromPage={'history'}
      />
        {!transactionsFetching ? (
          <>
            {transactions.length ? (
              <Row className="transactions__legend">
                <Col span={1}>{t("type")}</Col>
                <Col span={7} offset={6}>
                  {t("dataTime")}
                </Col>
                <Col span={2}>{t("status")}</Col>
                <Col span={8} className="transactions__legend-amount">
                  {t("amount")}
                </Col>
              </Row>
            ) : null}
            <div className="transactions-list">
              {transactions.length ? (
                transactions.map((transaction) => (
                  <Transaction key={transaction._id} transaction={transaction} currencies={currencies} networkFilter={networkFilter} />
                ))
              ) : (
                <EmptyTransactions />
              )}
            </div>
            {transactions.length && transactions.length < countItem ? (
              <>
                <Button
                  type="primary"
                  className={`transactions__load-more transactions__load-more-${identity}`}
                  loading={moreTransactionsFetching}
                  onClick={() => !moreTransactionsFetching && setPage(page + 1)}
                >
                  {t("homePage.transactions.loadMore")}
                </Button>
              </>
            ) : null}
          </>
        ) : (
          <div className="transactions__loader">
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
          </div>
        )}
      </div>
    </div>
  );
};

export default TransactionHistory;