import React, { useState, useEffect } from 'react';
import '../MerchantEdit/MerchantDataForm.scss';
import Button from "../Button";
import { useTranslation } from "react-i18next";
import { Form, Row, Col, Input, notification } from "antd";
import useWindowSize from "../../utils/resizeHook";
import plus from '../../img/default-svg/plus-circle.svg';
import { connect, useDispatch } from "react-redux";
import {
  getMerchantSecurity,
  changeMerchantSecurity,
  changeMerchantSecuritySuccess,
  setMerchantDataTwoFactorToken,
  setMerchantDataEmail,
  setMerchantDataVerifyCode
} from "../../redux/actions/checkouts";
import { useLocation, useParams } from "react-router-dom";
import SecurityVerifyModal from "./SecurityVerifyModal";
import { ReactComponent as CloseIcon } from '../../img/default-svg/close-black.svg'

const SecurityDataForm = ({
  getMerchantSecurity,
  merchantSecurity,
  changeMerchantSecurity ,
  twoFactorToken,
  emailOtp,
  verifyCode,
  merchantSecurityChange,
  merchantIds
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { currentWidth } = useWindowSize();
  const { merchantId } = useParams();
  const dispatch = useDispatch();

  const [whiteListIP, setWhiteListIP] = useState(['']);
  const [origin, setOrigin] = useState(['']);
  const [userAgent, setUserAgent] = useState('');
  const [twoFactorTokens, setTwoFactorTokens] = useState('');
  const [emailOtps, setEmailOtps] = useState('');
  const [verifyCodes, setVerifyCodes] = useState('');
  const [isOpenVerifyModal, setIsOpenVerifyModal] = useState(false);
  const { pathname } = useLocation();

  const ids = pathname.includes('merchants/edit') ? merchantId : merchantIds;

  const handleWhiteListIP = (value, index) => {
    const updatedList = [...whiteListIP];
    updatedList[index] = value;
    setWhiteListIP(updatedList);
    form.setFieldsValue({
      whiteListIp: updatedList
    });
  };

  const handleOrigin = (value, index) => {
    const updatedList = [...origin];
    updatedList[index] = value;
    setOrigin(updatedList);
    form.setFieldsValue({
      origin: updatedList
    });
  };

  const changeSecurity = () => {
    if (
      !merchantSecurity?.ips &&
      !merchantSecurity?.origin &&
      !merchantSecurity?.userAgent &&
      whiteListIP?.length === 1 && whiteListIP[0] === '' &&
      origin?.length === 1 && origin[0] === '' &&
      !userAgent
    ) {
      notification.error({
        message: 'You need to fill form to save changes'
      });
    } else {
      const filteredWhiteListIP = whiteListIP.filter(item => item.trim() !== "");
      const filteredOrigin = origin.filter(item => item.trim() !== "");
      changeMerchantSecurity(ids, {
        ips: filteredWhiteListIP,
        origin: filteredOrigin,
        userAgent: userAgent,
        twoFactorToken: twoFactorTokens || undefined,
        emailOtp: emailOtps || undefined,
        verifyCode: verifyCodes || undefined
      });
    }
  };

  useEffect(() => {
    if (merchantSecurityChange) {
      setIsOpenVerifyModal(false);
      getMerchantSecurity(ids);

      setTwoFactorTokens('');
      setEmailOtps('');
      setVerifyCodes('');

      dispatch(changeMerchantSecuritySuccess(false));
      dispatch(setMerchantDataTwoFactorToken(false));
      dispatch(setMerchantDataEmail(false));
      dispatch(setMerchantDataVerifyCode(false));
    }
  }, [merchantSecurityChange]);

  useEffect(() => {
    if (ids) {
      getMerchantSecurity(ids);
    }
  }, [ids]);

  useEffect(() => {
    if (merchantSecurity) {
      if (merchantSecurity?.ips && Array.isArray(merchantSecurity?.ips) && merchantSecurity?.ips?.length > 0) {
        setWhiteListIP(merchantSecurity?.ips);
        form.setFieldsValue({
          whiteListIp: merchantSecurity?.ips
        });
      } else {
        setWhiteListIP(['']);
        form.setFieldsValue({
          whiteListIp: ['']
        });
      }

      if (merchantSecurity?.origin && Array.isArray(merchantSecurity?.origin) && merchantSecurity?.origin?.length > 0) {
        setOrigin(merchantSecurity?.origin);
        form.setFieldsValue({
          origin: merchantSecurity?.origin
        });
      } else {
        setOrigin(['']);
        form.setFieldsValue({
          origin: ['']
        });
      }

      if (merchantSecurity?.userAgent) {
        setUserAgent(merchantSecurity?.userAgent);
        form.setFieldsValue({
          userAgent: merchantSecurity?.userAgent
        });
      }
    }
  }, [merchantSecurity]);

  useEffect(() => {
    if (verifyCode || emailOtp) {
      setIsOpenVerifyModal(true)
    }
  }, [verifyCode, emailOtp]);

  const onClose = () => {
    setIsOpenVerifyModal(false);
    setTwoFactorTokens('');
    setEmailOtps('');
    setVerifyCodes('');
    dispatch(setMerchantDataTwoFactorToken(false));
    dispatch(setMerchantDataEmail(false));
    dispatch(setMerchantDataVerifyCode(false));
  };

  const removeWhiteListInput = (index) => {
    const updatedWhiteList = whiteListIP?.filter((item, i) => i !== index);
    setWhiteListIP(updatedWhiteList);
    form.setFieldsValue({
      whiteListIp: updatedWhiteList
    });
  };

  const removeOriginInput = (index) => {
    const updatedOrigin = origin?.filter((item, i) => i !== index);
    setOrigin(updatedOrigin);
    form.setFieldsValue({
      origin: updatedOrigin
    });
  };

  return (
    <div className="component merchant-data autoswap">
      <div className="component__wrapper">
        <div className="component__title">{t('merchants.securityTitle')}</div>
      </div>
      <Form
        form={form}
        layout="vertical"
        name="basic"
        className="form merchant-data-form"
      >
        <Row>
          <Col span={currentWidth >= 1024 ? 18 : 24}>
            <Row gutter={[20, 0]}>
              {whiteListIP && whiteListIP?.length > 0 && whiteListIP?.map((item, index) => (
                <Col className="security__col" span={currentWidth >= 1024 ? 12 : 24} key={index}>
                  {whiteListIP?.length > 1 && (
                    <div onClick={() => removeWhiteListInput(index)} className={`security__col--close ${index > 1 && 'security__col--close-row'}`}>
                      <CloseIcon />
                    </div>
                  )}
                  <Form.Item
                    label={index === 0 ? t("merchants.whitelistIP") : ""}
                    name={["whiteListIp", index]}
                    className="form__item merchant-data-form__name"
                  >
                    <Input
                      value={whiteListIP[index]}
                      className="form__input"
                      placeholder={t("merchants.whitelistIP")}
                      onChange={e => handleWhiteListIP(e.target.value, index)}
                      maxLength={30}
                    />
                  </Form.Item>
                </Col>
              ))}
            </Row>
            <div className="security" onClick={() => setWhiteListIP([...whiteListIP, ''])}>
              <img src={plus} alt="plus icon" />
              <span className="security__add">{t('merchants.addWhitelistIP')}</span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={currentWidth >= 1024 ? 18 : 24}>
            <Row gutter={[20, 0]}>
              {origin?.map((item, index) => (
                <Col className="security__col" span={currentWidth >= 1024 ? 12 : 24} key={index}>
                  {origin?.length > 1 && (
                    <div onClick={() => removeOriginInput(index)} className={`security__col--close ${index > 1 && 'security__col--close-row'}`}>
                      <CloseIcon />
                    </div>
                  )}
                  <Form.Item
                    label={index === 0 ? t('merchants.origin') : ''}
                    name={['origin', index]}
                    className="form__item merchant-data-form__name"
                  >
                    <Input
                      value={origin[index]}
                      className="form__input"
                      placeholder={t('merchants.origin')}
                      onChange={e => handleOrigin(e.target.value, index)}
                      maxLength={30}
                    />
                  </Form.Item>
                </Col>
              ))}
            </Row>
            <div className="security" onClick={() => setOrigin([...origin, ''])}>
              <img src={plus} alt="plus icon" />
              <span className="security__add">{t('merchants.addOrigin')}</span>
            </div>
          </Col>
        </Row>
        <Row gutter={[20, 0]}>
          <Col span={currentWidth >= 1024 ? 18 : 24}>
            <Form.Item
              label={t('merchants.userAgent')}
              name="userAgent"
              className="form__item merchant-data-form__name"
            >
              <Input
                value={userAgent}
                className="form__input"
                placeholder={t('merchants.userAgent')}
                onChange={e => setUserAgent(e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={currentWidth >= 1024 ? 5 : 24}>
            <Button
              type="primary"
              className="form__button"
              onClick={changeSecurity}
            >
              {t('submit')}
            </Button>
          </Col>
        </Row>
      </Form>

      <SecurityVerifyModal
        isVisible={isOpenVerifyModal}
        handleCancel={onClose}
        handleSubmit={changeSecurity}
        twoFactorToken={twoFactorToken}
        twoFactorTokens={twoFactorTokens}
        emailOtp={emailOtp}
        emailOtps={emailOtps}
        verifyCode={verifyCode}
        verifyCodes={verifyCodes}
        setTwoFactorTokens={setTwoFactorTokens}
        setEmailOtps={setEmailOtps}
        setVerifyCodes={setVerifyCodes}
        merchantSecurityChange={merchantSecurityChange}
      />
    </div>
  )
};

const mapStateToProps = state => ({
  merchantSecurity: state.checkouts.merchantSecurity,
  twoFactorToken: state.checkouts.twoFactorToken,
  emailOtp: state.checkouts.emailOtp,
  verifyCode: state.checkouts.verifyCode,
  merchantIds: state.transactions.merchantId,
  merchantSecurityChange: state.checkouts.merchantSecurityChange
});

const mapDispatchToProps = dispatch => ({
  getMerchantSecurity: dispatch(getMerchantSecurity),
  changeMerchantSecurity: dispatch(changeMerchantSecurity),
});

export default connect(mapStateToProps, mapDispatchToProps)(SecurityDataForm);