import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { Row, Col, notification } from 'antd';
import Button from '../Button';
import { useTranslation } from 'react-i18next';
import {  activatePhoneTwoFactor } from '../../redux/actions/twoFactor';
import useWindowSize from '../../utils/resizeHook';
import PhoneTwoFaModal from './PhoneTwoFaModal';
import { twoFactorApi } from '../../service/two-factor-api';
import { changePhoneTwoFaStatus } from '../../redux/actions/user';
import { identity } from "../../utils/getIdentity";

const PhoneTwoFa = ({
    isPhoneConfirmed,
    isPhoneTwoFactorEnabled,
    isUserHasPhone,
    changePhoneTwoFaStatus,
    phoneTwoFaFetching,
    activatePhoneTwoFactor,
 }) => {
  const { t } = useTranslation();
  const { currentWidth } = useWindowSize();
  const [openTwoFaModal, setOpenTwoFaModal] = useState(false)

  const handleCloseModal = () => setOpenTwoFaModal(false)

  const enable2Fa = () => {
      activatePhoneTwoFactor().then(() => {
        changePhoneTwoFaStatus(true);
      });
  };

  const disable2Fa = async () => {
    try {
      await twoFactorApi.deactivatePhoneTwoFactor({})
      setOpenTwoFaModal(true)
    } catch (error) {
      notification.error({
        message: error.data.message
      })
    }
  }

  return (
    <>
     <div className="profile-block">
        <span className="profile__title">
        {t('profile.enablePhoneTwoFa')} (
        <span className={isPhoneTwoFactorEnabled ? 'profile__title_enabled' : 'profile__title_disabled'}>
          {isPhoneTwoFactorEnabled ? t('profile.enabled') : t('profile.disabled')}
        </span>
        )
      </span>
      <span className={`profile__subtitle profile__subtitle-${identity}`}>
        {isUserHasPhone ? t('profile.enablePhoneTwoFaForSecurity') : t('profile.completePhone')}
      </span>
      <Row gutter={[20, 0]}>
        <Col span={currentWidth >= 1024 ? 4 : 24}>

         <Button
         type="primary"
         disabled={!isPhoneConfirmed || !isUserHasPhone}
         loading={phoneTwoFaFetching}
         className="profile__button"
         onClick={isPhoneTwoFactorEnabled ? disable2Fa : enable2Fa}
       >
         {isPhoneTwoFactorEnabled ? t('profile.disableTwoFa') : t('profile.enableTwoFa')}
       </Button>
        </Col>
      </Row>
    </div>
    {openTwoFaModal && <PhoneTwoFaModal openTwoFaModal={openTwoFaModal} handleCloseModal={handleCloseModal} isPhoneTwoFactorEnabled={isPhoneTwoFactorEnabled} />}
    </>
  )
}

  const mapStateToProps = state => ({
      phoneTwoFaFetching: state.twoFactor.phoneTwoFaFetching
  });

  const mapDispatchToProps = dispatch => ({
    activatePhoneTwoFactor: activatePhoneTwoFactor(dispatch),
    changePhoneTwoFaStatus: changePhoneTwoFaStatus(dispatch),
  });

  export default connect(mapStateToProps, mapDispatchToProps)(PhoneTwoFa);