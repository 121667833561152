import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
// Components
import CheckoutsList from '../components/Checkouts/CheckoutsList';
import CheckoutsHeader from '../components/Checkouts/CheckoutsHeader';
import CheckoutsModals from '../components/Checkouts/CheckoutsModals';
import CheckoutsPagination from '../components/Checkouts/CheckoutsPagination';
// Actions
import {
  getCheckoutList,
  createCheckoutDonation,
  createCheckoutSale,
  createCheckoutSellCurrency,
  createCheckoutCart,
  deleteCheckout,
  setDetailedCheckout,
  setEditCheckout,
  patchCheckout,
  getSupportedCurrenciesList,
  getSupportedWalletsList,
  createNewPlan,
  getPlansList,
  deletePlan,
  patchPlan,
  publishPlan,
} from '../redux/actions/checkouts';
// Utils
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PlansList from '../components/Checkouts/PlansList';
import PlanDetails from '../components/Checkouts/CheckoutsModals/PlanDetails';

const CheckoutsPage = ({
  getCheckoutList,
  createCheckoutDonation,
  createCheckoutSale,
  createCheckoutSellCurrency,
  createCheckoutCart,
  deleteCheckout,
  merchantId,
  merchants,
  getState,
  limit,
  merchantData,
  getSupportedCurrenciesList,
  setDetailedCheckout,
  detailedCheckoutState,
  editCheckoutState,
  setEditCheckout,
  patchCheckout,
  ifCreateFetching,
  ifPatchFetching,
  getMerchantData,
  getMerchants,
  supportedCurrencies,
  typeNetwork,
  supportedWallets,
  getSupportedWalletsList,
  createNewPlan,
  getPlansList,
  plans,
  deletePlan,
  patchPlan,
  publishPlan,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [step, setStep] = useState(0);
  const [editStep, setEditStep] = useState(0);
  const [checkoutCustomerInfo, setCheckoutCustomerInfo] = useState({
    collectName: false,
    collectEmail: false,
  });
  const [checkoutType, setCheckoutType] = useState(null);
  const [isDuplicating, setIsDuplicating] = useState(null);
  const [search, setSearch] = useState(null);
  const [activeMerchantCurrencies, setActiveMerchantCurrencies] = useState([]);
  const [isRecurring, setIsRecurring] = useState(false);
  const [isOpenPlanDetails, setIsOpenPlanDetails] = useState(false);
  const [isPublished, setIsPublished] = useState(false);
  const location = useLocation();

  const type = location.pathname === '/recurring-billing' ? 'plan' : 'checkout';

  const { data, page, pages, countItem, fetching } = type === 'plan' ? plans : getState;

  const currentPage = localStorage.getItem('planPage') || 1;

  const CHECKOUT_CREATE_METHODS = {
    donation: createCheckoutDonation,
    sale: createCheckoutSale,
    saletoken: createCheckoutSellCurrency,
    cart: createCheckoutCart,
    plan: createNewPlan,
  };

  useEffect(() => {
    setSearch(null);
    if (merchantId) {
      getCheckoutListHandler(1);
      getMerchantData(merchantId);
    }
  }, [merchantId]);

  useEffect(() => {
    if (merchantId && merchants && merchants?.length) {
      setActiveMerchantCurrencies(merchants.find(merchant => merchantId === merchant._id).currencies);
    }
  }, [merchantId, merchants]);

  const getCheckoutListHandler = (pageToGet, search = null) => {
    getCheckoutList({
      merchantId: merchantId,
      params: { page: pageToGet, search: search ? search.trim().split(/ +/).join(' ') : null, limit, order: 'DESC' },
    });
  };

  const getPlanListHandler = (pageToGet, search = null) => {
    getPlansList({
      merchantId: merchantId,
      params: {
        page: pageToGet,
        search: search ? search.trim().split(/ +/).join(' ') : null,
        limit,
        order: 'DESC' ,
        typeNetwork: typeNetwork
      },
    });
  };

  useEffect(() => {
    getMerchants();
  }, [getMerchants]);

  const createCheckoutHandler = body => {
    const {
      description,
      organizationName,
      productName,
      cartName,
      name,
      currencies,
      image,
      linkImage,
      logoImage,
      linkLogoImage,
      backgroundColor,
      accentColor,
      fiatCurrency,
      price,
      expireTime,
      min,
      max,
      fixed,
      tradedCurrency,
      tradedWallet,
      linkSite,
      payeeAddress,
      periodType,
      currency,
      owner,
      published,
      sign,
      password,
    } = body;
    const formData = new FormData();
    formData.append('description', description);
    if (type !== 'plan') {
      formData.append('expireTime', expireTime);
    }
    formData.append('collectName', checkoutCustomerInfo.collectName);
    formData.append('collectEmail', checkoutCustomerInfo.collectEmail);

    // TO DO
    if (type !== 'plan') {
      formData.append('currencies', JSON.stringify(currencies));
    }

    if (image) {
      formData.append('image', image, image.name);
    }

    if (linkImage) {
      formData.append('linkImage', linkImage);
    }

    if (logoImage) {
      formData.append('logoImage', logoImage, logoImage.name);
    }

    if (linkLogoImage) {
      formData.append('linkLogoImage', linkLogoImage);
    }

    if (backgroundColor) {
      formData.append('backgroundColor', backgroundColor);
    }

    if (accentColor) {
      formData.append('accentColor', accentColor);
    }
    if (linkSite && type !== 'plan') {
      formData.append('linkSite', linkSite);
    }

    if (checkoutType === 'donation') {
      formData.append('organizationName', organizationName);
    }

    if (checkoutType === 'sale') {
      formData.append('productName', productName);
      formData.append('fiatCurrency', fiatCurrency);
      formData.append('price', price);
    }

    if (checkoutType === 'saletoken') {
      formData.append('productName', productName);
      if (fixed === 'true') {
        formData.append('fiatCurrency', fiatCurrency);
        formData.append('price', price);
      }
      formData.append('min', min);
      formData.append('max', max);
      formData.append('fixed', fixed);
      formData.append('tradedCurrency', tradedCurrency);
      formData.append('tradedWallet', tradedWallet);
    }

    if (checkoutType === 'cart') {
      formData.append('cartName', cartName);
      formData.append('fiatCurrency', fiatCurrency);
    }

    if (type === 'plan') {
      formData.append('payeeAddress', payeeAddress);
      formData.append('periodType', periodType);
      formData.append('currency', currency);
      formData.append('owner', owner);
      formData.append('name', name);
      formData.append('price', price);
      formData.append('published', isDuplicating ? isPublished : published);
      formData.append('sign', sign);
      formData.append('password', password);
    }

    checkoutType &&
      CHECKOUT_CREATE_METHODS[checkoutType]({
        merchantId: merchantId,
        body: formData,
        typeNetwork: typeNetwork,
        page: page
      });
  };
  const editCheckoutHandler = body => {
    const {
      description,
      linkSite,
      organizationName,
      productName,
      cartName,
      currencies,
      image,
      linkImage,
      logoImage,
      linkLogoImage,
      backgroundColor,
      accentColor,
      fiatCurrency,
      price,
      expireTime,
      min,
      max,
      fixed,
      tradedCurrency,
      tradedWallet,
      payeeAddress,
      periodType,
      currency,
      owner,
      name,
      status,
      sign,
      password
    } = body;
    const formData = new FormData();
    formData.append('description', description);
    if (type !== 'plan') {
      formData.append('expireTime', expireTime);
    }
    formData.append('collectName', checkoutCustomerInfo.collectName);
    formData.append('collectEmail', checkoutCustomerInfo.collectEmail);

    // TO DO
    if (type !== 'plan') {
      formData.append('currencies', JSON.stringify(currencies));
    }

    if (linkImage) {
      formData.append('linkImage', linkImage);
    }

    if (linkLogoImage) {
      formData.append('linkLogoImage', linkLogoImage);
    }

    if (backgroundColor) {
      formData.append('backgroundColor', backgroundColor);
    }

    if (accentColor) {
      formData.append('accentColor', accentColor);
    }

    if (linkSite) {
      formData.append('linkSite', linkSite);
    }

    if (editCheckoutState.type?.toLowerCase() === 'donation') {
      formData.append('organizationName', organizationName);
    }

    if (editCheckoutState.type?.toLowerCase() === 'sale') {
      formData.append('productName', productName);
      formData.append('fiatCurrency', fiatCurrency);
      formData.append('price', price);
    }

    if (editCheckoutState.type?.toLowerCase() === 'saletoken') {
      formData.append('productName', productName);
      // TODO: do not send fiatCurrency and price fields if checkout type is float
      if (fixed === 'true') {
        formData.append('fiatCurrency', fiatCurrency);
        formData.append('price', price);
      }
      formData.append('min', min);
      formData.append('max', max);
      formData.append('fixed', fixed);
      formData.append('tradedCurrency', tradedCurrency);
      formData.append('tradedWallet', tradedWallet);
    }

    if (editCheckoutState.type?.toLowerCase() === 'cart') {
      formData.append('cartName', cartName);
      formData.append('fiatCurrency', fiatCurrency);
    }

    if (image === 'delete') {
      formData.append('deleteImage', true);
    } else {
      image && formData.append('image', image, image.name);
    }

    if (logoImage === 'delete') {
      formData.append('deleteLogoImage', true);
    } else {
      logoImage && formData.append('logoImage', logoImage, logoImage.name);
    }

    if (type === 'plan') {
      formData.append('payeeAddress', payeeAddress);
      formData.append('periodType', periodType);
      formData.append('currency', currency);
      formData.append('owner', owner);
      formData.append('name', name);
      formData.append('price', price);
      formData.append('status', Boolean(status));
      formData.append('sign', sign);
      formData.append('password', password);
    }

    if (type !== 'plan') {
      patchCheckout({
        body: formData,
        merchantId: merchantId,
        type: editCheckoutState.type?.charAt(0)?.toLowerCase() + editCheckoutState.type?.slice(1),
        checkoutId: editCheckoutState._id,
      }).then(response => {
        modalCloseHandler();
      });
    } else {
      patchPlan({
        typeNetwork: typeNetwork,
        planId: editCheckoutState._id,
        merchantId: merchantId,
        body: formData,
        page: page
      }).then(response => {
        modalCloseHandler();
      });
    }
  };

  const modalCloseHandler = () => {
    setIsVisible(false);
    setIsRecurring(false);
    if (type !== 'plan') {
      setCheckoutType(null);
    }
    setDetailedCheckout(null);
    setEditCheckout(null);
    setIsDuplicating(null);
    setCheckoutCustomerInfo({
      collectName: false,
      collectEmail: false,
    });
    setStep(0);
    setEditStep(0);
  };

  const nextStep = () => setStep(step + 1);

  const prevStep = () => {
    setStep(step - 1);
  };

  const openDetailedHandler = (checkout, isRecurringState = false) => {
    setIsRecurring(isRecurringState);
    setIsVisible(true);
    setDetailedCheckout(checkout);
  };

  const openEditHandler = (checkout, action = 'edit') => {
    if (action === 'edit') {
      setIsVisible(true);
    }
    setEditCheckout(checkout);
  };

  const openDuplicateHandler = checkout => {
    if (checkout?.published) {
      setIsPublished(checkout?.published);
    }
    setIsDuplicating(true);
    setIsVisible(true);
    setCheckoutType(type !== 'plan' ? checkout?.type?.toLowerCase() : 'plan');
    setEditCheckout(checkout);
  };

  const publishPlanChanges = id => {
    publishPlan(page, typeNetwork, id, merchantId);
    setIsOpenPlanDetails(false);
  };

  useEffect(() => {
    if (type === 'plan' && merchantId) {
      setCheckoutType('plan');

      getPlansList({
        merchantId: merchantId,
        params: {
          page: 1,
          search: search ? search.trim().split(/ +/).join(' ') : null,
          limit,
          order: 'DESC' ,
          typeNetwork: typeNetwork
        },
      });
      getSupportedCurrenciesList();
      getSupportedWalletsList(typeNetwork, merchantId);
    }
  }, [type, merchantId, typeNetwork]);

  const openPlanDetails = () => {
    setIsOpenPlanDetails(true);
  };

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <div className="block-for-component">
        <div className="component">
          <CheckoutsModals
            type={type}
            visible={isVisible}
            close={modalCloseHandler}
            isDuplicating={isDuplicating}
            checkoutCustomerInfo={checkoutCustomerInfo}
            setCheckoutCustomerInfo={setCheckoutCustomerInfo}
            setCheckoutType={setCheckoutType}
            checkoutType={checkoutType}
            createCheckoutHandler={createCheckoutHandler}
            editCheckoutHandler={editCheckoutHandler}
            nextStep={nextStep}
            prevStep={prevStep}
            step={step}
            editStep={editStep}
            setEditStep={setEditStep}
            detailedCheckoutState={detailedCheckoutState}
            editCheckoutState={editCheckoutState}
            ifCreateFetching={ifCreateFetching}
            ifPatchFetching={ifPatchFetching}
            activeMerchantCurrencies={activeMerchantCurrencies}
            merchantId={merchantId}
            merchants={merchants}
            getSupportedCurrenciesList={getSupportedCurrenciesList}
            getSupportedWalletsList={getSupportedWalletsList}
            supportedCurrencies={supportedCurrencies}
            supportedWallets={supportedWallets}
            isRecurring={isRecurring}
          />
          <CheckoutsHeader
            type={type}
            fetching={fetching}
            setIsVisible={setIsVisible}
            data={data}
            search={search}
            setSearch={setSearch}
            getCheckoutListHandler={getCheckoutListHandler}
            getPlanListHandler={getPlanListHandler}
          />
          {type !== 'plan' ? (
            <CheckoutsList
              data={data}
              page={page}
              type={type}
              fetching={fetching}
              getPlansList={getPlansList}
              typeNetwork={typeNetwork}
              setIsVisible={setIsVisible}
              merchantId={merchantId}
              deleteCheckout={deleteCheckout}
              openDetailedHandler={openDetailedHandler}
              openEditHandler={openEditHandler}
              openDuplicateHandler={openDuplicateHandler}
              deletePlan={deletePlan}
              supportedCurrencies={supportedCurrencies}
            />
          ) : (
            <PlansList
              data={data}
              page={page}
              type={type}
              openPlanDetails={openPlanDetails}
              fetching={fetching}
              getPlansList={getPlansList}
              typeNetwork={typeNetwork}
              setIsVisible={setIsVisible}
              merchantId={merchantId}
              deleteCheckout={deleteCheckout}
              openDetailedHandler={openDetailedHandler}
              openEditHandler={openEditHandler}
              openDuplicateHandler={openDuplicateHandler}
              deletePlan={deletePlan}
              supportedCurrencies={supportedCurrencies}
            />
          )}
          <PlanDetails
            flow="publish"
            publishPlanChanges={publishPlanChanges}
            supportedWallets={supportedWallets}
            supportedCurrencies={supportedCurrencies}
            currentValues={editCheckoutState}
            editCheckoutState={editCheckoutState}
            visible={isOpenPlanDetails}
            close={() => setIsOpenPlanDetails(false)}
          />
          {pages > 1 && (
            <CheckoutsPagination
              type={type}
              page={page}
              pages={pages}
              search={search}
              countItem={countItem}
              limit={limit}
              getCheckoutListHandler={getCheckoutListHandler}
              getPlanListHandler={getPlanListHandler}
            />
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  merchantId: state.transactions.merchantId,
  merchantData: state.merchantData.data,
  merchants: state.merchants.data,
  getState: state.checkouts.get,
  plans: state.checkouts.plans,
  limit: state.checkouts.limit,
  detailedCheckoutState: state.checkouts.detailedCheckout,
  editCheckoutState: state.checkouts.editCheckout,
  ifCreateFetching: state.checkouts.create.fetching,
  ifPatchFetching: state.checkouts.patch.fetching,
  supportedCurrencies: state.checkouts.currencies,
  typeNetwork: state.networkFilter,
  supportedWallets: state.checkouts.wallets,
});

const mapDispatchToProps = {
  getCheckoutList,
  createCheckoutDonation,
  createCheckoutSale,
  createCheckoutSellCurrency,
  createCheckoutCart,
  deleteCheckout,
  setDetailedCheckout,
  setEditCheckout,
  patchCheckout,
  getSupportedCurrenciesList,
  getSupportedWalletsList,
  createNewPlan,
  getPlansList,
  deletePlan,
  patchPlan,
  publishPlan,
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutsPage);
