import React, { useState } from 'react';
import { Radio } from 'antd';
import { useTranslation } from 'react-i18next';
import './InstructionCustomURL.scss';

import { Typography } from 'antd';

export const InstructionCustomURL = () => {
  const { Title, Text } = Typography;
  const { t } = useTranslation();

  const [showInstruction, setShowInstruction] = useState('checkouts');

  return (
    <div className="component custom-instruction">
      <Radio.Group
        value={showInstruction}
        className="custom-instruction__switcher"
        onChange={e => setShowInstruction(e.target.value)}
      >
        <Radio value="checkouts">{t('checkouts.checkouts')}</Radio>
        <Radio value="recurring">{t('checkouts.recurring')}</Radio>
      </Radio.Group>
      <Title level={4} style={{ margin: '0' }}>
        Instruction for Your custom checkouts subdomaim setup
      </Title>

      <Text strong={true}>Create a CNAME record on your domain:</Text>

      <ul className="custom-instruction__list">
        <li>1. Add your domain name CPAY settings page</li>
        <li>
          2.1 Log into your <strong>domain provider</strong>
        </li>
        <li>
          2.2 Go to your <strong>DNS records</strong> page
        </li>
        <li>
          2.3 Under <strong>Create a new record,</strong> click <strong>CNAME</strong>
        </li>
        <li>2.4 Enter the required values as follows: </li>
      </ul>

      <div className="custom-instruction__details-box">
        <p>
          <strong>Type:</strong> CNAME
        </p>
        <p>
          <strong>Name:</strong> {showInstruction === 'checkouts' ? `checkouts` : 'recurring-billing'}{' '}
        </p>
        <p>
          <strong>Target:</strong>{' '}
          {showInstruction === 'checkouts' ? `checkouts.cpay.world` : 'recurring-billing.cpay.world'}
        </p>
        <p>
          <strong>TTL:</strong> Auto
        </p>
      </div>

      <ol>
        {' '}
        <li>2.5 Save your changes </li>{' '}
      </ol>

      <div className="custom-instruction__final-box">
        <p>Your custom URL will be available in 10 minutes</p>
      </div>
    </div>
  );
};
