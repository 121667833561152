import React, { useState, useEffect, useRef } from 'react';
import { Popover } from 'antd';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SuccessIcon } from '../../img/default-svg/success.svg';
import { ReactComponent as CopyIconCpay } from '../../img/cpay-svg/copy-blue.svg';
import Button from '../Button';
import network from '../../utils/transactionScans';
import { tokenNodeTypeObj } from '../../utils/tokenNodeTypes';
import styles from './SaleTokenDone.module.scss';
import { getContrastTextColor } from '../../utils/getTextColorByBg';
import { mainColor } from '../../utils/getMainColor';
import { identity } from "../../utils/getIdentity";

const truncate = function (fullStr = '', strLen, separator) {
  if (fullStr.length <= strLen) return fullStr;

  separator = separator || '...';

  var sepLen = separator.length,
    charsToShow = strLen - sepLen,
    frontChars = Math.ceil(charsToShow / 2),
    backChars = Math.floor(charsToShow / 2);

  return fullStr.substring(0, frontChars) + separator + fullStr.substring(fullStr.length - backChars);
};

const SaleTokenDone = ({ getChargeState, buttonRestartHandler }) => {
  const [showCopyPopover, setShowCopyPopover] = useState(false);
  const copyTimer = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      if (copyTimer.current) {
        clearTimeout(copyTimer.current);
        copyTimer.current = null;
      }
    };
  }, []);

  const copyHandler = value => {
    navigator.clipboard.writeText(value);
    setShowCopyPopover(true);
    copyTimer.current = setTimeout(() => setShowCopyPopover(false), 1000);
  };

const linkSolana = getChargeState.data.receive.currency === 'SOL' ? '?cluster=devnet' : ''

  return (
    <>
      <style>{`
        .custom-done-button {
          background: ${getChargeState.data.checkout.accentColor} !important;
          color: ${getContrastTextColor(getChargeState.data.checkout.accentColor || mainColor)} !important;
        }
      `}</style>
      <div className={styles['sale-token-done']}>
        <SuccessIcon className={styles['sale-token-done__icon']} />
        <div className={styles[`sale-token-done__title sale-token-done__title-${identity}`]}>{t('checkouts.success.title')}!</div>
        <div className={styles['sale-token-done__subtitle']}>{t('checkouts.success.subtitle')}</div>
        <div className={styles['sale-token-done__summary']}>
          <div className={styles['sale-token-done__summary-item']}>
            <div className={styles['sale-token-done__summary-item-title']}>{t('checkouts.summary.youGet')}:</div>
            <div className={styles['sale-token-done__summary-item-value']}>
              {getChargeState.data.amountTo} {getChargeState.data.receive.currency}{' '}
              {getChargeState.data.receive.currencyType === 'token'
                ? tokenNodeTypeObj[getChargeState.data.receive.nodeType]
                : ''}
                  {getChargeState.data.receive.currencyType === 'token' && getChargeState.data.receive.nodeType === 'optimism' ?
              getChargeState.data.receive.currency === 'OP' ? null : 'OP' : null }

              {getChargeState.data.receive.currency === 'ETH' && getChargeState.data.receive.currencyType === 'currency' && getChargeState.data.receive.nodeType === 'optimism' ?
             'OP' : null}

              {getChargeState.data.receive.currencyType === 'currency' && getChargeState.data.receive.nodeType === 'arbitrum' ? getChargeState.data.receive.currency === 'arbitrum' ? null : 'ARB' : null}

              {(getChargeState.data.receive.currency === 'USDT' || getChargeState.data.receive.currency === 'USDC' || getChargeState.data.receive.currency === 'ETH') && getChargeState.data.receive.currencyType === 'token' && getChargeState.data.receive.nodeType === 'arbitrum' ? 'ARB' : null}

              {(getChargeState.data.receive.currency === 'USDT' || getChargeState.data.receive.currency === 'USDC') && getChargeState.data.receive.currencyType === 'token' && getChargeState.data.receive.nodeType === 'matic' ? 'MATIC' : null}

              {(getChargeState.data.receive.currency === 'USDT' && getChargeState.data.receive.currencyType === 'token' && getChargeState.data.receive.nodeType === 'avax') ? 'AVAX' : null}

              {(getChargeState.data.receive.currency === 'USDC' && getChargeState.data.receive.currencyType === 'token' && getChargeState.data.receive.nodeType === 'solana') ? 'SOL' : null}

              {(getChargeState.data.receive.currency === 'USDT'  && getChargeState.data.receive.currencyType === 'token' && getChargeState.data.receive.nodeType === 'ftm') ? 'FTM' : null}

              {((getChargeState.data.receive.currency === 'USDT' || getChargeState.data.receive.currency === 'USDC') && getChargeState.data.receive.currencyType === 'token' && getChargeState.data.receive.nodeType === 'moonbeam') ? 'GLMR' : null}
            </div>
          </div>
          <div className={styles['sale-token-done__summary-item']}>
            <div className={styles['sale-token-done__summary-item-title']}>{t('checkouts.summary.recepient')}:</div>
            <div className={styles['sale-token-done__summary-item-value']}>{getChargeState.data.recipient}</div>
          </div>
          <div className={styles['sale-token-done__summary-item']}>
            <div className={styles['sale-token-done__summary-item-title']}>Hash:</div>
            <div className={styles['sale-token-done__summary-item-value']}>
              <a
                // eslint-disable-next-line react/jsx-no-target-blank
                target="_blank"
                href={`${network[getChargeState?.data?.typeNetwork][getChargeState.data.receive.nodeType]}${
                  getChargeState.data.receive.hash
                }${linkSolana}`}
                className={styles['sale-token-done__summary-item-scan-link']}
              >
                {truncate(getChargeState.data.receive.hash, 15)}{' '}
              </a>
              <Popover title="" content={t('copied')} trigger="click" visible={showCopyPopover}>
                <CopyIconCpay onClick={() => copyHandler(getChargeState.data.receive.hash)} />
              </Popover>
            </div>
          </div>
        </div>
        <Button className={`${styles['sale-token-done__button']} custom-done-button`} onClick={buttonRestartHandler}>
          {t('checkouts.summary.button')}
        </Button>
      </div>
    </>
  );
};

export default SaleTokenDone;
