import React, { useState, useEffect } from 'react';
import { Input, Modal, Form } from 'antd';
import '../../layout/Modal.scss';
import Button from "../Button";
import '../SignPasswordModal/style.scss'
import { resetAutosignStep, resetDownloadAutosignStep } from "../../redux/actions/withdraw";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Timer } from "../Timer/Timer";
import { identity } from "../../utils/getIdentity";
import { addMinutes } from "../../utils/addMinutes";

function PasswordModal({ isOpen, closeModal, downloadAutosignStep, merchantId, walletId, downloadAutosign }) {
  const [password, setPassword] = useState('');
  const [step, setStep] = useState(0)
  const [emailTwoFa, setEmailTwoFa] = useState('');
  const [disableResend, setDisableResend] = useState(true);
  const [twoFactorToken, setTwoFactorToken] = useState('');
  const [twoVerifyCode, setTwoVerifyCode] = useState('');

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const validUntil = addMinutes(1);

  useEffect(() => {
    if (downloadAutosignStep !== 0) {
      setStep(downloadAutosignStep + 1);
    } else if (step === 5 && downloadAutosignStep === 0) {
      closeModals();
      closeModal();
    }
  }, [downloadAutosignStep])

  useEffect(() => {
    if (isOpen) {
      setPassword('');
    }
  }, [isOpen]);

  const closeModals = () => {
    closeModal();
    setTwoVerifyCode('');
    setEmailTwoFa('');
    setTwoFactorToken('');
    form.resetFields();
    setPassword('');
    dispatch(resetAutosignStep());
    dispatch(resetDownloadAutosignStep());
  };

  const addNewPassword = async () => {
    try {
      await downloadAutosign(merchantId, walletId, {
        twoFactorToken: twoFactorToken || undefined,
        emailOtp: emailTwoFa || undefined,
        password: password || undefined,
        verifyCode: twoVerifyCode || undefined,
      });
    } catch (error) {
      console.error(error);
    }
  }

  const handleDisableResend = () => {
    const timeout = setTimeout(() => {
      setDisableResend(false);
    }, 60000);

    return () => clearTimeout(timeout);
  };

  const resendVerifyCode = async () => {
    try {
      await addNewPassword(true);
      setDisableResend(true);
      handleDisableResend();
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <Modal
      title={downloadAutosignStep === 0 ? "Password" : null}
      visible={isOpen}
      onCancel={closeModals}
      width={469}
      footer={null}
      className={`modal modal-${identity}`}
      style={{ padding: '30px' }}
    >
      <Form
        form={form}
        className="form"
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            addNewPassword();
          }
        }}>
        <div className={`${downloadAutosignStep !== 0 ? 'password-modal__top' : 'password-modal'}`}>
          {downloadAutosignStep === 0 && (
            <Input.Password
              className="form__input sign-password__input"
              placeholder="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
          )}

          {downloadAutosignStep === 1 && (
            <div className="sign-password">
              <Form.Item
                label={t('auth.сodeFromSms')}
                name="verifyCode"
                className="modal-form__label"
                rules={[{ required: true, message: t('validation.phoneTwoFaRequired') }]}
              >
                <Input
                  placeholder={t('auth.сodeFromSms')}
                  className="modal-form__input"
                  maxLength="6"
                  value={twoVerifyCode}
                  onChange={e => setTwoVerifyCode(e.target.value)}
                />
              </Form.Item>
            </div>
          )}

          {downloadAutosignStep === 2 && (
            <div className="sign-password">
              <Form.Item
                label={t('auth.keyFromEmail')}
                name="emailTwoFaCode"
                className="modal-form__label"
                rules={[{ required: true, message: t('validation.emailTwoFaRequired') }]}
              >
                <Input
                  placeholder={t('auth.keyFromEmail')}
                  className="modal-form__input"
                  maxLength="6"
                  value={emailTwoFa}
                  onChange={e => setEmailTwoFa(e.target.value)}
                />
              </Form.Item>
              <div className="resend-email-twoFa">
                <span>{t('doNotReveiveCode')}</span>
                {disableResend ? (
                  <span className="resend-email-twoFa__timer">
                    {' '}
                    Receive in <Timer validUntil={validUntil} onExpire={() => setDisableResend(false)} />
                  </span>
                ) : (
                  <span
                    className={`resend-email-twoFa__button resend-email-twoFa__button-${identity}`}
                    onClick={resendVerifyCode}
                  >
                    {' '}
                    {t('resend')}
                  </span>
                )}
              </div>
            </div>
          )}

          {downloadAutosignStep === 3 && (
            <div className="sign-password">
              <Form.Item
                label={t('auth.keyFromGA')}
                name="twoFactorToken"
                className="modal-form__label"
                rules={[{ required: true, message: t('validation.twoFaRequired') }]}
              >
                <Input
                  className="modal-form__input"
                  placeholder={t('auth.keyFromGA')}
                  maxLength="6"
                  value={twoFactorToken}
                  onChange={e => setTwoFactorToken(e.target.value)}
                />
              </Form.Item>
            </div>
          )}

          <div className="sign-password__buttons">
            <Button type="secondary" className="form__button sign-password__btn" onClick={closeModals}>
              Cancel
            </Button>
            <Button
              onClick={addNewPassword}
              type="primary"
              className="form__button sign-password__btn"
              disabled={
              (downloadAutosignStep === 0 && password === '')
                || (downloadAutosignStep === 1 && twoVerifyCode?.length < 6)
                || (downloadAutosignStep === 2 && emailTwoFa?.length < 6)
                || (downloadAutosignStep === 3 && twoFactorToken?.length < 6)
              }
            >
              {downloadAutosignStep === 0 ? 'Add' : t('signPassword.verify')}
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  )
}

export default PasswordModal