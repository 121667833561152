import React from 'react';
import { identity } from "../../../utils/getIdentity";
import './style.scss';

const SettingsToggler = ({ roleMode, setRoleMode, firstMode, secondMode }) => {
  return (
    <div className="role-mode-toggler-wrapper">
      <div className="role-mode-toggler">
        <span
          className={`role-mode-toggler__item ${!roleMode && `active active-${identity}`}`}
          onClick={() => setRoleMode(false)}
        >
          {firstMode}
        </span>
        <span
          className={`role-mode-toggler__item ${roleMode && `active active-${identity}`}`}
          onClick={() => setRoleMode(true)}
        >
          {secondMode}
        </span>
      </div>
    </div>
  )
};

export default SettingsToggler;