import React from 'react';
import { useTranslation } from 'react-i18next';

import './LoginInfo.scss';
import { AppConfig } from '../../config';
import { identity } from '../../utils/getIdentity';


export default function LoginInfo({ accountType }) {
  const { t } = useTranslation();

  return (
    <div className="login-info">
      <h1 className={`login-info__title login-info__title-${identity}`}>{accountType ? t('auth.welcomeTitle', { title: AppConfig.title }) : AppConfig.identity !== 'finvaro' ? t('auth.welcomeText') : t('auth.welcomeFinvaro')}</h1>
      {accountType && (
        <p className={`login-info__text login-info__text-${identity}`}>
           <>
              <span className={`login-info__thesis login-info__thesis-${identity}`}>{t('auth.welcomeTextBusiness.thesis1')}</span>
              <span className={`login-info__thesis login-info__thesis-${identity}`}>{t('auth.welcomeTextBusiness.thesis2')}</span>
              <span className={`login-info__thesis login-info__thesis-${identity}`}>{t('auth.welcomeTextBusiness.thesis3')}</span>
              <span className={`login-info__thesis login-info__thesis-${identity}`}>{t('auth.welcomeTextBusiness.thesis4')}</span>
              <span className={`login-info__thesis login-info__thesis-${identity}`}>{t('auth.welcomeTextBusiness.thesis5')}</span>
            </>
        </p>
      )}
    </div>
  );
}
