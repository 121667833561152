import React from 'react';
import { AppConfig } from "../config";
import { Link } from "react-router-dom";
import backCpayIcon from "../img/cpay-svg/arrow-back.svg";
import backNFGIcon from "../img/nfgpay-svg/arrow-back-nfg.svg";
import backFinvaroIcon from "../img/finvaro/arrow-back-finvaro.svg";
import backClarniumIcon from "../img/clarnium/arrow-back-clarnium.svg";
import { useTranslation } from "react-i18next";
import SubscriptionsList from "../components/Checkouts/Subscriptions/SubscriptionsList";
import {
  getSubscriptions
} from "../redux/actions/checkouts";
import { connect } from "react-redux";

const backIcon = {
  cpay: backCpayIcon,
  nfg: backNFGIcon,
  finvaro: backFinvaroIcon,
  clarnium: backClarniumIcon
}

const SubscriptionsPage = ({ merchantId, typeNetwork, getSubscriptions, subscriptions }) => {
  const { t } = useTranslation();

  return (
    <div className="block-for-component">
      <div className="component">
        <Link to="/recurring-billing" className={`admin-page-header__back admin-page-header__back-${AppConfig.identity}`}>
          <img src={backIcon[AppConfig.identity]} alt="Back" />
          <span>{t('goBack')}</span>
        </Link>
        <SubscriptionsList subscriptions={subscriptions} getSubscriptions={getSubscriptions} merchantId={merchantId} typeNetwork={typeNetwork} />
      </div>
    </div>
  )
};

const mapStateToProps = state => ({
  merchantId: state.transactions.merchantId,
  typeNetwork: state.networkFilter,
  subscriptions: state.checkouts.subscriptions
});

const mapDispatchToProps = {
  getSubscriptions
};

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionsPage);