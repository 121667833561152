import React from 'react';
import './style.scss';
import { identity } from '../../../utils/getIdentity';

const TextTag = ({ className, children }) => <span className={`text ${className}`}>{children}</span>;

const TEXT = {
  pageTitle({ color, ...rest }) {
    return <TextTag className="text__page-title" {...rest} />;
  },
  sectionTitle({ color, ...rest }) {
    return <TextTag className={`text__section-title text__section-title-${identity}`} {...rest} />;
  },
  sectionDescription({ color, ...rest }) {
    return <TextTag className={`text__section-description text__section-description-${identity}`} {...rest} />;
  },
  button({ color, ...rest }) {
    return <TextTag className="text__button" {...rest} />;
  },
  gray({ color, ...rest }) {
    return <TextTag className="text__gray" {...rest} />;
  },
  grayBold({ color, ...rest }) {
    return <TextTag className="text__gray-bold" {...rest} />;
  },
  grayBoldSmall({ color, ...rest }) {
    return <TextTag className="text__gray-bold-small" {...rest} />;
  },
  darkBold({ color, ...rest }) {
    return <TextTag className="text__dark-bold" {...rest} />;
  },
  label({ color, ...rest }) {
    return <TextTag className={`text__label text__label-${identity}`} {...rest} />;
  },
  darkBig({ color, ...rest }) {
    return <TextTag className="text__dark-big" {...rest} />;
  },
};

export default TEXT;
