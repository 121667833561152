import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useHistory} from "react-router-dom";
import { Menu, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DashboardSVG } from '../../img/adminPanel/dashboard.svg';
import { ReactComponent as UsersSVG } from '../../img/adminPanel/users.svg';
import { ReactComponent as SettingsSVG } from '../../img/adminPanel/settings.svg';
import { ReactComponent as WalletsSVG } from '../../img/adminPanel/wallets.svg';
import { ReactComponent as WithdrawSVG } from '../../img/adminPanel/withdraw.svg';
import { ReactComponent as TransactionsSVG } from '../../img/adminPanel/transactions.svg';
import { ReactComponent as CurrencySVG } from '../../img/adminPanel/health.svg';
import './Sider.scss';
import { AppConfig } from '../../config';
import { identity } from '../../utils/getIdentity';
import { getRolePermissions } from "../../redux/actions/adminPanel/adminSettings";
import { connect } from "react-redux";

function Sider({ siderState, userData, getRolePermissions, permissions }) {
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    if (permissions?.length > 0) {
      if (permissions[0]?.view) {
        return
      }
      if (permissions[1]?.view) {
        history.push('/admin/users')
        return
      }
      if (permissions[2]?.view) {
        history.push('/admin/settings')
        return
      }
      if (permissions[3]?.view) {
        history.push('/admin/wallets')
        return
      }
      if (permissions[4]?.view) {
        history.push('/admin/withdraw')
        return
      }
      if (permissions[5]?.view) {
        history.push('/admin/transactions')
        return
      }
      if (permissions[5]?.view) {
        history.push('/admin/price-chart')
        return
      }
    }
  }, [permissions])

  useEffect(() => {
    if (userData?.accessRole?.id) {
      getRolePermissions('role', userData?.accessRole?.id);
    }
  }, [userData]);

  return (
    <Menu mode="vertical" className={`sider sider__${siderState} sider-${identity}`}>
      {permissions && permissions?.length > 0 && permissions[0]?.view && (
        <Menu.Item className="sider__nav-item" key="0">
          <Tooltip title={t('dashboard')} placement="rightTop" overlayClassName={`sider__nav-tooltip_${siderState}`}>
            <NavLink
              exact
              className={`sider__nav-link sider__nav-link-${AppConfig.identity}`}
              activeClassName="sider__nav-link_active"
              to="/admin"
            >
              <DashboardSVG />
              <span className="sider__nav-title">Dashboard</span>
            </NavLink>
          </Tooltip>
        </Menu.Item>
      )}
      {permissions && permissions?.length > 0 && permissions[1]?.view && (
        <Menu.Item className="sider__nav-item" key="1">
          <Tooltip title={t('users')} placement="rightTop" overlayClassName={`sider__nav-tooltip_${siderState}`}>
            <NavLink
              exact
              className={`sider__nav-link sider__nav-link-${AppConfig.identity}`}
              activeClassName="sider__nav-link_active"
              to="/admin/users"
            >
              <UsersSVG />
              <span className="sider__nav-title">Users</span>
            </NavLink>
          </Tooltip>
        </Menu.Item>
      )}
      {permissions && permissions?.length > 0 && permissions[2]?.view && (
        <Menu.Item className="sider__nav-item" key="2">
          <Tooltip title={t('settings')} placement="rightTop" overlayClassName={`sider__nav-tooltip_${siderState}`}>
            <NavLink
              exact
              className={`sider__nav-link sider__nav-link-${AppConfig.identity}`}
              activeClassName="sider__nav-link_active"
              to="/admin/settings"
            >
              <SettingsSVG />
              <span className="sider__nav-title">Settings</span>
            </NavLink>
          </Tooltip>
        </Menu.Item>
      )}
      {permissions && permissions?.length > 0 && permissions[3]?.view && (
        <Menu.Item className="sider__nav-item" key="3">
          <Tooltip title={t('wallets')} placement="rightTop" overlayClassName={`sider__nav-tooltip_${siderState}`}>
            <NavLink
              exact
              className={`sider__nav-link sider__nav-link-${AppConfig.identity}`}
              activeClassName="sider__nav-link_active"
              to="/admin/wallets"
            >
              <WalletsSVG />
              <span className="sider__nav-title">Wallets</span>
            </NavLink>
          </Tooltip>
        </Menu.Item>
      )}
      {permissions && permissions?.length > 0 && permissions[4]?.view && (
        <Menu.Item className="sider__nav-item" key="4">
          <Tooltip title={t('withdraw')} placement="rightTop" overlayClassName={`sider__nav-tooltip_${siderState}`}>
            <NavLink
              exact
              className={`sider__nav-link sider__nav-link-${AppConfig.identity}`}
              activeClassName="sider__nav-link_active"
              to="/admin/withdraw"
            >
              <WithdrawSVG />
              <span className="sider__nav-title">Withdraw</span>
            </NavLink>
          </Tooltip>
        </Menu.Item>
      )}
      {permissions && permissions?.length > 0 && permissions[5]?.view && (
        <Menu.Item className="sider__nav-item" key="5">
          <Tooltip title={t('transactions')} placement="rightTop" overlayClassName={`sider__nav-tooltip_${siderState}`}>
            <NavLink
              exact
              className={`sider__nav-link sider__nav-link-${AppConfig.identity}`}
              activeClassName="sider__nav-link_active"
              to="/admin/transactions"
            >
              <TransactionsSVG />
              <span className="sider__nav-title">Transactions</span>
            </NavLink>
          </Tooltip>
        </Menu.Item>
      )}
      {permissions && permissions?.length > 0 && permissions[6]?.view && (
        <Menu.Item className="sider__nav-item" key="6">
          <Tooltip title={t('currency')} placement="rightTop" overlayClassName={`sider__nav-tooltip_${siderState}`}>
            <NavLink
              exact
              className={`sider__nav-link sider__nav-link-${AppConfig.identity}`}
              activeClassName="sider__nav-link_active"
              to="/admin/price-chart"
            >
              <CurrencySVG />
              <span className="sider__nav-title">Price chart</span>
            </NavLink>
          </Tooltip>
        </Menu.Item>
      )}
    </Menu>
  );
}

const mapStateToProps = state => ({
  userData: state.user.data,
  permissions: state.adminSettings.permissions,
});

const mapDispatchToProps = {
  getRolePermissions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Sider);
