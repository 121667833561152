import { identity } from "../utils/getIdentity";

export default (endpoint, { body, isFormData, isFormText, ...customConfig } = {}) => {
  const token = localStorage.getItem('authToken');
  const headers = {};
  let data;
  const typesOfEndpointsForBlobs =
    endpoint.includes('marketing') || endpoint.includes('list/export') || endpoint.includes('admin/export');

  if (!isFormData) {
    headers['Content-Type'] = !typesOfEndpointsForBlobs ? 'application/json' : 'blob';
  }

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  const config = {
    method: body ? 'POST' : 'GET',
    ...customConfig,
    responseType: typesOfEndpointsForBlobs ? 'blob' : isFormText ? 'text' : undefined,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };

  if (body) {
    config.body = isFormData ? body : JSON.stringify(body);
  }

  const response = fetch(`${process.env.REACT_APP_API_URL}/api/${endpoint}`, config).then(async response => {
    const contentType = response.headers.get('content-type');

    if (response.status === 401 && endpoint !== 'user/login' && endpoint !== 'user/login-by-phone') {
      localStorage.removeItem('authToken');
      localStorage.removeItem('merchantId');
      localStorage.removeItem('cpayNetworkFilter');
      window.location.replace(identity === 'clarnium' ? '/pay/login' : '/login');
    }

    if (response.ok) {
      if (endpoint.includes('user/export')) {
        data = await response.text();
      } else if (typesOfEndpointsForBlobs) {
        data = response;
      } else {
        if (contentType?.includes('application/json')) {
          data = await response.json();
        } else if (contentType?.includes('text/plain')) {
          data = await response.text();
        } else if (typesOfEndpointsForBlobs) {
          data = await response.blob();
        } else {
          data = { ...(await response.json()), code: response.status };
        }
      }
      return data;
    } else {
      // Handle error responses
      if (contentType?.includes('application/json')) {
        data = await response.json();
      } else if (contentType?.includes('text/plain')) {
        data = await response.text();
      } else if (typesOfEndpointsForBlobs) {
        data = await response.blob();
      } else {
        data = { message: 'An unknown error occurred', code: response.status };
      }
      return Promise.reject(data);
    }
  });

  return response;
};
