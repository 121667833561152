import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form, Input } from 'antd';
import Button from '../Button';
import { useTranslation } from 'react-i18next';
import { sendPassword } from '../../redux/actions/user';
import emailIcon from '../../img/default-svg/email.svg';
import ReCAPTCHA from 'react-google-recaptcha';
import useWindowSize from '../../utils/resizeHook';
import { identity } from '../../utils/getIdentity';

const SendPasswordForm = ({ sendPassword }) => {
  const [email, setEmail] = useState('');
  const [fetching, setFetching] = useState(false);
  const recaptchaRef = useRef();
  const { t } = useTranslation();
  const { isSm } = useWindowSize();

  const handleFormSubmit = async () => {
    const recaptcha = await recaptchaRef.current.executeAsync();

    const formData = {
      email: email,
    };

    try {
      setFetching(true);
      await sendPassword(formData, recaptcha);
      setFetching(false);
      recaptchaRef.current.reset();
    } catch (err) {
      setFetching(false);
      recaptchaRef.current.reset();
    }
  };

  return (
    <Form name="basic" initialValues={{ remember: true }} className={`login-form login-form-${identity}`}>
      {isSm && <p className={`login-form__title login-form__title-${identity}`}>{t('auth.resetPassword')}</p>}
      <p className={`login-form__label login-form__label-${identity}`}>{t('email')}</p>
      <Form.Item name="email" rules={[{ required: true, message: t('validation.required') }]}>
        <Input
          suffix={<img src={emailIcon} alt="email icon" />}
          placeholder={t('email')}
          className="login-form__input"
          onChange={e => setEmail(e.target.value)}
        />
      </Form.Item>
      <Button
        type="primary"
        className={`login-form__button login-form__button-${identity}`}
        onClick={handleFormSubmit}
        loading={fetching}
      >
        {t('auth.sendPassword')}
      </Button>
      <Link className={`login-form__reset-link login-form__reset-link-${identity}`} to="/password-reset">
        {t('auth.resetPassword')}
      </Link>
      <div className={`login-form__text login-form__text_space-between login-form__text_space-between-${identity}`}>
        <Link to="/login">{t('logIn')}</Link>
        {identity !== 'finvaro' && <Link to="/sign-up">{t('signUp')}</Link>}
      </div>
      <ReCAPTCHA ref={recaptchaRef} sitekey={process.env.REACT_APP_CAPTCHA_KEY} size="invisible" />
    </Form>
  );
};

const mapDispatchToProps = dispatch => ({
  sendPassword: sendPassword(dispatch),
});

export default connect(null, mapDispatchToProps)(SendPasswordForm);
