import React, { useEffect } from 'react';
import TransactionItem from './TransactionItem';
import { getNftTransactions } from '../../../redux/actions/nft';
import { connect } from 'react-redux';
import { Form, Input, Dropdown, Radio, Empty, Spin, Modal } from 'antd';
import { DownOutlined, LoadingOutlined } from '@ant-design/icons';
import resetCpayIcon from '../../../img/cpay-svg/reset.svg';
import resetNfgIcon from '../../../img/nfgpay-svg/reset-nfg.svg'
import resetFinvaroIcon from '../../../img/finvaro/reset-finvaro.svg';
import resetClarniumIcon from '../../../img/clarnium/reset-clarnium.svg';
import { useTranslation } from 'react-i18next';
import styles from './style.module.scss';
import { identity } from '../../../utils/getIdentity';

const resetIcon = {
  cpay: resetCpayIcon,
  nfg: resetNfgIcon,
  finvaro: resetFinvaroIcon,
  clarnium: resetClarniumIcon
}

const TransactionsWrapper = ({
  merchantId,
  typeNetwork,
  nftTransactions,
  getNftTransactions,
  nftTransactionsFetching,
  showTransactionsModal,
  setShowTransactionsModal,
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  useEffect(() => {
    if (merchantId && showTransactionsModal) {
      getNftTransactions(merchantId, {
        typeNetwork,
        limit: 10000,
        order: 'DESC',
        onlyNft: true,
      });
    }
  }, [merchantId, typeNetwork, showTransactionsModal]);

  return (
    <Modal
      title={t('nft.transactionHistory')}
      visible={showTransactionsModal}
      onCancel={() => setShowTransactionsModal(false)}
      afterClose={() => {
        form.resetFields();
      }}
      className={styles[`nft-transactions-modal nft-transactions-modal-${identity}`]}
      footer={null}
    >
      <div className={styles['wrapper']}>
        <Form
          name="nftTransactionsFilter"
          form={form}
          initialValues={{
            search: '',
            order: 'DESC',
            type: 'replenishment,withdrawal,Error',
            nftType: undefined,
          }}
          onFinish={values => {
            getNftTransactions(merchantId, {
              typeNetwork,
              limit: 10000,
              onlyNft: true,
              search: values.search,
              order: values.order,
              type: values.type,
              nftType: values.nftType,
            });
          }}
          autoComplete="off"
        >
          <div className={styles['filters-wrapper']}>
            <Form.Item name="search" noStyle={true}>
              <Input.Search
                // disabled={fetching}
                // name="search"
                placeholder={t('searchBy')}
                onSearch={() => form.submit()}
                className="transaction-filters__search-field"
                addonAfter={
                  // fetching ? (
                  // <Spin
                  // className="transaction-filters__reset-button"
                  // indicator={<LoadingOutlined style={{ fontSize: 14 }} spin />}
                  // />
                  // ) : (
                  <span
                    onClick={() => {
                      form.resetFields();
                      form.submit();
                    }}
                    className="transaction-filters__reset-button"
                  >
                    <img src={resetIcon[identity]} alt="Reset" />
                  </span>
                  // )
                }
              />
            </Form.Item>

            <Dropdown
              overlay={
                <div className="transaction-filters__currencies-dropdown">
                  <Form.Item name="order" noStyle={true}>
                    <Radio.Group onChange={() => form.submit()}>
                      <Radio value="DESC">{t('first')}</Radio>
                      <Radio value="ASC">{t('last')}</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <div className="dropdown-divider"></div>
                  <Form.Item name="type" noStyle={true}>
                    <Radio.Group onChange={() => form.submit()}>
                      <Radio value="replenishment,withdrawal,Error">{t('all')}</Radio>
                      <Radio value="replenishment">{t('transactionFilter.dropdown.replenishment')}</Radio>
                      <Radio value="withdrawal">{t('withdraw.withdrawal')}</Radio>
                      <Radio value="Error">{t('transactionFilter.dropdown.error')}</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <div className="dropdown-divider"></div>
                  <Form.Item name="nftType" noStyle={true}>
                    <Radio.Group onChange={() => form.submit()}>
                      <Radio value={undefined}>{t('all')}</Radio>
                      <Radio value="erc721">ERC-721</Radio>
                      <Radio value="erc1155">ERC-1155</Radio>
                    </Radio.Group>
                  </Form.Item>
                </div>
              }
            >
              <div className="transaction-filters__currencies-button">
                {t('sorting')}
                <DownOutlined />
              </div>
            </Dropdown>
          </div>
        </Form>

        <div className={styles['legend']}>
          <div className={styles['legend__item']}>{t('type')}</div>
          <div className={styles['legend__item']}>{t('data')}</div>
          <div className={styles['legend__item']}>{t('status')}</div>
        </div>

        {!nftTransactionsFetching && nftTransactions.length ? (
          <div className={styles['transactions-list']}>
            {nftTransactions.map(transaction => (
              <TransactionItem key={transaction._id} transaction={transaction} typeNetwork={typeNetwork} />
            ))}
          </div>
        ) : (
          <Empty
            className={styles['transactions-list__empty']}
            image={
              nftTransactionsFetching ? (
                <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
              ) : undefined
            }
            description={nftTransactionsFetching ? '' : undefined}
          />
        )}
      </div>
    </Modal>
  );
};

const mapStateToProps = state => ({
  merchantId: state.transactions.merchantId,
  typeNetwork: state.networkFilter,
  nftTransactions: state.nft.transactions.data,
  nftTransactionsFetching: state.nft.transactions.fetching,
});

const mapDispatchToProps = dispatch => ({
  getNftTransactions: getNftTransactions(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TransactionsWrapper);
