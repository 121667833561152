import React, { useEffect, useRef, useState } from "react";
import backCpayIcon from "../img/cpay-svg/arrow-back.svg";
import backNFGIcon from "../img/nfgpay-svg/arrow-back-nfg.svg";
import backFinvaroIcon from "../img/finvaro/arrow-back-finvaro.svg";
import backClarniumIcon from "../img/clarnium/arrow-back-clarnium.svg";
import { Link, useParams } from "react-router-dom";
import { identity } from "../utils/getIdentity";
import { Card } from "antd";
import { scrollContainerIntoView } from "../adminPanel/helpers/scrollIntoView";
import TEXT from "../adminPanel/components/Text";
import { getUserLoginHistory } from "../redux/actions/adminPanel/adminSettings";
import { connect } from "react-redux";
import LoginHistoryList from "../components/LoginHistoryList/LoginHistoryList";

const backIcon = {
  cpay: backCpayIcon,
  nfg: backNFGIcon,
  finvaro: backFinvaroIcon,
  clarnium: backClarniumIcon
};
const LoginHistoryPage = ({ getUserLoginHistory, loginHistory }) => {
  const { userId } = useParams();
  const adminUserLoginHistoryRef = useRef();

  useEffect(() => {
    if (userId) {
      getUserLoginHistory(userId, { page: 1 });
    }
  }, [userId]);

  useEffect(() => {
    scrollContainerIntoView(adminUserLoginHistoryRef);
  }, []);

  return (
    <div className="admin-user-transactions" ref={adminUserLoginHistoryRef}>
      <Card>
        <Link
          className={`admin-user-transactions__arrow-back admin-user-transactions__arrow-back-${identity}`}
          to="/admin/users"
        >
          <img src={backIcon[identity]} alt="arrow back" /> <span>Go Back</span>
        </Link>
        <TEXT.pageTitle>Login history</TEXT.pageTitle>
        {loginHistory?.data?.length > 0 ? (
          <LoginHistoryList loginHistory={loginHistory} limit={5} getUserLoginHistory={getUserLoginHistory} userId={userId}/>
        ) : (
          <div className="admin-user-transactions-empty">This user don`t have login history</div>
        )}
      </Card>
    </div>
  )
};

const mapStateToProps = state => ({
  loginHistory: state.adminSettings.loginHistory
});

const mapDispatchToProps = {
  getUserLoginHistory
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginHistoryPage);