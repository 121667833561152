import React, { useState } from 'react';
import cn from 'classnames';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import TokenIcon from '../../TokenIcon';
import network from '../../../utils/transactionScans';
import formatCurrency from '../../../utils/currencyFormatter';
import CopyWrapper from '../../CopyWrapper';
import { ReactComponent as CopyIconCpay } from '../../../img/cpay-svg/copy-blue.svg';
import { ReactComponent as CopyIconNfg } from '../../../img/nfgpay-svg/copy-blue-nfg.svg';
import { ReactComponent as CopyIconFinvaro } from '../../../img/finvaro/copy-blue-finvaro.svg';
import { ReactComponent as CopyIconClarnium } from '../../../img/clarnium/copy-blue-clarnium.svg';

import styles from './style.module.scss';
import { getHashFromLink } from '../../../utils/getHashFromLink';
import { identity } from '../../../utils/getIdentity';

const copyIcon = {
  cpay: <CopyIconCpay/>,
  nfg: <CopyIconNfg/>,
  finvaro: <CopyIconFinvaro />,
  clarnium: <CopyIconClarnium />
}

const TransactionItem = ({ transaction, typeNetwork }) => {
  const [isOpened, setIsOpened] = useState(false);
  const { t } = useTranslation();


  return (
    <div className={styles['transaction']}>
      <div className={styles['transaction__main']} onClick={() => setIsOpened(prevState => !prevState)}>
        <div className={`${styles['transaction__info']} ${styles['transaction__name']}`}>
          <TokenIcon tokenName={transaction.info.currency} />
          {transaction.type}
        </div>
        <div className={`${styles['transaction__info']} ${styles['transaction__date']}`}>
          {t(moment(transaction.createdAt).format('MMM').toLowerCase())}{' '}
          {moment(transaction.createdAt).format('D, YYYY, HH:mm')}
        </div>
        <div
          className={`${styles['transaction__info']} ${styles['transaction__status']} ${
            styles[`transaction__status_${transaction.systemStatus.toLowerCase()} transaction__status_${transaction.systemStatus.toLowerCase()}-${identity}`]
          }`}
        >
          {transaction.systemStatus}
        </div>
      </div>

      <div
        className={cn(styles['transaction-details'], {
          [styles['transaction-details_opened']]: isOpened,
        })}
      >
        <div className={styles['transaction-details__inner']}>
          <div className={styles['transaction-details__item']}>
            <b>{t('paymentId')}:</b> {transaction._id}
          </div>
         
          <div className={styles['transaction-details__item']}>
            <b>{t('to')}</b>: {transaction.info.to}
            <CopyWrapper copyContent={transaction.info.to}>
              {copyIcon[identity]}
            </CopyWrapper>
          </div>
          <div className={styles['transaction-details__item']}>
            <b>{t('from')}</b>: {transaction.info.from}
            <CopyWrapper copyContent={transaction.info.from}>
              {copyIcon[identity]}
            </CopyWrapper>
          </div>
          <div className={styles['transaction-details__item']}>
            <b>{t('amount')}</b>: {transaction.info.amount.value}
          </div>
          <div className={styles['transaction-details__item']}>
            <b>{t('type')}</b>: {transaction.info.nft.type}
          </div>
          <div className={styles['transaction-details__item']}>
            <b>{t('minerFee')}:</b> {transaction.info.minerFee.value} {transaction.info.minerFee.currency} ⸺{' '}
            {transaction.info.minerFee.usd ? formatCurrency(transaction.info.minerFee.usd) : 'USD'}
          </div>
          {transaction.info.systemFee && (
            <div className={styles['transaction-details__item']}>
              <b>{t('systemFee')}:</b> {transaction.info.systemFee.amount} {transaction.info.systemFee.currency} ⸺{' '}
              {formatCurrency(transaction.info.systemFee.amountUSD)}
            </div>
          )}

        { transaction.info.explorerUrl.current ? (
          <div className={styles["transaction-details__item"]}>
            <b>{t("homeTransactions.hash")}:</b>{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={
                transaction.type === "InternalTransfer"
                  ? null
                  : `${transaction.info.explorerUrl.current}`
              }
              className={`link link-${identity}`}
            >
              {getHashFromLink(transaction.info.explorerUrl.current)}
            </a>
            <CopyWrapper copyContent={getHashFromLink(transaction.info.explorerUrl.current)}>
              {copyIcon[identity]}
            </CopyWrapper>
          </div>
        ) : null
        }

        {
          transaction.info.explorerUrl.incoming ? (
          <div className="transaction-details__item">
            <>
              <b>{t("homeTransactions.incomingHash")}</b>:{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${transaction.info.explorerUrl.incoming}`}
                className={`link link-${identity}`}
              >
                {getHashFromLink(transaction.info.explorerUrl.incoming)}
              </a>
              <CopyWrapper copyContent={transaction.info.explorerUrl.incoming}>
                {copyIcon[identity]}
              </CopyWrapper>
            </>
          </div>
          ) : null
        }

        </div>
      </div>
    </div>
  );
};

export default TransactionItem;
