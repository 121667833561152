import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { AppConfig } from "../config";
import { getActivities } from "../redux/actions/checkouts";
import { connect } from "react-redux";
import backCpayIcon from "../img/cpay-svg/arrow-back.svg";
import backNFGIcon from "../img/nfgpay-svg/arrow-back-nfg.svg";
import backFinvaroIcon from "../img/finvaro/arrow-back-finvaro.svg";
import backClarniumIcon from "../img/clarnium/arrow-back-clarnium.svg";
import { useTranslation } from "react-i18next";
import ActivitiesList from "../components/Checkouts/Subscriptions/ActivitiesList";

const backIcon = {
  cpay: backCpayIcon,
  nfg: backNFGIcon,
  finvaro: backFinvaroIcon,
  clarnium: backClarniumIcon
};

const ActivitiesPage = ({ merchantId, typeNetwork, activities, getActivities }) => {
  const { t } = useTranslation();

  return (
    <div className="block-for-component">
      <div className="component">
        <Link to="/recurring-billing" className={`admin-page-header__back admin-page-header__back-${AppConfig.identity}`}>
          <img src={backIcon[AppConfig.identity]} alt="Back" />
          <span>{t('goBack')}</span>
        </Link>
        <ActivitiesList getActivities={getActivities} merchantId={merchantId} typeNetwork={typeNetwork} activities={activities} />
      </div>
    </div>
  )
};

const mapStateToProps = state => ({
  merchantId: state.transactions.merchantId,
  typeNetwork: state.networkFilter,
  activities: state.checkouts.activities
});

const mapDispatchToProps = {
  getActivities
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivitiesPage);