import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Menu, Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';
import { setActiveMerchant } from '../../redux/actions/transactions';
import { getMerchants } from '../../redux/actions/merchants';
import NetworkFilter from '../NetworkFilter';
import './MerchantSelector.scss';
import arrowIconCpay from '../../img/cpay-svg/arrow-blue.svg';
import arrowIconNFG from '../../img/nfgpay-svg/arrow-blue-nfg.svg';
import arrowIconFinvaro from '../../img/finvaro/arrow-blue-finvaro.svg';
import arrowIconClarnium from '../../img/clarnium/arrow-blue-clarnium.svg';
import { identity } from '../../utils/getIdentity';
import useWindowSize from "../../utils/resizeHook";

export const dropdownIcon = {
  cpay: arrowIconCpay,
  nfg: arrowIconNFG,
  finvaro: arrowIconFinvaro,
  clarnium: arrowIconClarnium
}

const MerchantSelector = ({ merchantId, merchants, getMerchants, setActiveMerchant }) => {
  const [activeMerchantName, setActiveMerchantName] = useState('');
  const { t } = useTranslation();
  const { currentWidth } = useWindowSize();

  useEffect(() => {
    getMerchants();
  }, [getMerchants]);

  useEffect(() => {
    if (merchants && merchants.length > 0) {
      const storageMerchantId = localStorage.getItem('merchantId');
      if (storageMerchantId && merchants.find(merchant => merchant._id === storageMerchantId)) {
        setActiveMerchant(storageMerchantId);
      } else {
        setActiveMerchant(merchants[0]._id);
      }
    }
  }, [merchants, setActiveMerchant]);

  const handleSelectMerchant = merchantId => {
    setActiveMerchant(merchantId);
    window.location.reload(true);
  };


  return (
    <>
      <NetworkFilter />
      <Dropdown
        trigger={currentWidth <= 768 ? ['click'] : ['hover']}
        overlay={
          <Menu>
            {merchants.map((merchant, index) => {
              if (merchant._id === merchantId && merchant.name !== activeMerchantName) {
                setActiveMerchantName(merchant.name);
              }

              return (
                <Menu.Item key={index}>
                  <div
                    onClick={() => handleSelectMerchant(merchant._id)}
                    className={merchant._id === merchantId ? 'merchant-selector_active' : ''}
                  >
                    {merchant.name}
                  </div>
                </Menu.Item>
              );
            })}
          </Menu>
        }
      >
        <div className={`header__merc-button header__merc-button-${identity}`}>
          <span>{activeMerchantName || t('merchants.selectMerchant')}</span>
          <img src={dropdownIcon[identity]} alt="dropdown" />
        </div>
      </Dropdown>
    </>
  );
};

const mapStateToProps = state => ({
  merchantId: state.transactions.merchantId,
  merchants: state.merchants.data,
});

const mapDispatchToProps = dispatch => ({
  getMerchants: getMerchants(dispatch),
  setActiveMerchant: setActiveMerchant(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(MerchantSelector);
