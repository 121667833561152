import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import Card from '../../components/Card';
import TEXT from '../../components/Text';
import UserTransactionsList from '../../components/UserTransactionsList';
import { resetAdminUserState, getAdminUserById } from '../../../redux/actions/adminPanel/adminUsers';
import { scrollContainerIntoView } from '../../helpers/scrollIntoView';
import backCpayIcon from '../../../img/cpay-svg/arrow-back.svg';
import backNFGIcon from '../../../img/nfgpay-svg/arrow-back-nfg.svg';
import backFinvaroIcon from '../../../img/finvaro/arrow-back-finvaro.svg';
import backClarniumIcon from '../../../img/clarnium/arrow-back-clarnium.svg';
import { identity } from '../../../utils/getIdentity';
import Button from "../../../components/Button";
import { downloadAdminUserTransactionsInfo } from "../../../redux/actions/transactions";
import { notification } from "antd";

const backIcon = {
  cpay: backCpayIcon,
  nfg: backNFGIcon,
  finvaro: backFinvaroIcon,
  clarnium: backClarniumIcon
};

const TransactionsByUser = ({
  getAdminUserById,
  resetAdminUserState,
  currentUserData,
  typeNetwork ,
  downloadAdminUserTransactionsInfo,
  permissions
}) => {
  const { userId } = useParams();
  const { phone, email, name, surname, metamask, id } = currentUserData || {};
  const adminUserTransactionRef = useRef();
  const [dateRange, setDateRange] = useState([]);

  useEffect(() => () => resetAdminUserState(), []);

  const changeDate = (value) => {
    setDateRange(value);
  };

  const downloadUserTransactions = () => {
    if (!dateRange?.length) {
      notification.error({
        message: 'You need to select Date Range'
      });
    } else {
      downloadAdminUserTransactionsInfo(userId, {
        dateRange: dateRange,
      }, typeNetwork);
    }
  }

  useEffect(() => {
    if (userId) {
      getAdminUserById(userId);
    }
  }, [userId]);

  useEffect(() => {
    scrollContainerIntoView(adminUserTransactionRef);
  }, []);

  return (
    <div className="admin-user-transactions" ref={adminUserTransactionRef}>
      <Card>
        <Link
          className={`admin-user-transactions__arrow-back admin-user-transactions__arrow-back-${identity}`}
          to="/admin/users"
        >
          <img src={backIcon[identity]} alt="arrow back" /> <span>Go Back</span>
        </Link>
        <div className="admin-user-transactions--export">
          <TEXT.pageTitle>Transactions by user</TEXT.pageTitle>
          <Button
            className={`admin-button admin-button__primary admin-button__primary-${identity}`}
            type="primary"
            onClick={downloadUserTransactions}
            disabled={permissions && permissions?.length > 0 && !permissions[1]?.edit}
          >
            Export
          </Button>
        </div>
        <UserTransactionsList
          userSearchParams={email || phone || metamask?.address}
          userName={name && surname ? `${name} ${surname}` : name}
          userId={id}
          isFromTransactionsByUser
          changeDate={changeDate}
        />
      </Card>
    </div>
  );
};

const mapStateToProps = state => ({
  currentUserData: state.adminUsers.editableUser.data,
  typeNetwork: state.networkFilter,
  permissions: state.adminSettings.permissions,
});

const mapDispatchToProps = {
  getAdminUserById,
  resetAdminUserState,
  downloadAdminUserTransactionsInfo
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionsByUser);
