import React, { useState } from 'react';
import { Input, Modal } from "antd";
import { ReactComponent as CloseSVG } from '../../../img/default-svg/close-black.svg';
import './style.scss';
import { useTranslation } from "react-i18next";
import Button from "../../../components/Button";

const AddRoleModal = ({ visible, close, createNewRole, getRolesList }) => {
  const { t } = useTranslation();

  const [roleName, setRoleName] = useState('');

  const postCreateRole = () => {
    if (roleName) {
      createNewRole(roleName);
      close();
      setTimeout(() => {
        getRolesList();
      }, 1000);
    }
  }

  return (
    <Modal
      width={620}
      title=""
      visible={visible}
      onCancel={close}
      footer={null}
      className="checkout-modal"
      centered
      closeIcon={<CloseSVG />}
      destroyOnClose
      maskClosable={false}
    >
      <div className="role-modal">
        <div className="role-modal__title">{t('adminRole.addRole')}</div>

        <Input
          placeholder={t('adminRole.roleName')}
          className="modal-form__input"
          onChange={e => setRoleName(e.target.value)}
        />

        <Button onClick={postCreateRole} type="primary">{t('adminRole.add')}</Button>
      </div>
    </Modal>
  )
};

export default AddRoleModal;

