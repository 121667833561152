import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import { Row, Col, Form, Input, Select } from 'antd';
import Button from '../Button';
import { useTranslation } from 'react-i18next';
import {
  getMerchantData,
  editMerchantData,
  getMerchantApiKeys,
  getApiResetTwoFa,
} from '../../redux/actions/merchantData';
import useWindowSize from '../../utils/resizeHook';
import { ReactComponent as CopyIconCpay } from '../../img/cpay-svg/copy-blue.svg';
import { ReactComponent as CopyIconNfg } from '../../img/nfgpay-svg/copy-blue-nfg.svg';
import { ReactComponent as CopyIconFinvaro } from '../../img/finvaro/copy-blue-finvaro.svg';
import { ReactComponent as CopyIconClarnium } from '../../img/clarnium/copy-blue-clarnium.svg';
import CopyWrapper from '../CopyWrapper';

import '../MerchantEdit/MerchantDataForm.scss';
import { identity } from '../../utils/getIdentity';
import MerchantsApiKeysTwoFaConfirm from '../MerchantsApiKeysTwoFaConfirm';
import { sanitizeInputDisable, sanitizeInputValidator } from '../../utils/sanitizeInput';

const copyIcon = {
  cpay: <CopyIconCpay className="copy-icon" />,
  nfg: <CopyIconNfg className="copy-icon" />,
  finvaro: <CopyIconFinvaro className="copy-icon" />,
  clarnium: <CopyIconClarnium className="copy-icon" />,
};

const MerchantDataForm = ({
  userRole,
  merchantId,
  merchantData,
  getMerchantData,
  editMerchantData,
  getMerchantApiKeys,
  merchantApiKeys,
  isFetching,
  getApiResetTwoFa,
}) => {
  const [merchantName, setMerchantName] = useState('');
  const [merchantCompanyName, setMerchantCompanyName] = useState('');
  const [merchantCompanyUrl, setMerchantCompanyUrl] = useState('');
  const [merchantCompanyEmail, setMerchantCompanyEmail] = useState('');
  const [merchantNotificationEmail, setMerchantNotificationEmail] = useState('');
  const [merchantNotificationUrl, setMerchantNotificationUrl] = useState('');
  const [merchantCountry, setMerchantCountry] = useState('');
  const [merchantCity, setMerchantCity] = useState('');
  const [merchantAddress, setMerchantAddress] = useState('');
  const [merchantNetwork, setMerchantNetwork] = useState('');
  const [merchantCustomUrl, setMerchantCustomUrl] = useState('');
  const [isFetchingLocal, setIsFetchingLocal] = useState(false);
  const [publicKey, setPublicKey] = useState('');
  const [privateKey, setPrivateKey] = useState('');
  const [showTwoFaModal, setShowTwoFaModal] = useState(false);
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();
  const { currentWidth } = useWindowSize();

  useEffect(() => {
    if (merchantId) {
      getMerchantData(merchantId);
    }
  }, [merchantId]);

  useEffect(() => {
    if (merchantApiKeys.publicKey && merchantApiKeys.privateKey) {
      setPublicKey(merchantApiKeys.publicKey);
      setPrivateKey(merchantApiKeys.privateKey);
    }
  }, [merchantApiKeys]);

  useEffect(() => {
    setMerchantName(merchantData.name);
    setMerchantCompanyName(merchantData.companyName);
    setMerchantCompanyUrl(merchantData.companyUrl);
    setMerchantCompanyEmail(merchantData.companyEmail);
    setMerchantNotificationEmail(merchantData.notificationEmail);
    setMerchantNotificationUrl(merchantData.notificationUrl);
    setMerchantCountry(merchantData.country);
    setMerchantCity(merchantData.city);
    setMerchantAddress(merchantData.address);
    setMerchantNetwork(merchantData.typeNetwork);
    setMerchantCustomUrl(merchantData.checkoutCustomUrl);

    form.resetFields();
  }, [form, merchantData]);

  const handleFormSubmit = () => {
    setIsFetchingLocal(true);
    const { status, fee, middleWallet } = merchantData;

    const formData = {
      name: merchantName.length ? merchantName : 'Account',
      companyName: merchantCompanyName,
      companyUrl: merchantCompanyUrl,
      companyEmail: merchantCompanyEmail,
      notificationEmail: merchantNotificationEmail,
      notificationUrl: merchantNotificationUrl,
      country: merchantCountry,
      city: merchantCity,
      address: merchantAddress,
      typeNetwork: merchantNetwork,
      checkoutCustomUrl: merchantCustomUrl,
      status,
      fee,
      middleWallet,
    };

    editMerchantData(merchantId, formData).then(() => setIsFetchingLocal(false));
  };

  return (
    <div className="component merchant-data">
      <MerchantsApiKeysTwoFaConfirm
        isVisible={showTwoFaModal}
        setIsVisible={setShowTwoFaModal}
        action={getMerchantApiKeys}
        fetching={isFetching}
        clearWithdrawState={setShowTwoFaModal}
        body={merchantId}
        resetTwoFa={getApiResetTwoFa}
      />
      <div className="component__title">{t('merchants.merchantData')}</div>
      <Form
        form={form}
        layout="vertical"
        name="basic"
        className="form merchant-data-form"
        initialValues={{
          merchantName: merchantData.name,
          merchantCompanyName: merchantData.companyName,
          merchantCompanyUrl: merchantData.companyUrl,
          merchantCompanyEmail: merchantData.companyEmail,
          merchantNotificationEmail: merchantData.notificationEmail,
          merchantNotificationUrl: merchantData.notificationUrl,
          merchantCountry: merchantData.country,
          merchantCity: merchantData.city,
          merchantAddress: merchantData.address,
          merchantNetwork: merchantData.typeNetwork,
          merchantCustomUrl: merchantData.checkoutCustomUrl,
        }}
      >
        <Row>
          <Col span={currentWidth >= 1024 ? 18 : 24}>
            {userRole.includes('corporate') && (
              <>
                <span className={`form__title form__title-${identity}`}>API</span>
                <Row gutter={[20, 0]}>
                  {merchantApiKeys.publicKey && merchantApiKeys.privateKey ? (
                    <Col span="24">
                      <div className="merchant-data-form__api-key">
                        <b>{t('publicKey')}:</b> {publicKey}{' '}
                        <CopyWrapper copyContent={publicKey}>{copyIcon[identity]}</CopyWrapper>
                      </div>
                      <div className="merchant-data-form__api-key">
                        <b>{t('privateKey')}:</b> {privateKey}{' '}
                        <CopyWrapper copyContent={privateKey}>{copyIcon[identity]}</CopyWrapper>
                      </div>
                    </Col>
                  ) : (
                    <Col span={currentWidth >= 1024 ? 7 : 24}>
                      <Button
                        type="primary"
                        className={cn('form__button', { es: i18n.language === 'es' })}
                        onClick={() =>
                          getMerchantApiKeys(merchantId).then(response => {
                            if (response && response.data) {
                              if (response.data.twoFactorToken || response.data.emailSent || response.data.codeSent) {
                                setShowTwoFaModal(true);
                              }
                            }
                          })
                        }
                      >
                        {t('merchants.newApiKeys')}
                      </Button>
                    </Col>
                  )}
                </Row>
              </>
            )}

            <span className={`form__title form__title-${identity}`}>{t('merchants.commonInformation')}</span>
            <Row gutter={[20, 0]}>
              <Col span={currentWidth >= 1024 ? 12 : 24}>
                <Form.Item
                  label={t('merchants.merchantName')}
                  name="merchantName"
                  className="form__item merchant-data-form__name"
                  rules={[sanitizeInputValidator]}
                >
                  <Input
                    className="form__input"
                    placeholder={t('merchants.merchantName')}
                    onChange={e => setMerchantName(e.target.value.trim())}
                    maxLength={30}
                  />
                </Form.Item>
              </Col>

              <Col span={currentWidth >= 1024 ? 12 : 24}>
                <Form.Item name="merchantNetwork" label={t('merchants.merchantsNetwork')} className="form__item">
                  <Select onChange={value => setMerchantNetwork(value)}>
                    <Select.Option value="testnet">{t('testnet')}</Select.Option>
                    <Select.Option value="mainnet">{t('mainnet')}</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            {identity === 'cpay' && (
              <>
                <Row gutter={[20, 0]}>
                  <Col span={currentWidth >= 1024 ? 12 : 24}>
                    <Form.Item
                      label={t('merchants.companyWebsite')}
                      name="merchantCompanyUrl"
                      className="form__item merchant-data-form__website"
                    >
                      <Input
                        className="form__input"
                        placeholder={t('merchants.companyWebsite')}
                        onChange={e => setMerchantCompanyUrl(e.target.value.trim())}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={currentWidth >= 1024 ? 12 : 24}>
                    <Form.Item
                      label={t('merchants.companyName')}
                      name="merchantCompanyName"
                      className="form__item merchant-data-form__website"
                    >
                      <Input
                        className="form__input"
                        placeholder={t('merchants.companyName')}
                        onChange={e => setMerchantCompanyName(e.target.value.trim())}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[20, 0]}>
                  <Col span={currentWidth >= 1024 ? 12 : 24}>
                    <Form.Item
                      label={t('country')}
                      name="merchantCountry"
                      rules={[sanitizeInputValidator]}
                      className="form__item merchant-data-form__name"
                    >
                      <Input
                        className="form__input"
                        placeholder={t('country')}
                        onChange={e => setMerchantCountry(e.target.value.trim())}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={currentWidth >= 1024 ? 12 : 24}>
                    <Form.Item
                      label={t('city')}
                      name="merchantCity"
                      rules={[sanitizeInputValidator]}
                      className="form__item merchant-data-form__website"
                    >
                      <Input
                        className="form__input"
                        placeholder={t('city')}
                        onChange={e => setMerchantCity(e.target.value.trim())}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[20, 0]}>
                  <Col span="24">
                    <Form.Item
                      label={t('address')}
                      name="merchantAddress"
                      className="form__item merchant-data-form__name"
                    >
                      <Input
                        className="form__input"
                        placeholder={t('address')}
                        onChange={e => setMerchantAddress(e.target.value.trim())}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}

            <Row gutter={[20, 0]}>
              <Col span={currentWidth >= 1024 ? 12 : 24}>
                <Form.Item
                  name="merchantCompanyEmail"
                  label={t('merchants.publicEmailForMerchantsClients')}
                  rules={[{ type: 'email' }]}
                  className="form__item merchant-data-form__public-email"
                >
                  <Input
                    className="form__input"
                    placeholder={t('email')}
                    onChange={e => setMerchantCompanyEmail(e.target.value.trim())}
                  />
                </Form.Item>
              </Col>

              <Col span={currentWidth >= 1024 ? 12 : 24}>
                <Form.Item
                  name="merchantNotificationEmail"
                  label={t('merchants.emailForNotifications')}
                  rules={[{ type: 'email' }]}
                  className="form__item merchant-data-form__email"
                >
                  <Input
                    className="form__input"
                    placeholder={t('email')}
                    onChange={e => setMerchantNotificationEmail(e.target.value.trim())}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[20, 0]}>
              <Col span={currentWidth >= 1024 ? 12 : 24}>
                <Form.Item
                  name="merchantNotificationUrl"
                  label={t('merchants.callbackURL')}
                  className="form__item merchant-data-form__public-email"
                >
                  <Input
                    className="form__input"
                    placeholder={t('merchants.callbackURL')}
                    onChange={e => setMerchantNotificationUrl(e.target.value.trim())}
                  />
                </Form.Item>
              </Col>

              <Col span={currentWidth >= 1024 ? 12 : 24}>
                <Form.Item
                  name="merchantCustomUrl"
                  label={t('merchants.customLinkForCheckout')}
                  className="form__item merchant-data-form__public-email"
                >
                  <Input
                    className="form__input"
                    placeholder={t('merchants.customLinkForCheckout')}
                    onChange={e => setMerchantCustomUrl(e.target.value.trim())}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={currentWidth >= 1024 ? 5 : 24}>
            <Button
              type="primary"
              className="form__button"
              onClick={handleFormSubmit}
              loading={isFetchingLocal && isFetching}
              disabled={
                sanitizeInputDisable(merchantName) ||
                sanitizeInputDisable(merchantCountry) ||
                sanitizeInputDisable(merchantCity)
              }
            >
              {t('submit')}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

const mapStateToProps = state => ({
  userRole: state.user.data.roles,
  merchantId: state.transactions.merchantId,
  merchantData: state.merchantData.data,
  merchantApiKeys: state.merchantData.apiKeys,
  isFetching: state.merchantData.fetching,
});

const mapDispatchToProps = dispatch => ({
  getMerchantData: getMerchantData(dispatch),
  editMerchantData: editMerchantData(dispatch),
  getMerchantApiKeys: getMerchantApiKeys(dispatch),
  getApiResetTwoFa: () => dispatch(getApiResetTwoFa()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MerchantDataForm);
