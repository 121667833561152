import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, Radio, Checkbox, Input } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import resetCpayIcon from '../../../img/cpay-svg/reset.svg';
import resetNfgIcon from '../../../img/nfgpay-svg/reset-nfg.svg';
import resetFinvaroIcon from '../../../img/finvaro/reset-finvaro.svg';
import resetClarniumIcon from '../../../img/clarnium/reset-clarnium.svg';
import TransactionDownloadForm from '../TransactionDownloadForm/TransactionDownloadForm';
import './style.scss';
import { identity } from '../../../utils/getIdentity';
import { sanitizeSearchInputDisable } from '../../../utils/sanitizeInput';

const resetIcon = {
  cpay: resetCpayIcon,
  nfg: resetNfgIcon,
  finvaro: resetFinvaroIcon,
  clarnium: resetClarniumIcon
};

const TransactionFilters = ({
  search,
  setSearch,
  order,
  setOrder,
  type,
  setType,
  submitSearch,
  resetFilters,
  downloadTransactionsInfo,
  merchantId,
  networkFilter,
  transInfoFetching,
  onlyNft,
  setOnlyNft,
  nftType,
  setNftType,
  hideNft,
  setHideNft,
  fromPage,
  setTime
}) => {
  const [isSortingVisible, setIsSortingVisible] = useState(false);
  const { t } = useTranslation();

  return (
    <div className="transaction-filters">
      {fromPage === 'home' && <div className="transaction-filters__title">{t('transactions')}</div>}
      <div className="transaction-filters__search-wrap">
        <div className="transaction-filters__search">
          <Input.Search
            name="search"
            placeholder={t('searchBy')}
            onSearch={value => {
              sanitizeSearchInputDisable(value) ? submitSearch('') : submitSearch(value)
            } }
            className="transaction-filters__search-field"
            onChange={(e) => {
              setSearch(e.target.value)
              fromPage === 'home' ? localStorage.setItem('search', e.target.value) :  localStorage.setItem('search_tr', e.target.value)
            }}
            value={search}
            addonAfter={
              <span onClick={resetFilters} className="transaction-filters__reset-button">
                <img src={resetIcon[identity]} alt="Reset" />
              </span>
            }
          />
        </div>
        <div className="transaction-filters__sort-wrap">
          <div className="transaction-filters__sorting">
            <Dropdown
              onVisibleChange={visibilityState => setIsSortingVisible(visibilityState)}
              visible={isSortingVisible}
              overlay={
                <div className="transaction-filters__currencies-dropdown">
                  <Radio.Group
                    value={order}
                    onChange={e => {
                      setOrder(e.target.value);
                      fromPage === 'home' ?  localStorage.setItem('order', e.target.value) : localStorage.setItem('order_tr', e.target.value)
                    }}
                  >
                    <Radio value="DESC">{t('first')}</Radio>
                    <Radio value="ASC">{t('last')}</Radio>
                  </Radio.Group>
                  {fromPage === 'home' && 
                  <>
                    <div className="dropdown-divider"></div>
                    <Radio.Group
                    value={type}
                    onChange={e => {
                      setType(e.target.value);
                      localStorage.setItem('type', e.target.value)
                    }}
                  >
                    <Radio value="replenishment,withdrawal,Error,refund,multisend,InternalTransfer,ExternalCall">
                      {t('all')}
                    </Radio>
                    <Radio value="replenishment">{t('transactionFilter.dropdown.replenishment')}</Radio>
                    <Radio value="withdrawal">{t('withdraw.withdrawal')}</Radio>
                    <Radio value="multisend">{t('transactionFilter.dropdown.multisend')}</Radio>
                    <Radio value="Error">{t('transactionFilter.dropdown.error')}</Radio>
                    <Radio value="refund">{t('transactionFilter.dropdown.refund')}</Radio>
                    <Radio value="InternalTransfer">{t('transactionFilter.dropdown.internalTransfer')}</Radio>
                    <Radio value="ExternalCall">{t('transactionFilter.dropdown.externalCall')}</Radio>
                  </Radio.Group>

                  <div className="dropdown-divider"></div>
                  <div className="transaction-filters__currencies-dropdown_nft-block">
                    <Checkbox
                      checked={!!hideNft}
                      onChange={(e) => {
                        setHideNft(e.target.checked ?? false);
                        setOnlyNft(false);
                        localStorage.setItem('hideNft', e.target.checked);
                        localStorage.setItem('onlyNft', false);
                      }}
                    >
                      Hide NFT
                    </Checkbox>
                    <Checkbox
                      checked={!!onlyNft}
                      onChange={(e) => {
                        setOnlyNft(e.target.checked ?? false);
                        setHideNft(false);
                        localStorage.setItem('hideNft', false);
                        localStorage.setItem('onlyNft', true)
                      }}
                    >
                      Only NFT
                    </Checkbox>
                  </div>

                  <Radio.Group value={nftType} onChange={e => {
                    setNftType(e.target.value)
                    localStorage.setItem('nftType', e.target.value)
                  }} disabled={!onlyNft}>
                    <Radio value={undefined}>{t('all')}</Radio>
                    <Radio value="erc721">NFT ERC-721</Radio>
                    <Radio value="erc1155">NFT ERC-1155</Radio>
                  </Radio.Group>
                  </>}
                </div>
              }
            >
              <div
                className={`transaction-filters__currencies-button transaction-filters__currencies-button-${identity}`}
              >
                {t('sorting')} <DownOutlined />
              </div>
            </Dropdown>
          </div>
        </div>
        <TransactionDownloadForm
          setTime={setTime}
          downloadTransactionsInfo={downloadTransactionsInfo}
          merchantId={merchantId}
          networkFilter={networkFilter}
          transInfoFetching={transInfoFetching}
        />
      </div>
    </div>
  );
};

export default TransactionFilters;
