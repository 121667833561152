import React from 'react';
// Components
import Button from '../../Button';
import { Input } from 'antd';
import resetCpayIcon from '../../../img/cpay-svg/reset.svg';
import resetNfgIcon from '../../../img/nfgpay-svg/reset-nfg.svg';
import resetFinvaroIcon from '../../../img/finvaro/reset-finvaro.svg';
import resetClarniumIcon from '../../../img/clarnium/reset-clarnium.svg';
// Utils
import { useTranslation } from 'react-i18next';
// Style
import './style.scss';
import { identity } from '../../../utils/getIdentity';

const resetIcon = {
  cpay: resetCpayIcon,
  nfg: resetNfgIcon,
  finvaro: resetFinvaroIcon,
  clarnium: resetClarniumIcon,
};

const CheckoutsHeader = ({
  data,
  setIsVisible,
  fetching,
  search,
  type,
  setSearch,
  getCheckoutListHandler,
  getPlanListHandler,
}) => {
  const { t } = useTranslation();

  const resetSearch = () => {
    setSearch(null);
    type === 'plan' ? getPlanListHandler() : getCheckoutListHandler(1);
  };

  return (
    <div className="checkouts-header">
      <div className="checkouts-header__title">
        {type === 'plan' ? t('checkouts.recurringTitle') : t('checkouts.checkouts')}
        <div className="checkouts-header__controls">
          <Input.Search
            disabled={fetching}
            name="search"
            placeholder={t('search')}
            onSearch={() => {
              type === 'plan' ? getPlanListHandler(search) : getCheckoutListHandler(1, search);
            }}
            className="checkouts-header__search-field"
            onChange={e => setSearch(e.target.value)}
            value={search}
            addonAfter={
              <span onClick={resetSearch} className="checkouts-header__reset-button">
                <img src={resetIcon[identity]} alt="Reset" />
              </span>
            }
          />
          {data && data.length ? (
            <Button type="secondary" className="checkouts-list__button" onClick={() => setIsVisible(true)}>
              {type === 'plan' ? t('checkouts.createPlan') : t('checkouts.createCheckout')}
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default CheckoutsHeader;
