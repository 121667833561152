import React, { useState } from 'react';
import { Form, Select, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SelectArrowIcon } from '../../../img/swap-svg/swap-select-arrow.svg';
import { ReactComponent as SwapFiltersIcon } from '../../../img/swap-svg/swap-filters.svg';
import useWindowSize from '../../../utils/resizeHook.js';
import Button from '../../Button/index.jsx';
import './style.scss';
import { identity } from '../../../utils/getIdentity.js';

const OffersFilters = ({ setSwapOffersSort, setSwapOffersType, swapOffersUpdating, swapOffersSort, swapOffersType }) => {
  const [showMobileFilters, setShowMobileFilters] = useState(false);
  const [form] = Form.useForm();
  const { currentWidth } = useWindowSize();
  const {t} = useTranslation();

  const filters = (
    <>
      <Form.Item className="swap-offers-filters__label" label={t('swap.filters.sortBy')} name="sort">
        <Select
          className="swap-offers-filters__select swap-offers-filters__select-sort"
          suffixIcon={<SelectArrowIcon />}
          onChange={currentWidth > 590 ? form.submit : undefined}
          disabled={swapOffersUpdating}
        >
          <Select.Option value="rate">{t('swap.filters.rate')}</Select.Option>
          <Select.Option value="duration">ETA</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item className="swap-offers-filters__label" label={t('swap.filters.show')} name="type">
        <Select
          className="swap-offers-filters__select swap-offers-filters__select-sort"
          suffixIcon={<SelectArrowIcon />}
          onChange={currentWidth > 590 ? form.submit : undefined}
          disabled={swapOffersUpdating}
        >
          <Select.Option value="">{t('swap.filters.all')}</Select.Option>
          <Select.Option value="fixed">{t('swap.filters.fixed')}</Select.Option>
          <Select.Option value="float">{t('swap.filters.float')}</Select.Option>
        </Select>
      </Form.Item>
    </>
  );

  return (
    <Form
      className="swap-offers-filters"
      initialValues={{
        sort: swapOffersSort || 'rate',
        type: swapOffersType || '',
      }}
      onFinish={values => {
        setSwapOffersSort(values.sort);
        setSwapOffersType(values.type);
      }}
      form={form}
    >
      {currentWidth > 590 ? (
        filters
      ) : (
        <>
          <div className={`swap-history-filters__filters-switch swap-history-filters__filters-switch-${identity}`} onClick={() => setShowMobileFilters(true)}>
            <SwapFiltersIcon />
            {t('swap.filters.filters')}
          </div>
          <Modal
            title="Filters"
            visible={showMobileFilters}
            footer={null}
            onCancel={() => setShowMobileFilters(false)}
            className={`swap-offers-filters__modal swap-offers-filters__modal-${identity}`}
            transitionName=""
          >
            {filters}
            <div className="swap-offers-filters__modal-buttons">
              <Button
                type="secondary"
                onClick={() => {
                  form.resetFields();
                  form.submit();
                }}
              >
                {t('swap.offers.reset')}
              </Button>
              <Button onClick={form.submit}>{t('swap.filters.apply')}</Button>
            </div>
          </Modal>
        </>
      )}
    </Form>
  );
};

export default OffersFilters;
