import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { Form, Input, Row, Radio, Button } from 'antd';
import { signUpByPhone } from '../../redux/actions/user';
import PasswordStrength from '../PasswordStrength';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import profileIcon from '../../img/default-svg/profile.svg';
import companyIcon from '../../img/default-svg/company.svg';
import useWindowSize from '../../utils/resizeHook';
import backCpayIcon from '../../img/cpay-svg/arrow-back.svg';
import backNFGIcon from '../../img/nfgpay-svg/arrow-back-nfg.svg';
import backFinvaroIcon from '../../img/finvaro/arrow-back-finvaro.svg';
import backClarniumIcon from '../../img/clarnium/arrow-back-clarnium.svg';
import PhoneInput from 'react-phone-input-2';
import { identity } from '../../utils/getIdentity';

const backIcon = {
  cpay: backCpayIcon,
  nfg: backNFGIcon,
  finvaro: backFinvaroIcon,
  clarnium: backClarniumIcon
}


function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const SignupPhoneForm = ({
  isFetching,
  signUpByPhone,
  setAccountType,
  showPhoneCodeVerify,
  backAction,
  setShowPhoneCodeVerify,
}) => {
  const query = useQuery();
  const [phone, setPhone] = useState(query.get('phone') || '');
  const [password, setPassword] = useState('');
  const [userType, setUserType] = useState('corporate');
  const [verifyCode, setVerifyCode] = useState(undefined);
  const recaptchaRef = useRef();
  const { t } = useTranslation();
  const { isSm } = useWindowSize();

  const handleFormSubmit = async () => {
    const recaptcha = await recaptchaRef.current.executeAsync();

    const formData = {
      phone: phone.trim(),
      password: password.trim(),
      roles: [userType],
      verifyCode: verifyCode,
      inviterId: query.get('inviterId') || localStorage.getItem('cpayInviterId') || undefined,
    };

    try {
      await signUpByPhone(formData, recaptcha);
      recaptchaRef.current.reset();
    } catch {
      recaptchaRef.current.reset();
    }
  };

  useEffect(() => {
    if (query.get('inviterId')) {
      localStorage.setItem('cpayInviterId', query.get('inviterId'));
    }
  }, []);

  useEffect(() => {
    setAccountType(userType);
    return () => {
      setAccountType(null);
    };
  }, [userType]);

  return (
    <Form
      name="basic"
      initialValues={{ merchantComission: userType, phone: phone, verifyCode: verifyCode }}
      className={`login-form login-form-${identity}`}
    >
      {!showPhoneCodeVerify ? (
        <>
          <p className={`login-form__title login-form__title-${identity}`}>{t('auth.createAccount')}</p>
          {isSm && (
            <div style={{ marginBottom: '20px' }}>
              <span className={`login-info__thesis login-info__thesis-${identity}`}>{t('auth.welcomeTextBusiness.thesis1')}</span>
              <span className={`login-info__thesis login-info__thesis-${identity}`}>{t('auth.welcomeTextBusiness.thesis2')}</span>
              <span className={`login-info__thesis login-info__thesis-${identity}`}>{t('auth.welcomeTextBusiness.thesis3')}</span>
              <span className={`login-info__thesis login-info__thesis-${identity}`}>{t('auth.welcomeTextBusiness.thesis4')}</span>
              <span className={`login-info__thesis login-info__thesis-${identity}`}>{t('auth.welcomeTextBusiness.thesis5')}</span>
            </div>
          )}
          <p className={`login-form__label login-form__label-${identity}`}>{t('phone')}</p>
          <Form.Item name="userPhone" /*className="form__item"*/>
            <PhoneInput
              containerClass="form__input form__input-phone login-form__input-phone"
              country={'us'}
              onChange={phone => setPhone(`+${phone}`)}
            />
          </Form.Item>

          <p className={`login-form__label login-form__label-${identity}`}>{t('password')}</p>
          <Form.Item name="password" rules={[{ required: true, message: t('validation.required') }]}>
            <Input.Password
              name="password"
              autoComplete="new-password"
              placeholder={t('password')}
              className="login-form__input"
              onChange={e => setPassword(e.target.value)}
            />
          </Form.Item>
          <PasswordStrength password={password} />
        </>
      ) : (
        <>
          <p className={`login-form__label login-form__label-${identity}`}>{t('auth.verifyCode')}</p>
          <Form.Item name="verifyCode" rules={[{ required: true, message: t('validation.required') }]}>
            <Input
              name="verifyCode"
              placeholder={t('auth.сodeFromSms')}
              className="login-form__input"
              onChange={e => setVerifyCode(e.target.value)}
            />
          </Form.Item>
        </>
      )}

      <ReCAPTCHA ref={recaptchaRef} sitekey={process.env.REACT_APP_CAPTCHA_KEY} size="invisible" />

      <div className="login-form__buttons-wrap">
        <div
          className={`login-form__back-button`}
          onClick={() => (showPhoneCodeVerify ? setShowPhoneCodeVerify(false) : backAction())}
        >
          <img src={backIcon[identity]} alt="back" />
        </div>

        <Button
          type="primary"
          className={`login-form__button login-form__button-${identity}`}
          onClick={!isFetching && handleFormSubmit}
          loading={isFetching}
          id="signup_phone"
        >
          {t('signUp')}
        </Button>
      </div>

      <Row>
        <p className={`login-form__text login-form__text_center login-form__text-${identity}`}>
          {t('auth.alreadyHaveAccount')} <Link to="/login">{t('logIn')}</Link>
        </p>
      </Row>
    </Form>
  );
};

const mapStateToProps = state => ({
  isFetching: state.user.fetching,
  showPhoneCodeVerify: state.user.showPhoneCodeVerify,
});

const mapDispatchToProps = dispatch => ({
  signUpByPhone: signUpByPhone(dispatch),
  setShowPhoneCodeVerify: isShowCode =>
    dispatch({
      type: 'USER_SHOW_PHONE_CODE_VERIFY',
      payload: isShowCode,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignupPhoneForm);
