import React, {useState} from 'react'
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Button from '../Button';
import { Row, Col, Modal, Form, Input, notification } from 'antd';
import './style.scss';
import '../../layout/Modal.scss';
import { twoFactorApi } from '../../service/two-factor-api';
import { deactivatePhoneTwoFactor } from '../../redux/actions/twoFactor';
import { changePhoneTwoFaStatus } from '../../redux/actions/user';
import { identity } from '../../utils/getIdentity';

const PhoneTwoFaModal = ({openTwoFaModal, handleCloseModal, changePhoneTwoFaStatus}) => {
  const { t } = useTranslation();
  const [verifyCode, setVerifyCode] = useState('');
  const [errorMessage, setErrorMessage] = useState(false)
  const [errMessage, setErrMsg] = useState('')

  const handleSubmit = async () => {
    try {
      await twoFactorApi.deactivatePhoneTwoFactor({verifyCode})
      changePhoneTwoFaStatus(false);
      handleCloseModal()
      notification.success({
        message: t('success')
      })
    } catch (error) {
      setErrorMessage(true)
      setErrMsg(error.data.message)
    }
  }

  return (
    <>
    <Modal
      title={t('profile.disableTwoFa')}
      visible={openTwoFaModal}
      width={534}
      footer={null}
      onCancel={handleCloseModal}
      className={`twofa__modal twofa__modal-${identity}`}
    >
       <Form
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          name="basic"
          initialValues={{ remember: true }}
          className="twofa__modal-form"
          onFinish={handleSubmit}
        >
          <Form.Item
            label={t("auth.keyFromPhone")}
            name="verifyCode"
            className="modal-form__label"
            // rules={[{ required: true, message: t('validation.required') }]}
          >
            <Input
              placeholder={t("auth.keyFromPhone")}
              className="modal-form__input"
              onChange={e => setVerifyCode(e.target.value)}
            />
             {errorMessage && <p style={{fontSize: '13px', color: '#E85B48' }}>{errMessage}</p>}
          </Form.Item>
          <Row className="modal__buttons-wrapper">
            <Col span="12">
              <Button
                type="primary"
                className={`modal__button modal__button-create modal__button modal__button-create-${identity} modal__button-create=${identity}`}
              >
               {t('profile.disableTwoFa')}
              </Button>
            </Col>
            <Col span="12">
              <Button
                type="primary"
                className="modal__button modal__button-cancel"
                onClick={e => {
                  e.preventDefault();
                  handleCloseModal();
                }}
              >
                {t('cancel')}
              </Button>
            </Col>
          </Row>
        </Form>
    </Modal>
    </>

  )
}

  const mapDispatchToProps = dispatch => ({
    deactivatePhoneTwoFactor: deactivatePhoneTwoFactor(dispatch),
    changePhoneTwoFaStatus: changePhoneTwoFaStatus(dispatch),
  });

export default connect(null, mapDispatchToProps)(PhoneTwoFaModal);