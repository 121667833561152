import React, { useState } from 'react';
import { Input, Modal } from "antd";
import { ReactComponent as CloseSVG } from '../../../img/default-svg/close-black.svg';
import './style.scss';
import { useTranslation } from "react-i18next";
import Button from "../../../components/Button";

const RemoveRoleModal = ({ visible, close, deleteRole, getRolesList, removeId, removeName }) => {
  const { t } = useTranslation();

  const removeRole = () => {
    deleteRole(removeId);
    close();
    setTimeout(() => {
      getRolesList();
    }, 1000);
  }

  return (
    <Modal
      width={620}
      title=""
      visible={visible}
      onCancel={close}
      footer={null}
      className="checkout-modal"
      centered
      closeIcon={<CloseSVG />}
      destroyOnClose
      maskClosable={false}
    >
      <div className="role-modal">
        <div className="role-modal__title">Do you want to remove {removeName} role?</div>

        <Button onClick={removeRole} type="primary">{t('adminRole.remove')}</Button>
      </div>
    </Modal>
  )
};

export default RemoveRoleModal;