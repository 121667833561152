import { adminApi } from '../../../service/admin-api';
import { notificationsSuccessShow, notificationsErrorShow } from '../notifications';

// GET CURRENT ADMIN SETTINGS

export const GET_ADMIN_SETTINGS_REQUEST = 'GET_ADMIN_SETTINGS_REQUEST';
export const GET_ADMIN_SETTINGS_SUCCESS = 'GET_ADMIN_SETTINGS_SUCCESS';

export const getAdminSettingsRequest = () => ({
  type: GET_ADMIN_SETTINGS_REQUEST,
});

export const getAdminSettingsSuccess = payload => ({
  type: GET_ADMIN_SETTINGS_SUCCESS,
  payload,
});

export const getAdminSettings = () => async dispatch => {
  dispatch(getAdminSettingsRequest());

  try {
    const response = await adminApi.getAdminSettings();
    dispatch(getAdminSettingsSuccess(response.data));
  } catch (error) {
    dispatch(notificationsErrorShow(error.data));
  }
};

// UPDATE ADMIN SETTINGS

export const UPDATE_ADMIN_SETTINGS_REQUEST = 'UPDATE_ADMIN_SETTINGS_REQUEST';
export const UPDATE_ADMIN_SETTINGS_SUCCESS = 'UPDATE_ADMIN_SETTINGS_SUCCESS';
export const UPDATE_ADMIN_SETTINGS_FAIL = 'UPDATE_ADMIN_SETTINGS_FAIL';


export const updateAdminSettingsRequest = () => ({
  type: UPDATE_ADMIN_SETTINGS_REQUEST,
});

export const updateAdminSettingsSuccess = payload => ({
  type: UPDATE_ADMIN_SETTINGS_SUCCESS,
  payload,
});

export const updateAdminSettingsFail = () => ({
  type: UPDATE_ADMIN_SETTINGS_FAIL,
});

export const updateAdminSettings = formData => async dispatch => {
  dispatch(updateAdminSettingsRequest());

  try {
    const response = await adminApi.updateAdminSettings(formData);
    dispatch(updateAdminSettingsSuccess(response.data));
    dispatch(notificationsSuccessShow({}));
  } catch (error) {
    dispatch(updateAdminSettingsFail());
    dispatch(notificationsErrorShow(error.data));
  }
};

// GET ROLES LIST

export const GET_ROLES_LIST_REQUEST = 'GET_ROLES_LIST_REQUEST';
export const GET_ROLES_LIST_SUCCESS = 'GET_ROLES_LIST_SUCCESS';
export const GET_ROLES_LIST_FAIL = 'GET_ROLES_LIST_FAIL';

const getRolesListRequest = () => ({
  type: GET_ROLES_LIST_REQUEST
});

const getRolesListSuccess = payload => ({
  type: GET_ROLES_LIST_SUCCESS,
  payload
});

const getRolesListFail = payload => ({
  type: GET_ROLES_LIST_FAIL,
  payload
});

export const getRolesList = () => async dispatch => {
  dispatch(getRolesListRequest());
  try {
    const response = await adminApi.getRolesList();
    dispatch(getRolesListSuccess(response?.data));
  } catch (error) {
    dispatch(getRolesListFail(error));
    dispatch(notificationsErrorShow(error.data));
  }
};

// CREATE NEW ROLE

export const createNewRole = (name) => async dispatch => {
  try {
    await adminApi.createNewRole(name);
    dispatch(notificationsSuccessShow({}));
  } catch (error) {
    dispatch(notificationsErrorShow(error.data));
  }
};

// DELETE ROLE

export const deleteRole = (roleId) => async dispatch => {
  try {
    await adminApi.deleteRole(roleId);
    dispatch(notificationsSuccessShow({}));
  } catch (error) {
    dispatch(notificationsErrorShow(error.data));
  }
}

// GET ROLE PERMISSIONS

export const GET_ROLE_PERMISSIONS_REQUEST = 'GET_ROLE_PERMISSIONS_REQUEST';
export const GET_ROLE_PERMISSIONS_SUCCESS = 'GET_ROLE_PERMISSIONS_SUCCESS';
export const GET_ROLE_PERMISSIONS_LIST_SUCCESS = 'GET_ROLE_PERMISSIONS_LIST_SUCCESS';
export const GET_ROLE_PERMISSIONS_FAIL = 'GET_ROLE_PERMISSIONS_FAIL';

const getRolePermissionsRequest = () => ({
  type: GET_ROLE_PERMISSIONS_REQUEST
});

const getRolePermissionsSuccess = payload => ({
  type: GET_ROLE_PERMISSIONS_SUCCESS,
  payload
});

const getRolePermissionsListSuccess = payload => ({
  type: GET_ROLE_PERMISSIONS_LIST_SUCCESS,
  payload
});

const getRolePermissionsFail = payload => ({
  type: GET_ROLE_PERMISSIONS_FAIL,
  payload
});

export const getRolePermissions = (type = 'role', roleId) => async dispatch => {
  dispatch(getRolePermissionsRequest());
  try {
    const response = await adminApi.getRolePermissions(roleId);
    if (type === 'edit') {
      dispatch(getRolePermissionsListSuccess(response));
    } else {
      dispatch(getRolePermissionsSuccess(response));
    }
  } catch (error) {
    dispatch(getRolePermissionsFail(error));
    dispatch(notificationsErrorShow(error.data));
  }
};

// CHANGE ROLE PERMISSION

export const changeRolePermissions = (roleId, body) => async dispatch => {
  try {
    await adminApi.changeRolePermissions(roleId, body);
    dispatch(notificationsSuccessShow({}));
  } catch (error) {
    dispatch(notificationsErrorShow(error.data));
  }
};

// GET USER LOGIN HISTORY

export const GET_USER_LOGIN_HISTORY_REQUEST = 'GET_USER_LOGIN_HISTORY_REQUEST';
export const GET_USER_LOGIN_HISTORY_SUCCESS = 'GET_USER_LOGIN_HISTORY_SUCCESS';
export const GET_USER_LOGIN_HISTORY_FAIL = 'GET_USER_LOGIN_HISTORY_FAIL';

const getUserLoginHistoryRequest = () => ({
  type: GET_USER_LOGIN_HISTORY_REQUEST
});

const getUserLoginHistorySuccess = payload => ({
  type: GET_USER_LOGIN_HISTORY_SUCCESS,
  payload
});

const getUserLoginHistoryFail = payload => ({
  type: GET_USER_LOGIN_HISTORY_FAIL,
  payload
});

export const getUserLoginHistory = (userId, params) => async dispatch => {
  dispatch(getUserLoginHistoryRequest());
  try {
    const response = await adminApi.getUserLoginHistory(userId, params);
    dispatch(getUserLoginHistorySuccess(response));
  } catch (error) {
    dispatch(getUserLoginHistoryFail(error));
    dispatch(notificationsErrorShow(error.data));
  }
}
