import {
  GET_ADMIN_SETTINGS_REQUEST,
  GET_ADMIN_SETTINGS_SUCCESS,
  UPDATE_ADMIN_SETTINGS_REQUEST,
  UPDATE_ADMIN_SETTINGS_SUCCESS,
  UPDATE_ADMIN_SETTINGS_FAIL,
  // GET ROLES LIST
  GET_ROLES_LIST_REQUEST,
  GET_ROLES_LIST_SUCCESS,
  GET_ROLES_LIST_FAIL,
  // GET ROLE PERMISSIONS
  GET_ROLE_PERMISSIONS_REQUEST,
  GET_ROLE_PERMISSIONS_SUCCESS,
  GET_ROLE_PERMISSIONS_LIST_SUCCESS,
  GET_ROLE_PERMISSIONS_FAIL,
  // GET USER LOGIN HISTORY
  GET_USER_LOGIN_HISTORY_REQUEST,
  GET_USER_LOGIN_HISTORY_SUCCESS,
  GET_USER_LOGIN_HISTORY_FAIL
} from '../../actions/adminPanel/adminSettings';

export default function reducer(
  state = {
    data: {},
    fetching: false,
    rolesList: [],
    permissions: [],
    permissionsList: [],
    roleName: '',
    loginHistory: { data: null, page: 1, pages: null, countItem: null },
  },
  action
) {
  switch (action.type) {
    case GET_ADMIN_SETTINGS_REQUEST:
    case UPDATE_ADMIN_SETTINGS_REQUEST:
    case GET_ROLES_LIST_REQUEST:
    case GET_ROLE_PERMISSIONS_REQUEST:
    case GET_USER_LOGIN_HISTORY_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case GET_ADMIN_SETTINGS_SUCCESS:
    case UPDATE_ADMIN_SETTINGS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        fetching: false,
      };
    case GET_ROLES_LIST_SUCCESS:
      return {
        ...state,
        rolesList: action.payload,
        fetching: false,
      };
    case GET_ROLE_PERMISSIONS_SUCCESS:
      return {
        ...state,
        permissions: action.payload.data?.permissions,
        fetching: false,
      };
    case GET_ROLE_PERMISSIONS_LIST_SUCCESS:
      return {
        ...state,
        permissionsList: action.payload.data?.permissions,
        roleName: action.payload.data?.name,
        fetching: false,
      };
    case GET_USER_LOGIN_HISTORY_SUCCESS:
      return {
        ...state,
        loginHistory: {
          ...state.loginHistory,
          data: action.payload.entities,
          page: action.payload.page,
          pages: action.payload.pages,
          countItem: action.payload.countItems
        },
        fetching: false
      }
    case UPDATE_ADMIN_SETTINGS_FAIL:
    case GET_ROLES_LIST_FAIL:
    case GET_ROLE_PERMISSIONS_FAIL:
    case GET_USER_LOGIN_HISTORY_FAIL:
      return {
        ...state,
        fetching: false
      };
    default:
      return state;
  }
}
