import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { cryptoApi } from '../../../../../service/cryptopay-api';
import { notificationsErrorShow } from '../../../../../redux/actions/notifications';
import Button from '../../../../Button';
import UploadComponent from '../UploadComponent';
import { useTranslation } from 'react-i18next';
import { Form, Input, Select, Tabs, Radio, Spin, Tooltip } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { getMerchantWallets, merchantWalletsReset } from '../../../../../redux/actions/merchantWallets';
import CurrencyDropdown from '../../../../CurrencyDropdown';
import TokenIcon from '../../../../TokenIcon';
import formatCurrency from '../../../../../utils/currencyFormatter';
import { ReactComponent as DropdownIcon } from '../../../../../img/default-svg/chevron-down.svg';
import './style.scss';
import { whitespaceInputValidator } from '../../../../../utils/whitespaceInputValidator';
import { mainColor } from '../../../../../utils/getMainColor';
import { identity } from '../../../../../utils/getIdentity';
import { ReactComponent as HelpCircleSVG } from '../../../../../img/default-svg/help-circle.svg';
import PlanDetails from '../../PlanDetails';
import { useLocation } from 'react-router-dom';
import { utils } from 'ethers';
import { checkAutosignStatus } from '../../../../../redux/actions/withdraw';
import uploadFile from '../../../../../img/cpay-svg/upload.svg';
import UploadAutosignWrapper from '../../../../../components/Withdraw/UploadAutosignWrapper';
import debounce from 'lodash/debounce';

const { TextArea } = Input;

const SetData = ({
  prevStep,
  checkoutType,
  isDuplicating,
  createCheckoutHandler,
  editCheckoutState,
  ifCreateFetching,
  ifPatchFetching,
  activeMerchantCurrencies,
  merchantId,
  typeNetwork,
  merchantWallets,
  getMerchantWallets,
  merchantWalletsReset,
  currencies,
  notificationsErrorShow,
  merchants,
  supportedCurrencies,
  supportedWallets,
  close,
  checkAutosignStatus,
  autosignStatus,
  autosignStep,
}) => {
  const [titleLength, setTitleLength] = useState('');
  const [payeeAddressLength, setPayeeAddressLength] = useState('');
  const [descrLength, setDescrLength] = useState('');
  const [isRateFixed, setIsRateFixed] = useState(
    editCheckoutState && editCheckoutState.type?.toLowerCase() === 'saletoken'
      ? editCheckoutState.fixed.toString()
      : 'true'
  );
  const [currentValues, setCurrentValues] = useState('');
  const [openPlanDetails, setOpenPlanDetails] = useState(false);
  const [tradedCurrencyType, setTradedCurrencyType] = useState('');
  const [isInitialWalletFetch, setIsInitialWalletFetch] = useState(true);
  const [isEstimateMaxFetching, setIsEstimateMaxFetching] = useState(false);
  const [isImageFromFile, setIsImageFromFile] = useState(
    isDuplicating && editCheckoutState && editCheckoutState.image ? false : true
  );
  const [isOpenedDropdownCurrency, setIsOpenedDropdownCurrency] = useState(false);
  const [wallets, setWallets] = useState([]);
  const [walletsDefault, setWalletsDefault] = useState('');
  const [loadingFees, setLoadingFees] = useState(false);
  const [formData, setFormData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [isOpenedSaleToken, setIsOpenedSaleToken] = useState(false);
  const [visibleSaleToken, setVisibleSaleToken] = useState(false);
  const [isOpenAutosignModal, setIsOpenAutosignModal] = useState(false);
  const [password, setPassword] = useState('');
  const [sign, setSign] = useState('');
  const [fees, setFees] = useState('');
  const [feesCurrency, setFeesCurrency] = useState('');
  const [form] = Form.useForm();
  const location = useLocation();
  const type = location.pathname === '/recurring-billing' && 'plan';
  const checkType = checkoutType || type || editCheckoutState.type?.toLowerCase();
  const isDonation = checkType === 'donation';
  const { t } = useTranslation();

  const handleValuesChange = (changedValues, allValues) => {
    if (allValues?.owner) {
      form.setFieldsValue({ owner: allValues?.owner });
      setWalletsDefault(allValues?.owner);
    }
    setFormData(allValues);
  };

  useEffect(() => {
    if (merchantId && (walletsDefault || formData?.owner)) {
      checkAutosignStatus(merchantId, walletsDefault || formData?.owner);
    }
  }, [formData?.owner, walletsDefault, merchantId]);

  const handleWallets = value => {
    const filteredSupportedCurrencies = supportedCurrencies?.data?.filter(item => item?._id === value);
    const filteredSupportedWallets = supportedWallets?.data?.filter(
      item => item?.nodeType === filteredSupportedCurrencies[0]?.nodeType
    );
    setWallets(filteredSupportedWallets);
  };

  const debouncedGetFeeEstimatePlan = useRef(
    debounce(async data => {
      try {
        const response = await cryptoApi.planEstimateMax(merchantId, data);
        if (response?.data) {
          setFees(response.data?.fee);
          setFeesCurrency(response.data?.currencyFee);
        }
      } catch (error) {
        setFees('');
        setFeesCurrency('');
        notificationsErrorShow({ message: error.data.message });
      }

      setLoadingFees(false);
    }, 1000)
  );

  useEffect(() => {
    if (editCheckoutState) {
      setFormData(editCheckoutState);
    }
  }, [editCheckoutState]);

  useEffect(() => {
    if (
      formData?.name &&
      formData?.owner &&
      formData?.payeeAddress &&
      formData?.periodType &&
      formData?.price &&
      formData?.currency
    ) {
      setLoadingFees(true);

      if (!editCheckoutState) {
        debouncedGetFeeEstimatePlan.current({
          name: formData?.name,
          owner: formData?.owner,
          payeeAddress: formData?.payeeAddress,
          periodType: formData?.periodType,
          price: formData?.price,
          currency: formData?.currency,
        });
      } else {
        if (editCheckoutState !== formData) {
          debouncedGetFeeEstimatePlan.current({
            name: formData?.name,
            owner: formData?.owner,
            payeeAddress: formData?.payeeAddress,
            periodType: formData?.periodType,
            price: formData?.price,
            currency: formData?.currency,
            planId: formData?._id,
          });
        }
      }
    }
  }, [
    formData?.name,
    formData?.owner,
    formData?.payeeAddress,
    formData?.periodType,
    formData?.price,
    formData?.currency,
  ]);

  useEffect(() => {
    if (wallets && wallets.length > 0) {
      const isDefaultWalletInList = wallets?.some(wallet => wallet?._id === walletsDefault);

      if (!isDefaultWalletInList) {
        const defaultWallet = wallets[0]?._id;
        setWalletsDefault(defaultWallet);
        form.setFieldsValue({ owner: defaultWallet });
        setFormData(prevFormData => ({
          ...prevFormData,
          owner: defaultWallet,
        }));
      }
    } else {
      form.setFieldsValue({ owner: '' });
      setWalletsDefault('');
    }
  }, [wallets, walletsDefault]);

  useEffect(() => {
    if (supportedCurrencies && type === 'plan') {
      handleWallets(formData?.currency || editCheckoutState?.currency || supportedCurrencies?.data[0]?._id);
    }
  }, [formData, supportedCurrencies, supportedWallets]);

  useEffect(() => {
    if (supportedCurrencies) {
      if (editCheckoutState) {
        handleWallets(editCheckoutState?.currency);
      } else if (supportedCurrencies?.data?.length) {
        handleWallets(supportedCurrencies?.data[0]?._id);
      }
    }
  }, [supportedCurrencies, supportedWallets]);

  useEffect(() => {
    merchantWalletsReset();
    return () => merchantWalletsReset();
  }, []);

  useEffect(() => {
    if (editCheckoutState && merchantWallets.length && isInitialWalletFetch) {
      form.setFieldsValue({ tradedWallet: editCheckoutState.tradedWallet });
      setIsInitialWalletFetch(false);
    }
  }, [merchantWallets, editCheckoutState, isInitialWalletFetch]);

  useEffect(() => {
    if (editCheckoutState) {
      setTitleLength(
        editCheckoutState[
          checkType === 'plan'
            ? 'name'
            : isDonation
            ? 'organizationName'
            : checkType === 'cart'
            ? 'cartName'
            : 'productName'
        ]?.length || 0
      );
      setDescrLength(editCheckoutState.description.length || 0);
      checkType === 'saletoken' && getMerchantWalletsByCurrency(editCheckoutState.tradedCurrency);
    }
  }, [editCheckoutState]);

  const setCurrencies = values => {
    form.setFieldsValue({ currencies: values });
  };

  const ethAddressValidator = {
    validator: (_, value) => {
      if (value && utils.isAddress(value)) {
        return Promise.resolve();
      } else {
        return Promise.reject(new Error('Payee Address must be an Ethereum address'));
      }
    },
  };

  const getEstimateMax = async () => {
    setIsEstimateMaxFetching(true);
    try {
      const response = await cryptoApi.getEstimateMax(merchantId, {
        ...form.getFieldsValue(['tradedWallet', 'tradedCurrency']),
        checkoutId: editCheckoutState ? editCheckoutState._id : undefined,
      });

      form.setFieldsValue({ max: response.data.max });
      setIsEstimateMaxFetching(false);
    } catch (error) {
      notificationsErrorShow({ message: error.data.message });
      setIsEstimateMaxFetching(false);
    }
  };

  const truncate = function (fullStr = '', strLen, separator) {
    if (fullStr.length <= strLen) return fullStr;

    separator = separator || '...';

    var sepLen = separator.length,
      charsToShow = strLen - sepLen,
      frontChars = Math.ceil(charsToShow / 2),
      backChars = Math.floor(charsToShow / 2);

    return fullStr.substring(0, frontChars) + separator + fullStr.substring(fullStr.length - backChars);
  };

  const uploadAutosign = data => {
    const { password, sign } = data;
    setPassword(password || undefined);
    setSign(sign || undefined);

    setIsOpenAutosignModal(false);
  };

  const removeValueFromArray = (array, value) => {
    if (!value || !array) return array;

    const index = array.indexOf(value);
    if (index < 0) return array;

    const result = [...array];
    result.splice(index, 1);
    return result;
  };

  const getMerchantWalletsByCurrency = currencyId => {
    const merchantNetwork = merchants.find(merchant => merchant._id === merchantId).typeNetwork;

    merchantWalletsReset();
    const selectedCurrencyType = currencies.find(currency => currency._id === currencyId).currencyType;
    setTradedCurrencyType(selectedCurrencyType);

    getMerchantWallets(merchantId, {
      typeNetwork: merchantNetwork,
      limit: -1,
      currencyIds: `${currencyId}`,
      order: 'DESC',
      positiveBalance: true,
    });
  };

  const CHECKOUT_INITIAL_VALUES = {
    donation: {
      organizationName: editCheckoutState ? editCheckoutState.organizationName : '',
      description: editCheckoutState ? editCheckoutState.description : '',
      currencies: editCheckoutState
        ? editCheckoutState.currencies?.filter(currency =>
            activeMerchantCurrencies.find(merchantCurrency => currency === merchantCurrency)
          )
        : activeMerchantCurrencies,
      image: '',
      linkImage:
        isDuplicating && editCheckoutState && editCheckoutState.image
          ? `${process.env.REACT_APP_API_URL}${editCheckoutState.image}`
          : '',
      expireTime: editCheckoutState ? editCheckoutState.expireTime : 60,
      logoImage: '',
      linkLogoImage:
        isDuplicating && editCheckoutState && editCheckoutState.logoImage
          ? `${process.env.REACT_APP_API_URL}${editCheckoutState.logoImage}`
          : '',
      backgroundColor: editCheckoutState
        ? editCheckoutState.backgroundColor
        : identity === 'clarnium'
        ? '#F5F6FF'
        : '#f1f3f6',
      accentColor: editCheckoutState ? editCheckoutState.accentColor : mainColor,
      linkSite: editCheckoutState ? editCheckoutState.linkSite : '',
    },
    sale: {
      productName: editCheckoutState ? editCheckoutState.productName : '',
      description: editCheckoutState ? editCheckoutState.description : '',
      fiatCurrency: editCheckoutState ? editCheckoutState.fiatCurrency : 'USD',
      price: editCheckoutState ? editCheckoutState.price : '',
      currencies: editCheckoutState
        ? editCheckoutState.currencies?.filter(currency =>
            activeMerchantCurrencies.find(merchantCurrency => currency === merchantCurrency)
          )
        : activeMerchantCurrencies,
      image: '',
      linkImage:
        isDuplicating && editCheckoutState && editCheckoutState.image
          ? `${process.env.REACT_APP_API_URL}${editCheckoutState.image}`
          : '',
      expireTime: editCheckoutState ? editCheckoutState.expireTime : 60,
      logoImage: '',
      linkLogoImage:
        isDuplicating && editCheckoutState && editCheckoutState.logoImage
          ? `${process.env.REACT_APP_API_URL}${editCheckoutState.logoImage}`
          : '',
      backgroundColor: editCheckoutState
        ? editCheckoutState.backgroundColor
        : identity === 'clarnium'
        ? '#F5F6FF'
        : '#f1f3f6',
      accentColor: editCheckoutState ? editCheckoutState.accentColor : mainColor,
      linkSite: editCheckoutState ? editCheckoutState.linkSite : '',
    },
    saletoken: {
      productName: editCheckoutState ? editCheckoutState.productName : '',
      description: editCheckoutState ? editCheckoutState.description : '',
      fiatCurrency: (editCheckoutState && editCheckoutState.fiatCurrency) || 'USD',
      price: editCheckoutState ? editCheckoutState.price : '',
      currencies: editCheckoutState
        ? editCheckoutState.currencies?.filter(currency =>
            activeMerchantCurrencies.find(merchantCurrency => currency === merchantCurrency)
          )
        : [],
      image: '',
      linkImage:
        isDuplicating && editCheckoutState && editCheckoutState.image
          ? `${process.env.REACT_APP_API_URL}${editCheckoutState.image}`
          : '',
      expireTime: editCheckoutState ? editCheckoutState.expireTime : 60,
      min: editCheckoutState ? editCheckoutState.min : '',
      max: editCheckoutState ? editCheckoutState.max : '',
      fixed: editCheckoutState ? `${editCheckoutState.fixed}` : 'true',
      tradedCurrency: editCheckoutState ? editCheckoutState.tradedCurrency : '',
      tradedWallet: undefined,
      logoImage: '',
      linkLogoImage:
        isDuplicating && editCheckoutState && editCheckoutState.logoImage
          ? `${process.env.REACT_APP_API_URL}${editCheckoutState.logoImage}`
          : '',
      backgroundColor: editCheckoutState
        ? editCheckoutState.backgroundColor
        : identity === 'clarnium'
        ? '#F5F6FF'
        : '#f1f3f6',
      accentColor: editCheckoutState ? editCheckoutState.accentColor : mainColor,
      linkSite: editCheckoutState ? editCheckoutState.linkSite : '',
    },
    cart: {
      cartName: editCheckoutState ? editCheckoutState.cartName : '',
      description: editCheckoutState ? editCheckoutState.description : '',
      fiatCurrency: editCheckoutState ? editCheckoutState.fiatCurrency : 'USD',
      currencies: editCheckoutState
        ? editCheckoutState.currencies?.filter(currency =>
            activeMerchantCurrencies.find(merchantCurrency => currency === merchantCurrency)
          )
        : activeMerchantCurrencies,
      image: '',
      linkImage:
        isDuplicating && editCheckoutState && editCheckoutState.image
          ? `${process.env.REACT_APP_API_URL}${editCheckoutState.image}`
          : '',
      expireTime: editCheckoutState ? editCheckoutState.expireTime : 60,
      logoImage: '',
      linkLogoImage:
        isDuplicating && editCheckoutState && editCheckoutState.logoImage
          ? `${process.env.REACT_APP_API_URL}${editCheckoutState.logoImage}`
          : '',
      backgroundColor: editCheckoutState
        ? editCheckoutState.backgroundColor
        : identity === 'clarnium'
        ? '#F5F6FF'
        : '#f1f3f6',
      accentColor: editCheckoutState ? editCheckoutState.accentColor : mainColor,
      linkSite: editCheckoutState ? editCheckoutState.linkSite : '',
    },
    plan: {
      name: editCheckoutState ? editCheckoutState.name : '',
      description: editCheckoutState ? editCheckoutState.description : '',
      payeeAddress: editCheckoutState ? editCheckoutState.payeeAddress : '',
      currency: editCheckoutState
        ? editCheckoutState?.currency
        : supportedCurrencies?.data?.length
        ? supportedCurrencies?.data[0]?._id
        : '',
      price: editCheckoutState ? editCheckoutState.price : '',
      periodType: editCheckoutState ? editCheckoutState.periodType : 1,
      owner: editCheckoutState ? editCheckoutState?.owner : walletsDefault,
      linkImage:
        isDuplicating && editCheckoutState && editCheckoutState.image
          ? `${process.env.REACT_APP_API_URL}${editCheckoutState.image}`
          : '',
      expireTime: editCheckoutState ? editCheckoutState.expireTime : 60,
      logoImage: '',
      linkLogoImage:
        isDuplicating && editCheckoutState && editCheckoutState.logoImage
          ? `${process.env.REACT_APP_API_URL}${editCheckoutState.logoImage}`
          : '',
      backgroundColor: editCheckoutState
        ? editCheckoutState.backgroundColor
        : identity === 'clarnium'
        ? '#F5F6FF'
        : '#f1f3f6',
      accentColor: editCheckoutState ? editCheckoutState.accentColor : mainColor,
    },
  };

  const handleFinish = values => {
    if (checkType !== 'plan') {
      createCheckoutHandler({
        ...values,
        image: isImageFromFile ? values.image : undefined,
        linkImage: !isImageFromFile ? values.linkImage : (isDuplicating && values?.linkImage) || undefined,
        backgroundColor: values.backgroundColor || '#f1f3f6',
        accentColor: values.accentColor || mainColor,
        linkSite: values.linkSite ? values.linkSite : undefined,
      });
    } else {
      setCurrentValues({
        ...values,
        sign: sign,
        password: password,
        image: isImageFromFile ? values.image : undefined,
        linkImage: !isImageFromFile ? values.linkImage : (isDuplicating && values?.linkImage) || undefined,
        backgroundColor: values.backgroundColor || '#f1f3f6',
        accentColor: values.accentColor || mainColor,
        linkSite: values.linkSite ? values.linkSite : undefined,
      });
      setOpenPlanDetails(true);
    }
  };

  const createPlan = type => {
    createCheckoutHandler({
      ...currentValues,
      published: type,
      image: isImageFromFile ? currentValues.image : undefined,
      linkImage: !isImageFromFile ? currentValues.linkImage : (isDuplicating && currentValues?.linkImage) || undefined,
      backgroundColor: currentValues.backgroundColor || '#f1f3f6',
      accentColor: currentValues.accentColor || mainColor,
      linkSite: currentValues.linkSite ? currentValues.linkSite : undefined,
    });

    setOpenPlanDetails(false);
    close();
  };

  const changePlan = type => {
    createCheckoutHandler({
      ...currentValues,
      status: type,
      image: isImageFromFile ? currentValues.image : undefined,
      linkImage: !isImageFromFile ? currentValues.linkImage : (isDuplicating && currentValues?.linkImage) || undefined,
      backgroundColor: currentValues.backgroundColor || '#f1f3f6',
      accentColor: currentValues.accentColor || mainColor,
      linkSite: currentValues.linkSite ? currentValues.linkSite : undefined,
    });

    setOpenPlanDetails(false);
    close();
  };

  return (
    <div
      className="checkouts-form"
      onClick={() => {
        (isOpenedDropdownCurrency && !visible) || (isOpenedSaleToken && !visibleSaleToken)
          ? setIsOpenedDropdownCurrency(false)
          : console.log('nothing to see');
      }}
    >
      <Form
        initialValues={CHECKOUT_INITIAL_VALUES[checkType]}
        form={form}
        autoComplete="off"
        onFinish={values => handleFinish(values)}
        onValuesChange={handleValuesChange}
      >
        {(values, formInstance) => (
          <div className="checkout-set-data">
            <div className="checkout-set-data__name">
              <Form.Item
                name={
                  checkType === 'plan'
                    ? 'name'
                    : isDonation
                    ? 'organizationName'
                    : checkType === 'cart'
                    ? 'cartName'
                    : 'productName'
                }
                rules={[{ required: true, message: t('validation.required') }, whitespaceInputValidator]}
              >
                <Input
                  placeholder={t(`checkouts.steps.2.${[checkType]}.organizationName`)}
                  maxLength={120}
                  onChange={e => setTitleLength(e.target.value.length)}
                />
              </Form.Item>
              <div className="checkout-set-data__chars-limit checkout-set-data__chars-limit_centered">
                {+titleLength}/120
              </div>
            </div>
            <div className="checkout-set-data__description">
              <Form.Item
                name="description"
                rules={
                  type === 'plan' && [{ required: true, message: t('validation.required') }, whitespaceInputValidator]
                }
              >
                <TextArea
                  placeholder={t('description')}
                  maxLength={300}
                  onChange={e => setDescrLength(e.target.value.length)}
                />
              </Form.Item>
              <div className="checkout-set-data__chars-limit">{+descrLength}/300</div>
            </div>
            {/* <div className="checkout-set-data__website"> */}

            {checkType === 'plan' && (
              <div className="checkout-set-data__name">
                <Form.Item
                  name="payeeAddress"
                  rules={[{ required: true, message: t('validation.required') }, ethAddressValidator]}
                >
                  <Input
                    placeholder={t('checkouts.payeeAddress')}
                    maxLength={50}
                    onChange={e => setPayeeAddressLength(e.target.value.length)}
                  />
                </Form.Item>
                <div className="checkout-set-data__chars-limit checkout-set-data__chars-limit_centered">
                  {+payeeAddressLength}/50
                </div>
              </div>
            )}

            {checkType !== 'plan' && (
              <Form.Item
                name="linkSite"
                className="checkout-set-data__website"
                label={
                  <div className="checkout-set-data__website_label">
                    <span>{t('website')}</span>
                    <Tooltip
                      placement="bottomRight"
                      overlayClassName="checkout-set-data__website_label_tooltip"
                      title={t('websiteTooltipInfo')}
                    >
                      <HelpCircleSVG />
                    </Tooltip>
                  </div>
                }
                rules={[
                  {
                    type: 'url',
                    message: t('validUrl'),
                  },
                ]}
              >
                <Input placeholder={t('website')} />
              </Form.Item>
            )}

            {/* </div> */}
            {checkType !== 'plan' && (
              <div className="checkout-set-data__payment-methods">
                <Form.Item
                  name="currencies"
                  valuePropName="checkoutCurrencies"
                  noStyle={true}
                  rules={[{ required: true, message: t('validation.required') }]}
                >
                  <span>{t('checkouts.paymentMethods')}:</span>
                  <div className="checkout-set-data__error-wrap">
                    <CurrencyDropdown
                      setFunc={setCurrencies}
                      multiple={true}
                      allCurrencies={false}
                      individualTokens={true}
                      excludeCurrencies={[formInstance.getFieldValue('tradedCurrency')]}
                      defaultValues={formInstance.getFieldValue('currencies')}
                      activeMerchantCurrencies={activeMerchantCurrencies}
                      isFiltersAvailable={true}
                      isOpenedDropdownCurrency={isOpenedDropdownCurrency}
                      setIsOpenedDropdownCurrency={setIsOpenedDropdownCurrency}
                      visible={visible}
                      setVisible={setVisible}
                    />
                    <span className={`checkout-set-data__select-error checkout-set-data__select-error-${identity}`}>
                      {formInstance.getFieldError('currencies')}
                    </span>
                  </div>
                </Form.Item>
              </div>
            )}
            {checkType === 'saletoken' && (
              <>
                <div className="checkout-set-data__traded-currency">
                  <span>{t('checkouts.receiveMethods')}:</span>
                  <Form.Item
                    name={'tradedCurrency'}
                    valuePropName="checkoutCurrencies"
                    noStyle={true}
                    rules={[{ required: true, message: t('validation.required') }]}
                  >
                    <div className="checkout-set-data__error-wrap">
                      <CurrencyDropdown
                        setFunc={id => {
                          getMerchantWalletsByCurrency(id);
                          form.setFieldsValue({
                            currencies: removeValueFromArray(form.getFieldValue('currencies'), id),
                            tradedCurrency: id,
                            tradedWallet: undefined,
                          });
                        }}
                        allCurrencies={false}
                        individualTokens={true}
                        defaultValues={editCheckoutState ? [form.getFieldValue('tradedCurrency')] : undefined}
                        isOpenedDropdownCurrency={isOpenedSaleToken}
                        setIsOpenedDropdownCurrency={setIsOpenedSaleToken}
                        visible={visibleSaleToken}
                        setVisible={setVisibleSaleToken}
                      />
                      <span className={`checkout-set-data__select-error checkout-set-data__select-error-${identity}`}>
                        {formInstance.getFieldError('currencies')}
                      </span>
                    </div>
                  </Form.Item>
                </div>

                <div className="checkout-set-data__merchant-wallet">
                  <span>{t('checkouts.merchantWallet')}:</span>
                  <div className="checkout-set-data__error-wrap">
                    <Form.Item
                      name={'tradedWallet'}
                      noStyle={true}
                      rules={[{ required: true, message: t('validation.required') }]}
                    >
                      <Select
                        placeholder={t('checkouts.metamask.connect')}
                        suffixIcon={<DropdownIcon />}
                        getPopupContainer={trigger => trigger.parentNode}
                        notFoundContent={t('nodata')}
                      >
                        {tradedCurrencyType === 'currency'
                          ? merchantWallets.map(wallet => (
                              <Select.Option value={wallet._id} key={wallet._id}>
                                <div className="checkout-set-data__merchant-wallet-option">
                                  <div>
                                    <div
                                      className={`checkout-set-data__merchant-wallet-token-name checkout-set-data__merchant-wallet-token-name-${identity}`}
                                    >
                                      <TokenIcon tokenName={wallet.currency} />
                                      {wallet.currency}
                                    </div>
                                    <div className="checkout-set-data__merchant-wallet-balance">
                                      {wallet.balance.value} {wallet.currency}
                                    </div>
                                  </div>
                                  <div>
                                    <div className="checkout-set-data__merchant-wallet-address">
                                      {truncate(wallet.address, 14, '..')}
                                    </div>
                                    <div className="checkout-set-data__merchant-wallet-fiat">
                                      {formatCurrency(wallet.balance.usd)}
                                    </div>
                                  </div>
                                </div>
                              </Select.Option>
                            ))
                          : merchantWallets.map(wallet =>
                              wallet.tokens.length
                                ? wallet.tokens.map(token => (
                                    <Select.Option value={wallet._id} key={token._id}>
                                      <div className="checkout-set-data__merchant-wallet-option">
                                        <div>
                                          <div
                                            className={`checkout-set-data__merchant-wallet-token-name checkout-set-data__merchant-wallet-token-name-${identity}`}
                                          >
                                            <TokenIcon tokenName={token.currency} />
                                            {token.currency}
                                          </div>
                                          <div className="checkout-set-data__merchant-wallet-balance">
                                            {token.balance.value} {token.currency}
                                          </div>
                                        </div>
                                        <div>
                                          <div className="checkout-set-data__merchant-wallet-address">
                                            {truncate(wallet.address, 14, '..')}
                                          </div>
                                          <div className="checkout-set-data__merchant-wallet-fiat">
                                            {formatCurrency(token.balance.usd)}
                                          </div>
                                        </div>
                                      </div>
                                    </Select.Option>
                                  ))
                                : undefined
                            )}
                      </Select>
                    </Form.Item>
                    <span className={`checkout-set-data__select-error checkout-set-data__select-error-${identity}`}>
                      {formInstance.getFieldError('currencies')}
                    </span>
                  </div>
                </div>

                <div className="checkout-set-data__limits">
                  <Form.Item
                    name={'min'}
                    dependencies={['max']}
                    normalize={(value, prevValue) => (!value || +value || +value === 0 ? value : prevValue)}
                    rules={[
                      { required: true, message: t('validation.required') },
                      ({ getFieldValue }) => ({
                        validator: (rule, value) => {
                          if (!value || !getFieldValue('max') || +getFieldValue('max') >= +value) {
                            return Promise.resolve();
                          }

                          return Promise.reject(new Error('Min must be less than or equal to max'));
                        },
                      }),
                    ]}
                    className="checkout-set-data__min"
                  >
                    <Input placeholder={'min'} min={0} />
                  </Form.Item>

                  <Form.Item
                    name={'max'}
                    dependencies={['min']}
                    normalize={(value, prevValue) => (!value || +value || +value === 0 ? value : prevValue)}
                    rules={[
                      { required: true, message: t('validation.required') },
                      ({ getFieldValue }) => ({
                        validator: (rule, value) => {
                          if (!value || !getFieldValue('min') || +getFieldValue('min') <= +value) {
                            return Promise.resolve();
                          }

                          return Promise.reject(new Error(t('checkouts.error')));
                        },
                      }),
                    ]}
                    className="checkout-set-data__max"
                  >
                    <Input placeholder={'max'} min={0} />
                  </Form.Item>
                  <div
                    className={`checkout-set-data__get-max checkout-set-data__get-max-${identity} ${
                      (!formInstance.getFieldValue('tradedCurrency') || !formInstance.getFieldValue('tradedWallet')) &&
                      'checkout-set-data__get-max_disabled'
                    }`}
                    onClick={() =>
                      formInstance.getFieldValue('tradedCurrency') &&
                      formInstance.getFieldValue('tradedWallet') &&
                      getEstimateMax()
                    }
                  >
                    {!isEstimateMaxFetching ? (
                      'MAX'
                    ) : (
                      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                    )}
                  </div>
                </div>

                <div className="checkout-set-data__rate-wrapper">
                  <Form.Item name={'fixed'} className="checkout-set-data__rate">
                    <Radio.Group
                      onChange={e => {
                        setIsRateFixed(e.target.value);
                      }}
                    >
                      <Radio value="true">{t('checkouts.fixed')}</Radio>
                      <Radio value="false">{t('checkouts.market')}</Radio>
                    </Radio.Group>
                  </Form.Item>
                </div>

                {identity !== 'finvaro' && identity !== 'clarnium' && (
                  <a
                    href="https://docs.google.com/forms/d/e/1FAIpQLSe89Dv3UEWij0XdZv1QO68V5RRLe_0QOvp7cgqhwxyPscCGwA/viewform"
                    target="__blank"
                    className={`checkout-set-data__add-token checkout-set-data__add-token-${identity}`}
                  >
                    {t('checkouts.addToken')}
                  </a>
                )}
              </>
            )}
            {((checkType === 'saletoken' && isRateFixed === 'true') ||
              checkType === 'sale' ||
              checkType === 'cart' ||
              checkType === 'plan') && (
              <div className="checkout-set-data__sale">
                {checkType !== 'cart' && (
                  <Form.Item
                    name={'price'}
                    normalize={(value, prevValue) => {
                      const split = `${value}`.split('.');

                      let result;
                      if (!split || !split[1] || split[1].length <= 18) {
                        result = value;
                      } else {
                        result = `${split[0]}.${split[1].substring(0, 18)}`;
                      }

                      return !value || +value || +value === 0 ? result : prevValue;
                    }}
                    rules={[
                      { required: isRateFixed === 'true', message: t('validation.required') },
                      ({ getFieldValue }) => ({
                        validator: (rule, value) => {
                          if (value > 0) {
                            return Promise.resolve();
                          }

                          return Promise.reject(new Error(t('checkouts.priceError')));
                        },
                      }),
                    ]}
                    className="checkout-set-data__number"
                  >
                    <Input placeholder={t('checkouts.checkoutForm.enterPrice')} min={0} />
                  </Form.Item>
                )}

                <Form.Item
                  name={type === 'plan' ? 'currency' : 'fiatCurrency'}
                  className="checkout-set-data__currency"
                  rules={[{ required: true, message: t('validation.required') }]}
                >
                  {checkType === 'plan' ? (
                    <Select getPopupContainer={trigger => trigger.parentNode}>
                      {supportedCurrencies?.data?.map(item => (
                        <Select.Option key={item?._id} value={item?._id}>
                          {item?.title}
                        </Select.Option>
                      ))}
                    </Select>
                  ) : (
                    <Select getPopupContainer={trigger => trigger.parentNode}>
                      <Select.Option value="AED">د.إ AED</Select.Option>
                      <Select.Option value="AUD">A$ AUD</Select.Option>
                      <Select.Option value="BRL">R$ BRL</Select.Option>
                      <Select.Option value="CNY">¥ CNY</Select.Option>
                      <Select.Option value="EUR">€ EUR</Select.Option>
                      <Select.Option value="GBP">£ GBP</Select.Option>
                      <Select.Option value="NGN">₦ NGN</Select.Option>
                      <Select.Option value="RUB">₽ RUB</Select.Option>
                      <Select.Option value="TRY">₺ TRY</Select.Option>
                      <Select.Option value="UAH">₴ UAH</Select.Option>
                      <Select.Option value="USD">$ USD</Select.Option>
                    </Select>
                  )}
                </Form.Item>
              </div>
            )}
            {checkType !== 'plan' && (
              <div className="checkout-set-data__flex">
                <Form.Item
                  name={'expireTime'}
                  className="checkout-set-data__expireTime"
                  label={t('checkouts.timeExpiration')}
                >
                  <Select getPopupContainer={trigger => trigger.parentNode}>
                    <Select.Option value={10}>10 {t('checkouts.minutes')}</Select.Option>
                    <Select.Option value={30}>30 {t('checkouts.minutes')}</Select.Option>
                    <Select.Option value={60}>60 {t('checkouts.minutes')}</Select.Option>
                  </Select>
                </Form.Item>
              </div>
            )}

            {checkType === 'plan' && (
              <div className="checkout-set-data__flex">
                <Form.Item
                  name={'periodType'}
                  className="checkout-set-data__expireTime"
                  label={t('checkouts.periodType')}
                  rules={[{ required: true, message: t('validation.required') }]}
                >
                  <Select getPopupContainer={trigger => trigger.parentNode}>
                    <Select.Option value={1}>Daily</Select.Option>
                    <Select.Option value={2}>Weekly</Select.Option>
                    <Select.Option value={3}>Monthly</Select.Option>
                    <Select.Option value={4}>Yearly</Select.Option>
                  </Select>
                </Form.Item>
              </div>
            )}

            {checkType === 'plan' && (
              <div className="checkout-set-data__flex">
                <Form.Item
                  name={'owner'}
                  className="checkout-set-data__expireTime"
                  label={t('checkouts.owner')}
                  rules={[{ required: true, message: t('validation.required') }]}
                >
                  <Select getPopupContainer={trigger => trigger.parentNode}>
                    {wallets &&
                      wallets?.map(item => (
                        <Select.Option key={item?._id} value={item?._id}>
                          <div className="checkout-set-data__owner">
                            <div className="checkout-set-data__owner--address">
                              <TokenIcon tokenName={item?.currency} />
                              {truncate(item?.address, 14, '..')}
                            </div>
                            <div className="checkout-set-data__owner--balance">
                              <div className="checkout-set-data__owner--balance-crypto">
                                {item?.balance?.value?.length > 11
                                  ? `${item?.balance?.value?.slice(0, 12)}`
                                  : item?.balance?.value}{' '}
                                {item?.currency}
                              </div>
                              <div className="checkout-set-data__owner--balance-usd">
                                {formatCurrency(item?.balance?.usd)}
                              </div>
                            </div>
                          </div>
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </div>
            )}

            {checkType === 'plan' && fees && feesCurrency && (
              <div>
                <div>
                  {loadingFees ? (
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                  ) : (
                    <div className="checkout-set-data__flex--estimate">
                      <div>Publication Estimate:</div>
                      <div>
                        {fees} {feesCurrency}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}

            {!autosignStatus && (
              <div
                className="withdraw__autosign checkout-set-data__flex--autosign"
                onClick={() => setIsOpenAutosignModal(true)}
              >
                <img src={uploadFile} alt="upload" />
                <span>{!sign ? t('withdraw.uploadAutosign') : `${sign.slice(0, 15)}...`}</span>
              </div>
            )}

            <div className="checkout-set-data__image">
              <span>{checkType === 'plan' ? 'Plan' : 'Checkout'} photo:</span>
              <Tabs
                className="checkout-set-data__image-tabs"
                defaultActiveKey={isDuplicating ? '2' : '1'}
                onChange={tabId => (tabId === '1' ? setIsImageFromFile(true) : setIsImageFromFile(false))}
              >
                <Tabs.TabPane tab={t('checkouts.upload')} key="1">
                  <Form.Item name={'image'} className="checkout-set-data__currency">
                    <UploadComponent
                      handleChange={value => {
                        form.setFieldsValue({
                          image: value,
                        });
                      }}
                      image={editCheckoutState && editCheckoutState.image}
                    />
                  </Form.Item>
                </Tabs.TabPane>
                <Tabs.TabPane tab={t('checkouts.specify')} key="2">
                  <Form.Item name="linkImage">
                    <Input placeholder={t('checkouts.specifyLink')} />
                  </Form.Item>
                </Tabs.TabPane>
              </Tabs>
            </div>

            <div className="checkout-set-data__image">
              <span>{checkType === 'plan' ? 'Plan' : 'Checkout'} Logo:</span>
              <Tabs
                className="checkout-set-data__image-tabs"
                defaultActiveKey={isDuplicating ? '2' : '1'}
                onChange={tabId => (tabId === '1' ? setIsImageFromFile(true) : setIsImageFromFile(false))}
              >
                <Tabs.TabPane tab={t('checkouts.upload')} key="1">
                  <Form.Item name={'logoImage'} className="checkout-set-data__currency">
                    <UploadComponent
                      handleChange={value => {
                        form.setFieldsValue({
                          logoImage: value,
                        });
                      }}
                      image={editCheckoutState && editCheckoutState.logoImage}
                    />
                  </Form.Item>
                </Tabs.TabPane>
                <Tabs.TabPane tab={t('checkouts.specify')} key="2">
                  <Form.Item name="linkLogoImage">
                    <Input placeholder={t('checkouts.specifyLink')} />
                  </Form.Item>
                </Tabs.TabPane>
              </Tabs>
            </div>

            <div className="checkout-set-data__color">
              <span>Background color:</span>
              <Form.Item name="backgroundColor" noStyle={true}>
                <input
                  type="color"
                  style={{ all: 'revert' }}
                  defaultValue={editCheckoutState ? editCheckoutState.backgroundColor : '#f1f3f6'}
                />
              </Form.Item>
            </div>

            <div className="checkout-set-data__color">
              <span>Accent color:</span>
              <Form.Item name="accentColor" noStyle={true}>
                <input
                  type="color"
                  style={{ all: 'revert' }}
                  defaultValue={editCheckoutState ? editCheckoutState.accentColor : mainColor}
                />
              </Form.Item>
            </div>

            <div className="checkout-set-data__button-container">
              {prevStep && (
                <Button type="secondary" className="checkouts-list__button" onClick={prevStep}>
                  {t('back')}
                </Button>
              )}
              <Button
                type="primary"
                className="checkouts-list__button"
                htmlType="submit"
                disabled={ifCreateFetching || ifPatchFetching}
                loading={ifCreateFetching || ifPatchFetching}
              >
                {editCheckoutState ? t('save') : checkType === 'plan' ? 'Next' : t('create')}
              </Button>
            </div>
            {checkType === 'plan' && (
              <span className="checkout-set-data__non-revertible">{t('checkouts.plan.0.nonRevertible')}</span>
            )}
          </div>
        )}
      </Form>

      <PlanDetails
        flow="edit"
        createPlan={createPlan}
        changePlan={changePlan}
        supportedWallets={supportedWallets}
        supportedCurrencies={supportedCurrencies}
        currentValues={currentValues}
        editCheckoutState={editCheckoutState}
        visible={openPlanDetails}
        close={() => setOpenPlanDetails(false)}
      />

      <UploadAutosignWrapper
        step={autosignStep}
        uploadAutosign={uploadAutosign}
        onCancel={() => setIsOpenAutosignModal(false)}
        isOpen={isOpenAutosignModal}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  merchantId: state.transactions.merchantId,
  typeNetwork: state.networkFilter,
  merchantWallets: state.merchantWallets.data,
  currencies: state.currencies.data,
  merchants: state.merchants.data,
  autosignStatus: state.withdraw.autosignStatus,
  autosignStep: state.withdraw.autosignStep,
});

const mapDispatchToProps = dispatch => ({
  merchantWalletsReset: () => dispatch(merchantWalletsReset()),
  getMerchantWallets: getMerchantWallets(dispatch),
  notificationsErrorShow: error => dispatch(notificationsErrorShow(error)),
  checkAutosignStatus: checkAutosignStatus(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SetData);
