import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Form } from 'antd';
import Card from '../../components/Card';
import TEXT from '../../components/Text';
import { InputSearch, RangePicker, InputSelect } from '../../components/FormFields';
import UsersList from '../../components/UsersList';
import Pagination from '../../components/Pagination';
import UserInfoModal from '../../components/UserInfoModal';
import AddUserModal from '../../components/AddUserModal';
import { ButtonPrimary } from '../../components/Button';
import { getAdminUsers } from '../../../redux/actions/adminPanel/adminUsers';
import { getAdminUsersEmailsList } from '../../../redux/actions/adminPanel/adminUsersEmailsDownload';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import qs from 'qs';
import moment from 'moment';
import { setAdminFiltersUsers } from '../../../redux/actions/adminPanel/adminPageFilters';
import { scrollContainerIntoView } from '../../helpers/scrollIntoView';
import './style.scss';
import { identity } from '../../../utils/getIdentity';
import { sanitizeSearchInputDisable } from '../../../utils/sanitizeInput';


const Users = ({
  usersList,
  usersCurrentPage,
  totalUsers,
  usersFetching,
  getAdminUsers,
  getAdminUsersEmailsList,
  emailsFetching,
  setAdminFiltersUsers,
  adminFiltersUsers,
  permissions
}) => {
  const [form] = Form.useForm();
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [sort, setSort] = useState(adminFiltersUsers?.sort || undefined);
  const [order, setOrder] = useState(adminFiltersUsers?.order || 'DESC');
  const [formValues, setFormValues] = useState(null);
  const [sortRole, setSortRole] = useState(adminFiltersUsers?.role || undefined);
  const [sortProfile, setSortProfile] = useState(adminFiltersUsers?.active ?? undefined);
  const [selectedOrder, setSelectedOrder] = useState(adminFiltersUsers?.order || undefined);
  const history = useHistory();
  const query = qs.parse(history.location.search, { ignoreQueryPrefix: true });
  let dateParam = undefined;
  if (Object.keys(query).length !== 0 && query.constructor === Object) {
    dateParam = query;
  }
  const adminUsersRef = useRef(null);

  useEffect(() => {
    if (!adminFiltersUsers) {
      getAdminUsers({
        dateOfSignUp: dateParam ? [moment.utc(dateParam?.from), moment.utc(dateParam?.to)] : undefined,
        search: undefined,
      });
      return;
    }

    getAdminUsers({ ...adminFiltersUsers });
  }, []);

  useEffect(() => {
    scrollContainerIntoView(adminUsersRef);
  }, []);
  // scroll to top when you change pagination
  useEffect(() => {
    scrollContainerIntoView(adminUsersRef);
    setAdminFiltersUsers({page: +usersCurrentPage});
  }, [usersCurrentPage]);

  const sortUsersByBalanceRequest = (order, sortBalance) => {
    getAdminUsers({
      ...form.getFieldsValue(true),
      sort: sortBalance,
      page: +usersCurrentPage,
      order,
      role: sortRole,
      active: sortProfile,
    });
    setAdminFiltersUsers({ order: order, sort: sortBalance });
  };

  const sortUsersByRoleRequest = role => {
    getAdminUsers({
      ...form.getFieldsValue(true),
      role: role,
      page: +usersCurrentPage,
      order,
      sort,
      active: sortProfile,
    });
    setAdminFiltersUsers({ role: role });
  };

  const sortUsersByactiveRequest = active => {
    getAdminUsers({
      ...form.getFieldsValue(true),
      active: active,
      role: sortRole,
      page: +usersCurrentPage,
      order,
      sort,
    });
    setAdminFiltersUsers({ active: active });
  };

  const dateOfSignUpDateParams = dateParam
    ? [moment.utc(dateParam.from), moment.utc(dateParam.to)]
    : adminFiltersUsers?.dateOfSignUp || null;

  return (
    <>
      <div className="admin-users" ref={adminUsersRef}>
        <Card>
          <TEXT.pageTitle>Users list</TEXT.pageTitle>
          <Form
            form={form}
            name="admin-users-form"
            initialValues={{
              search: adminFiltersUsers?.search || '',
              dateOfSignUp: dateOfSignUpDateParams,
              dateOfSignIn: adminFiltersUsers?.dateOfSignIn || null,
              emailConfirmed: adminFiltersUsers?.emailConfirmed || undefined,
            }}
            onFieldsChange={() => setFormValues(form.getFieldsValue(true))}
            onFinish={() => {
              getAdminUsers({
                ...form.getFieldsValue(true),
                sort,
                page: 1,
                order,
                role: sortRole,
                active: sortProfile,
              });
              setAdminFiltersUsers({ ...form.getFieldsValue(true) });
            }}
          >
            <div className="admin-users__filters">
              <InputSearch
                name="search"
                onSearch={() => {
                  const searchWords = form?.getFieldsValue()?.search
                  if (searchWords) {
                    !sanitizeSearchInputDisable(searchWords) && localStorage.setItem('searchForUsers', searchWords);
                    !sanitizeSearchInputDisable(searchWords) && form.submit();

                   if (sanitizeSearchInputDisable(searchWords)) {
                    form.resetFields();
                    form.setFieldsValue({ search: '' });
                    form.submit();
                   }
                  }
                }}
                onReset={() => {
                  localStorage.removeItem('searchForUsers');
                  form.resetFields();
                  form.setFieldsValue({ search: '' });
                  form.submit();
                }}
              />
              <RangePicker
                name="dateOfSignUp"
                placeholder="Choose date of registration"
                submitAction={form.submit}
                formValues={formValues}
                withInitial={dateParam || adminFiltersUsers?.dateOfSignUp}
                clearAction={() => {
                  form.setFieldsValue({ dateOfSignUp: '' });
                  setAdminFiltersUsers({ dateOfSignUp: '' });
                }}
              />
              <RangePicker
                name="dateOfSignIn"
                placeholder="Choose date of last login"
                submitAction={form.submit}
                formValues={formValues}
                withInitial={adminFiltersUsers?.dateOfSignIn}
                clearAction={() => {
                  form.setFieldsValue({ dateOfSignIn: '' });
                  setAdminFiltersUsers({ dateOfSignIn: '' });
                }}
              />
              <InputSelect
                name="emailConfirmed"
                dropdownMatchSelectWidth={false}
                placeholder="Choose user email status"
                options={[
                  { value: 'true', label: 'Email confirmed' },
                  { value: 'false', label: 'Email  not confirmed' },
                  { value: null, label: 'All' },
                ]}
                onChange={form.submit}
                style={{
                  width: 180,
                }}
                className="search-email"
              />
              <div className="admin-users__add-user__button-container">
                <ButtonPrimary
                  className="admin-users__add-user-button"
                  onClick={() => setShowAddUserModal(true)}
                  disabled={permissions && permissions?.length > 0 && !permissions[1]?.edit}
                >
                  Add user
                </ButtonPrimary>
                <ButtonPrimary
                  className={`admin-users__add-user-button admin-users__add-user-button__download-emails admin-users__add-user-button__download-emails-${identity}`}
                  onClick={() => {
                    getAdminUsersEmailsList({
                      ...form.getFieldsValue(true),
                      sort,
                      order,
                      role: sortRole,
                      active: sortProfile,
                    });
                  }}
                  disabled={permissions && permissions?.length > 0 && !permissions[1]?.edit}
                >
                  {!emailsFetching ? (
                    'Export'
                  ) : (
                    <div className="admin-users__loader">
                      <Spin indicator={<LoadingOutlined style={{ fontSize: 16 }} spin />} />
                    </div>
                  )}
                </ButtonPrimary>
              </div>
            </div>
            <UsersList
              usersList={usersList}
              permissions={permissions}
              setSelectedUserId={setSelectedUserId}
              usersFetching={usersFetching}
              handleClick={(order, sortBalance) => {
                sortUsersByBalanceRequest(order, sortBalance);
                setSort(sortBalance);
                setOrder(order);
                setSelectedOrder(order);
              }}
              handleClickSort={role => {
                sortUsersByRoleRequest(role);
                setSortRole(role);
              }}
              handleClickProfile={active => {
                sortUsersByactiveRequest(active);
                setSortProfile(active);
              }}
              setSortRole={setSortRole}
              sortRole={sortRole}
              setSortProfile={setSortProfile}
              sortProfile={sortProfile}
              setSortBalance={setSort}
              setSelectedOrder={setSelectedOrder}
              selectedOrder={selectedOrder}
            />
            {usersList.length ? (
              <Pagination
                current={+usersCurrentPage}
                total={totalUsers}
                getEntities={props => getAdminUsers({ ...props, order, sort, role: sortRole, active: sortProfile })}
                getFormValues={form.getFieldsValue}
              />
            ) : null}
          </Form>
        </Card>
      </div>
      <UserInfoModal selectedUserId={selectedUserId} setSelectedUserId={setSelectedUserId} />
      <AddUserModal
        showAddUserModal={showAddUserModal}
        setShowAddUserModal={setShowAddUserModal}
        updateUsersList={() => form.submit()}
      />
    </>
  );
};

const mapStateToProps = state => ({
  usersList: state.adminUsers.entities,
  usersCurrentPage: state.adminUsers.page,
  totalUsers: state.adminUsers.countItems,
  usersFetching: state.adminUsers.fetching,
  emailsFetching: state.adminUsersEmailsDownload.fetching,
  adminFiltersUsers: state.adminFilters.users,
  permissions: state.adminSettings.permissions,
});

const mapDispatchToProps = {
  getAdminUsers,
  getAdminUsersEmailsList,
  setAdminFiltersUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
