import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Modal, Checkbox } from 'antd';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { changeNetworkFilter } from '../../../redux/actions/networkFilter';
import Button from '../../Button';
import './style.scss';
import { identity } from "../../../utils/getIdentity";

const SwapNetworkWarn = ({ networkFilter, changeNetworkFilter }) => {
  const [showModal, setShowModal] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const history = useHistory();
  const {t} = useTranslation();

  useEffect(() => {
    if (!localStorage.getItem('cpayHideSwapWarning') && networkFilter === 'testnet') {
      setShowModal(true);
    }
  }, [networkFilter]);

  return (
    <Modal
      visible={showModal}
      footer={null}
      className={`swap-network-warn swap-network-warn-${identity}`}
      centered={true}
      closable={false}
      maskClosable={false}
    >
      <div className="swap-network-warn__body">
        <div className="swap-network-warn__message">{t('swap.warning')}</div>
        <div className="swap-network-warn__checkbox">
          <Checkbox checked={isChecked} onChange={e => setIsChecked(e.target.checked)}>
            {t('swap.checkbox')}
          </Checkbox>
        </div>
        <div className="swap-network-warn__buttons">
          <Button
            type="secondary"
            onClick={() => {
              history.push('/');
            }}
          >
            {t('swap.backHome')}
          </Button>
          <Button
            onClick={() => {
              isChecked
                ? localStorage.setItem('cpayHideSwapWarning', true)
                : localStorage.removeItem('cpayHideSwapWarning');
              changeNetworkFilter('mainnet');
              setShowModal(false);
            }}
          >
            {t('swap.switchToMainnet')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = state => ({
  networkFilter: state.networkFilter,
});

const mapDispatchToProps = dispatch => ({
  changeNetworkFilter: changeNetworkFilter(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SwapNetworkWarn);
