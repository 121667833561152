import React from "react";
import { useTranslation } from "react-i18next";
import { Checkbox } from "antd";


import "./styles.scss";

const StatusFilter = () => {
  const { t } = useTranslation();

  return (
    <div className="transaction-filters__status">
      <div className="transaction-filters__status-title">
        <p>{t("transactionHistory.status")}</p>
      </div>
      <div className="transaction-filters__status-box">
        <Checkbox>Error</Checkbox>
        <Checkbox>Done</Checkbox>
      </div>
    </div>
  );
};

export default StatusFilter;
