import React from 'react';
import { Row, Col } from 'antd';
import TransactionFilters from './TransactionFilters';
import Transaction from './Transaction';
import { useTranslation } from 'react-i18next';
import TransactionOffer from './TransactionOffer';
import Button from '../Button';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import './style.scss';
import { identity } from '../../utils/getIdentity';

const HomeTransactions = ({
  currencyId,
  merchantBalances,
  transactions,
  transactionsFetching,
  moreTransactionsFetching,
  currencies,
  networkFilter,
  countItem,
  page,
  setPage,
  search,
  setSearch,
  order,
  setOrder,
  type,
  setType,
  submitSearch,
  resetFilters,
  downloadTransactionsInfo,
  merchantId,
  transInfoFetching,
  onlyNft,
  setOnlyNft,
  nftType,
  setNftType,
  setHideNft,
  hideNft,
  limit,
  setTime,
  getTransactions,
}) => {
  const { t } = useTranslation();

  return (
    <div className="transactions">
      <TransactionFilters
        search={search}
        setSearch={setSearch}
        order={order}
        setOrder={setOrder}
        type={type}
        setTime={setTime}
        setType={setType}
        submitSearch={submitSearch}
        resetFilters={resetFilters}
        downloadTransactionsInfo={downloadTransactionsInfo}
        merchantId={merchantId}
        networkFilter={networkFilter}
        transInfoFetching={transInfoFetching}
        onlyNft={onlyNft}
        setOnlyNft={setOnlyNft}
        hideNft={hideNft}
        setHideNft={setHideNft}
        nftType={nftType}
        setNftType={setNftType}
        fromPage={'home'}
      />
      {!transactionsFetching ? (
        <>
          {transactions.length ? (
            <Row className="transactions__legend">
              <Col span={1}>{t('type')}</Col>
              <Col span={7} offset={6}>
                {t('dataTime')}
              </Col>
              <Col span={2}>{t('status')}</Col>
              <Col span={8} className="transactions__legend-amount">
                {t('amount')}
              </Col>
            </Row>
          ) : null}
          <div className="transactions-list">
            {transactions.length ? (
              transactions.map(transaction => (
                <Transaction
                  key={transaction._id}
                  transaction={transaction}
                  currencies={currencies}
                  networkFilter={networkFilter}
                />
              ))
            ) : (
              <TransactionOffer currencyId={currencyId} merchantBalances={merchantBalances} />
            )}
          </div>
          {transactions.length && transactions.length < countItem ? (
            <>
              <Button
                type="primary"
                className={`transactions__load-more transactions__load-more-${identity}`}
                loading={moreTransactionsFetching}
                onClick={() => !moreTransactionsFetching && setPage(page + 1)}
              >
                {t('homePage.transactions.loadMore')}
              </Button>
            </>
          ) : null}
        </>
      ) : (
        <div className="transactions__loader">
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        </div>
      )}
    </div>
  );
};

export default HomeTransactions;
