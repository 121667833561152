import React, { useState } from 'react';
import './style.scss';
import TEXT from "../../adminPanel/components/Text";
import moment from "moment";
import { Pagination, Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import { identity } from "../../utils/getIdentity";

const LoginHistoryList = ({ loginHistory, limit, getUserLoginHistory, userId }) => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);

  const getLoginHistory = (page) => {
    setCurrentPage(page)
    getUserLoginHistory(userId, { page: page })
  }

  return (
    <div className="admin-history">
      <div className="admin-history__row">
        <div className="admin-history__row--item">
          <TEXT.grayBold>IP</TEXT.grayBold>
        </div>
        <div className="admin-history__row--item">
          <TEXT.grayBold>User Agent</TEXT.grayBold>
        </div>
        <div className="admin-history__row--item">
          <TEXT.grayBold>Date of last login</TEXT.grayBold>
        </div>
      </div>

      {loginHistory?.data && loginHistory?.data?.map((item) => (
        <div className="admin-history__row admin-history__row--data" key={item?.id}>
          <div className="admin-history__row--item admin-history__row--data--item">{item?.ip}</div>
          <div className="admin-history__row--item">{item?.userAgent}</div>
          <div className="admin-history__row--item">{moment(item?.createdAt).format('DD MMM YYYY, HH:mm')}</div>
        </div>
      ))}

      {loginHistory?.pages && loginHistory?.pages > 1 && (
        <div className="login-pagination">
          <Row align="middle">
            <Col span={20} className={`login-pagination__wrapper login-pagination__wrapper-${identity}`}>
              <Pagination
                current={currentPage}
                total={loginHistory?.countItem}
                pageSize={20}
                showTotal={false}
                showSizeChanger={false}
                size="small"
                onChange={(page) => getLoginHistory(page)}
              />
            </Col>
          </Row>
        </div>
      )}
    </div>
  )
};

export default LoginHistoryList;