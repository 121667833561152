import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';
import { ReactComponent as CloseSVG } from '../../../img/default-svg/close-black.svg';
import noCheckoutImgSVG from '../../../img/default-svg/no-checkout-image.svg';
import './style.scss';
import { truncate } from "../../../utils/truncateFunction";
import TokenIcon from "../../TokenIcon";
import formatCurrency from "../../../utils/currencyFormatter";
import Button from "../../Button";
import { identity } from "../../../utils/getIdentity";
import DeactivatePlan from "./DeactivatePlan";

const PlanDetails = ({
  visible,
  close,
  currentValues,
  supportedCurrencies,
  supportedWallets,
  createPlan,
  editCheckoutState,
  changePlan,
  flow = 'edit',
  publishPlanChanges
}) => {
  const [currency, setCurrency] = useState('');
  const [currencyIcon, setCurrencyIcon] = useState('');
  const [owner, setOwner] = useState('');
  const [isOpenDeactivate, setIsOpenDeactivate] = useState(false);

  useEffect(() => {
    if (supportedCurrencies?.data && currentValues) {
      const matchedCurrency = supportedCurrencies.data.find((item) => item?._id === currentValues?.currency);

      if (matchedCurrency) {
        setCurrency(matchedCurrency?.title);
        setCurrencyIcon(matchedCurrency?.name);
      }
    }

    if (supportedWallets?.data && currentValues) {
      const matchedOwner = supportedWallets.data.find((item) => item?._id === currentValues?.owner);

      if (matchedOwner) {
        setOwner(matchedOwner);
      }
    }
  }, [currentValues]);

  const updatePlan = (status) => {
    if (!status) {
      changePlan(!status)
    } else {
      setIsOpenDeactivate(true)
    }
  }

  return (
    <Modal
      width={620}
      title=""
      visible={visible}
      onCancel={close}
      footer={null}
      className={`checkout-modal checkout-modal-${identity}`}
      centered
      closeIcon={<CloseSVG />}
      destroyOnClose
      maskClosable={false}
    >
      <div className="checkout-modal-content">
        {editCheckoutState && flow === 'edit' ? (
          <div className="checkout-modal-content__titlePlan">
            Do you want to stop subscription?
          </div>
        ) : (
          <div className="checkout-modal-content__titlePlan">
            Do you want to publish this plan?
          </div>
        )}

        {editCheckoutState && flow === 'edit' ? (
          <div className="checkout-modal-content__subtitle">
            If you stop it, you can always publish it again.
          </div>
        ) : (
          <div className="checkout-modal-content__subtitle">
            If you publish it, you can always stop subscription.
          </div>
        )}

        <div className="checkout-modal-content__body">
          <div className="checkout-modal-content__body--line">
          <div className="checkout-modal-content__body--title">Plan name:</div>
            <div className="checkout-modal-content__body--value">{currentValues?.name || '-'}</div>
          </div>
          <div className="checkout-modal-content__body--line">
            <div className="checkout-modal-content__body--title">Description:</div>
            <div className="checkout-modal-content__body--value">{currentValues?.description || '-'}</div>
          </div>
          <div className="checkout-modal-content__body--line">
            <div className="checkout-modal-content__body--title">Payee Address:</div>
            <div className="checkout-modal-content__body--value">{truncate(currentValues?.payeeAddress, 14, '..') || '-'}</div>
          </div>
          <div className="checkout-modal-content__body--line">
            <div className="checkout-modal-content__body--title">Price:</div>
            <div className="checkout-modal-content__body--value">
              {currentValues?.price || '-'}
              {' '}
              {currencyIcon && <TokenIcon tokenName={currencyIcon} />}
              {' '}
              {currency || '-'}
            </div>
          </div>
          <div className="checkout-modal-content__body--line">
            <div className="checkout-modal-content__body--title">Owner:</div>
            <div className="checkout-modal-content__body--value">
              <div className="checkout-modal-content__body__owner">
                <div className="checkout-modal-content__body__owner--address">
                  {owner?.currency && <TokenIcon tokenName={owner?.currency} />}
                  {truncate(owner?.address, 14, '..')}
                </div>
                <div className="checkout-modal-content__body__owner--balance">
                  <div className="checkout-modal-content__body__owner--balance-crypto">
                    {owner?.balance?.value?.length > 11 ? `${owner?.balance?.value?.slice(0, 12)}` : owner?.balance?.value}
                    {' '}
                    {owner?.currency}
                  </div>
                  <div className="checkout-modal-content__body__owner--balance-usd">{formatCurrency(owner?.balance?.usd)}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="checkout-modal-content__body--br"></div>
          <div className="checkout-modal-content__body--line">
            <div className="checkout-modal-content__body--title">Plan photo:</div>
            <div className="checkout-modal-content__body--image">
              <img
                src={currentValues?.image && typeof currentValues?.image === 'string' ? `${process.env.REACT_APP_API_URL}${currentValues?.image}` : currentValues?.image && typeof currentValues?.image !== 'string' ? URL.createObjectURL(currentValues?.image) : noCheckoutImgSVG}
                alt="" />
            </div>
          </div>
          <div className="checkout-modal-content__body--line">
            <div className="checkout-modal-content__body--title">Plan logo:</div>
            <div className="checkout-modal-content__body--image">
              <img
                src={currentValues?.logoImage && typeof currentValues?.logoImage === 'string' ? `${process.env.REACT_APP_API_URL}${currentValues?.logoImage}` : currentValues?.logoImage && typeof currentValues?.logoImage !== 'string' ? URL.createObjectURL(currentValues?.logoImage) : noCheckoutImgSVG}
                alt="" />
            </div>
          </div>
          <div className="checkout-modal-content__body--line">
            <div className="checkout-modal-content__body--title">Background color:</div>
            <div style={{
              backgroundColor: currentValues?.backgroundColor,
              width: "50px",
              height: "24px",
              borderRadius: "6px"
            }}></div>
          </div>
          <div className="checkout-modal-content__body--line">
          <div className="checkout-modal-content__body--title">Accent color:</div>
            <div style={{ backgroundColor: currentValues?.accentColor, width: '50px', height: '24px', borderRadius: '6px' }} ></div>
          </div>

          {!editCheckoutState && flow === 'edit' && (
            <div className="checkout-modal-content__body--button">
              <Button onClick={() => createPlan(false)} type="secondary">Save As Draft</Button>
              <Button onClick={() => createPlan(true)} type="primary">Publish</Button>
            </div>
          )}

          {editCheckoutState && flow === 'publish' && (
            <div className="checkout-modal-content__body--button">
              <Button onClick={close} type="secondary">Save As Draft</Button>
              <Button onClick={() => publishPlanChanges(editCheckoutState?._id)} type="primary">Publish</Button>
            </div>
          )}

          {editCheckoutState && flow === 'edit' && (
            <div className="checkout-modal-content__body--button">
              <Button
                onClick={() => updatePlan(editCheckoutState?.status)}
                className={`${editCheckoutState?.status ? 'checkout-modal-content__body--button-deactivate' : 'checkout-modal-content__body--button-activate'}`}
              >
                {editCheckoutState?.status ? 'Deactivate Plan' : 'Activate Plan'}
              </Button>
              <Button onClick={() => changePlan(editCheckoutState?.status)} type="primary">Update</Button>
            </div>
          )}
        </div>
      </div>

      <DeactivatePlan changePlan={changePlan} currentValues={editCheckoutState} visible={isOpenDeactivate} close={() => setIsOpenDeactivate(false)} />
    </Modal>
  )
};

export default PlanDetails;