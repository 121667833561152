import React, { useState, useRef } from 'react';
// Components
import emptyCpayImage from '../../../img/cpay-svg/empty.svg';
import emptyNFGImage from '../../../img/nfgpay-svg/empty-nfg.svg';
import emptyFinvaroImage from '../../../img/finvaro/empty-finvaro.svg';
import emptyClarniumImage from '../../../img/clarnium/empty-clarnium.svg';
import noCheckoutImgSVG from '../../../img/default-svg/no-checkout-image.svg';
import moreSVG from '../../../img/default-svg/more.svg';
import transactionsSVG from '../../../img/default-svg/checkoutTransactions.svg';
import editSVG from '../../../img/default-svg/edit.svg';
import deleteSVG from '../../../img/default-svg/trash.svg';
import duplicateSVG from '../../../img/default-svg/duplicate.svg';
import Button from '../../Button';
import { Menu, Dropdown, Modal, Row, Col } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import DeletePlan from '../CheckoutsModals/DeletePlan';
// Utils
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// Style
import '../CheckoutsList/style.scss';
//icons
import donationIcon from '../../../img/checkout-types/donation.svg';
import p2pIcon from '../../../img/checkout-types/p2p.svg';
import sellIcon from '../../../img/checkout-types/sell.svg';

import { identity } from '../../../utils/getIdentity';

const checkoutsNoDataSVG = {
  cpay: emptyCpayImage,
  nfg: emptyNFGImage,
  finvaro: emptyFinvaroImage,
  clarnium: emptyClarniumImage,
};

const { confirm } = Modal;

function showPromiseConfirm(t, name, type, action, merchantId, checkoutId, page) {
  confirm({
    title: `${t('checkouts.deleteTitle')} ${t(type.toLowerCase())}?`,
    icon: <ExclamationCircleOutlined />,
    content: name,
    onOk() {
      return action({ merchantId, checkoutId }, page);
    },
    onCancel() {},
    okText: t('okButton'),
    cancelText: t('cancelButton'),
  });
}

const iconObj = {
  donation: donationIcon,
  sale: sellIcon,
  saletoken: p2pIcon,
  cart: sellIcon,
};

const PlansList = ({
  data,
  page,
  type,
  fetching,
  setIsVisible,
  deleteCheckout,
  merchantId,
  openDetailedHandler,
  openEditHandler,
  openDuplicateHandler,
  deletePlan,
  typeNetwork,
  getPlansList,
  supportedCurrencies,
  openPlanDetails,
}) => {
  const [showAddressCopiedPopover, setShowAddressCopiedPopover] = useState(false);
  const [isOpenDeletePlan, setIsOpenDeletePlan] = useState(false);
  const [currency, setCurrency] = useState('');
  const [currentId, setCurrentId] = useState('');
  const { t } = useTranslation();
  const addressCopyPopoverTimer = useRef(null);

  const location = window.location.href;
  const checkoutLinkCPay =
    location.includes('demo') || location.includes('localhost')
      ? 'https://checkouts-demo.cpay.world/'
      : 'https://checkouts.cpay.world/';
  const checkoutLinkNFG = 'https://checkouts.nfg-crypto.io/';
  const checkoutLinkFinvaro = 'https://checkouts.finvaro.com/';
  const checkoutLinkClarnium = 'https://checkouts.clarnium.io/';

  const checkoutLink = {
    cpay: checkoutLinkCPay,
    nfg: checkoutLinkNFG,
    finvaro: checkoutLinkFinvaro,
    clarnium: checkoutLinkClarnium,
  };

  const openPublish = (el, action) => {
    openEditHandler(el, action);
    openPlanDetails();
  }

  // const checkoutSaleLink = {
  //   cpay: 'sell-token-checkout',
  //   nfg: 'sale-token-checkout'
  //  }

  const periodType = {
    0: 'Hourly',
    1: 'Daily',
    2: 'Weekly',
    3: 'Monthly',
    4: 'Yearly',
  };

  const openDeleteModal = id => {
    setIsOpenDeletePlan(true);
    setCurrentId(id);
  };

  const addressCopyHandler = (identifier, type) => {
    navigator.clipboard.writeText(
      `${checkoutLink[identity]}${type === 'saletoken' ? 'sell-token-checkout' : 'checkout'}/${identifier}`
    );
    setShowAddressCopiedPopover(true);
    addressCopyPopoverTimer.current = setTimeout(() => setShowAddressCopiedPopover(false), 1000);
  };

  const removePlan = async () => {
    await deletePlan(page, currentId, merchantId, typeNetwork);

    setIsOpenDeletePlan(false);
    await getPlansList({
      merchantId,
      params: {
        typeNetwork,
        page,
        limit: 5,
        order: 'DESC'
      },
    });
  };

  return (
    <div className="checkouts-list">
      {fetching ? (
        <div>{t('swap.loading')}...</div>
      ) : data && data.length !== 0 ? (
        <div>
          <Row className="plans">
            <Col span={5}>Name</Col>
            <Col className="plans__col" span={4}>
              Subscribers (Active/All)
            </Col>
            <Col className="plans__col" span={3}>
              Status
            </Col>
            <Col className="plans__col" span={3}>
              Period Type
            </Col>
            <Col className="plans__col" span={4}>
              Price
            </Col>
            <Col className="plans__col" span={4}>
              Total
            </Col>
          </Row>
          {data.map(el => (
            <div key={el._id} className="plans__not checkouts-list__plan ">
              <Row className="plans__not checkouts-list__plan__plans">
                <Col
                  className="plans__cursor"
                  span={5}
                  onClick={() => {
                    openDetailedHandler(el, true);
                  }}
                >
                  <div className="checkouts-list__plan__plans--row">
                    <img src={el.image ? `${process.env.REACT_APP_API_URL}${el.image}` : noCheckoutImgSVG} alt="" />
                    <div>
                      <div className="checkouts-list__checkout-info-name">
                        {el.organizationName || el.productName || el.cartName || el.name}
                      </div>
                      <div className="checkouts-list__checkout-info-description">{el.description}</div>
                    </div>
                  </div>
                </Col>
                <Col
                  onClick={() => {
                    openDetailedHandler(el, true);
                  }}
                  className="plans__col plans__cursor"
                  span={4}
                >
                  <span>
                    {el?.activeSubscriptions}/{el?.subscriptionsTotal}
                  </span>
                </Col>
                <Col
                  onClick={() => {
                    openDetailedHandler(el, true);
                  }}
                  className="plans__col plans__col--dot plans__cursor"
                  span={3}
                >
                  <span
                    className={`checkouts-list__checkout-price--block ${
                      el?.published === false
                        ? 'checkouts-list__checkout-price--block--draft'
                        : el?.published === true && el?.status === false
                        ? 'checkouts-list__checkout-price--block--deactive'
                        : 'checkouts-list__checkout-price--block--active'
                    }`}
                  ></span>
                  <span
                    className={
                      el?.published === false
                        ? 'checkouts-list__checkout-price--draft'
                        : el?.published === true && el?.status === false
                        ? 'checkouts-list__checkout-price--deactive'
                        : 'checkouts-list__checkout-price--active'
                    }
                  >
                    {el?.published === false
                      ? 'Draft'
                      : el?.published === true && el?.status === false
                      ? 'Deactive'
                      : 'Active'}
                  </span>
                </Col>
                <Col
                  onClick={() => {
                    openDetailedHandler(el, true);
                  }}
                  className="plans__col plans__cursor"
                  span={3}
                >
                  <span>{periodType[el?.periodType]}</span>
                </Col>
                <Col
                  onClick={() => {
                    openDetailedHandler(el, true);
                  }}
                  className="plans__col plans__cursor"
                  span={4}
                >
                  <span>
                    {el?.price} {supportedCurrencies?.data?.find(item => item?._id === el?.currency)?.title}
                  </span>
                </Col>
                <Col
                  onClick={() => {
                    openDetailedHandler(el, true);
                  }}
                  className="plans__col plans__cursor"
                  span={4}
                >
                  <span>{el?.totalAmount}$</span>
                </Col>
                <Dropdown
                  placement="bottomLeft"
                  overlay={
                    <Menu>
                      {type !== 'plan' && (
                        <Menu.Item key="0">
                          <Link to={`/?checkoutId=${el.identifier}`} className="checkouts-list__checkout-drop-item">
                            <div className="checkouts-list__checkout-drop-item">
                              <img src={transactionsSVG} alt="" />
                              {t('transactions')}
                            </div>
                          </Link>
                        </Menu.Item>
                      )}
                      {type === 'plan' && (
                        <Menu.Item key="0">
                          <Link to={`/subscriptions/?planId=${el._id}`} className="checkouts-list__checkout-drop-item">
                            <div className="checkouts-list__checkout-drop-item">
                              <img src={transactionsSVG} alt="" />
                              Subscriptions
                            </div>
                          </Link>
                        </Menu.Item>
                      )}
                      <Menu.Item key="0">
                        <Link to={`/activities/?planId=${el._id}`} className="checkouts-list__checkout-drop-item">
                          <div className="checkouts-list__checkout-drop-item">
                            <img src={transactionsSVG} alt="" />
                            Activities
                          </div>
                        </Link>
                      </Menu.Item>
                      <Menu.Item key="3">
                        <div className="checkouts-list__checkout-drop-item" onClick={() => openDuplicateHandler(el)}>
                          <img src={duplicateSVG} alt="" />
                          {t('duplicate')}
                        </div>
                      </Menu.Item>
                      {!el?.published && (
                          <Menu.Item key="4">
                            <div className="checkouts-list__checkout-drop-item" onClick={() => openPublish(el, 'publish')}>
                              <img src={editSVG} alt="" />
                              Public
                            </div>
                          </Menu.Item>
                        )}
                      <Menu.Item key="5">
                        <div className="checkouts-list__checkout-drop-item" onClick={() => openEditHandler(el)}>
                          <img src={editSVG} alt="" />
                          {t('edit')}
                        </div>
                      </Menu.Item>
                      <Menu.Item key="6">
                        <div className="checkouts-list__checkout-drop-item" onClick={() => openDeleteModal(el?._id)}>
                          <img src={deleteSVG} alt="" /> {t('delete')}
                        </div>
                      </Menu.Item>
                    </Menu>
                  }
                  trigger={['click']}
                >
                  <div className="checkouts-list__checkout-actions">
                    <img src={moreSVG} alt="" />
                  </div>
                </Dropdown>
              </Row>
            </div>
          ))}
        </div>
      ) : (
        <div className="checkouts-list__empty">
          <div className="checkouts-list__empty-text">
            {type === 'plan' ? t('checkouts.noPlans') : t('checkouts.noCheckouts')}
          </div>
          <img src={checkoutsNoDataSVG[identity]} alt="" />
          <Button type="primary" className="checkouts-list__button" onClick={() => setIsVisible(true)}>
            {type === 'plan' ? t('checkouts.createPlan') : t('checkouts.createCheckout')}
          </Button>
        </div>
      )}

      <DeletePlan removePlan={removePlan} visible={isOpenDeletePlan} close={() => setIsOpenDeletePlan(false)} />
    </div>
  );
};

export default PlansList;
