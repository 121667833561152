import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { RangePicker } from '../../../components/FormFields';
import { Form } from 'antd';
import { ButtonPrimary } from '../../../components/Button';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { getMarketingInfo } from '../../../../redux/actions/adminPanel/adminMarketingInfoDownload';
import { identity } from '../../../../utils/getIdentity';

const DashboardHeader = ({ getMarketingInfo, marketingInfoFetching, changeChosenDate, permissions }) => {
  const [form] = Form.useForm();
  const [dateForFiltering, setDateForFiltering] = useState([]);
  const [dateForFilterInfo, setDateForFilterInfo] = useState([]);
  const [error, setError] = useState(null);

  const handleFormSubmit = () => {
    dateForFiltering.length === 0 || dateForFiltering?.length < 2
      ? setError(true)
      : getMarketingInfo({ dateForFiltering: dateForFiltering});

    setDateForFiltering([]);
  };

  useEffect(() => {
    if (error) {
      setTimeout(() => setError(null), 2000);
    }
  }, [error]);

  useEffect(() => {
    changeChosenDate(dateForFilterInfo)
  }, [dateForFilterInfo])

  return (
    <div className="admin-marketing-container">
      <Form
        form={form}
        name="admin-marketing-form"
        initialValues={{
          dateForFiltering: [],
          dateForFilterInfo: [],
        }}
        onFieldsChange={() => {
          setDateForFiltering(form.getFieldValue('dateForFiltering'));
          setDateForFilterInfo(form.getFieldValue('dateForFilterInfo'));
        }}
        className="admin-marketing-form"
      >
        <div className="admin-marketing-container-filter">
          <p className="admin-marketing-container-filter-text">Filter by:</p>
          <RangePicker
            name="dateForFilterInfo"
            placeholder="Choose date for filtering"
            submitAction={form.submit}
            formValues={form.getFieldValue('dateForFilterInfo')}
            withInitial={undefined}
            clearAction={() => setDateForFilterInfo([])}
          />
        </div>

        <div className="admin-marketing-container-filter">
          <RangePicker
            name="dateForFiltering"
            placeholder="Choose date for filtering"
            submitAction={form.submit}
            formValues={form.getFieldValue('dateForFiltering')}
            withInitial={undefined}
          />
          {error && (
            <div className="admin-marketing-container-filter-error">
              <p>Please, insert the dates first</p>
            </div>
          )}
          <ButtonPrimary
            className={`admin-users__add-user-button admin-users__add-user-button__download-emails admin-users__add-user-button__download-emails-${identity}`}
            type="submit"
            htmlType="submit"
            onClick={handleFormSubmit}
            disabled={permissions && permissions?.length > 0  && !permissions[0]?.edit}
          >
            {!marketingInfoFetching ? (
              'Download stats'
            ) : (
              <div className="admin-users__loader">
                <Spin indicator={<LoadingOutlined style={{ fontSize: 16 }} spin />} />
              </div>
            )}
          </ButtonPrimary>
        </div>
      </Form>
    </div>
  );
};

const mapStateToProps = state => ({
  marketingInfoFetching: state.adminMarketingInfoDownload.fetching,
  permissions: state.adminSettings.permissions,
});

const mapDispatchToProps = {
  getMarketingInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardHeader);
