import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Modal, Form, Input, InputNumber, Radio, Select, Popover } from "antd";
import Button from '../Button';
import '../../layout/Modal.scss';
import { useTranslation } from 'react-i18next';
import { createMerchant } from '../../redux/actions/merchants';
import CurrencyDropdown from '../CurrencyDropdown';
import './MerchantCreateModal.scss';
import { identity } from '../../utils/getIdentity';
import helpCircleIcon from "../../img/default-svg/help-circle.svg";
import helpCircleActiveIcon from "../../img/default-svg/help-circle-blue.svg";

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const MerchantCreateModal = ({
  isFetching,
  createMerchant,
  isCreateMerchantModalVisible,
  setIsCreateMerchantModalVisible,
  currencies
}) => {
  const [merchantName, setMerchantName] = useState('');
  const [merchantCurrencies, setMerchantCurrencies] = useState([]);
  const [merchantComissionPays, setMerchantComissionPays] = useState('merchant');
  const [merchantComissionPaysReplenish, setMerchantComissionPaysReplenish] = useState('merchant');
  const [merchantFee, setMerchantFee] = useState('');
  const [merchantFeeReplenish, setMerchantFeeReplenish] = useState('');
  const [merchantNetwork, setMerchantNetwork] = useState('testnet');
  const [isOpenedDropdownCurrency, setIsOpenedDropdownCurrency] = useState(false);
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();

  const handleOk = e => {
    createMerchant({
      name: merchantName.length ? merchantName : 'Account',
      currencies: merchantCurrencies,
      fee: merchantFee >= 0 ? +merchantFee : 1,
      feeReplenish: merchantFeeReplenish >= 0 ? +merchantFeeReplenish : 1,
      typeNetwork: merchantNetwork,
      middleWallet: false,
      payerCommission: merchantComissionPays ?? 'merchant',
      payerCommissionReplenish: merchantComissionPaysReplenish ?? 'merchant',
    }).then(() => {
      handleCancel();
    });
  };

  const handleCancel = e => {
    setMerchantName('');
    setMerchantCurrencies([]);
    setMerchantComissionPays('merchant');
    setMerchantComissionPaysReplenish('merchant');
    setMerchantFee('');
    setMerchantFeeReplenish('');
    setMerchantNetwork('testnet');
    setIsCreateMerchantModalVisible(false);
    setIsOpenedDropdownCurrency(false)
    setVisible(false);
  };

  const currenciesIds = currencies.map(({_id}) => _id)

  return (
    <Modal
      title={t('merchants.createMerchant')}
      visible={isCreateMerchantModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      centered={true}
      className={`modal modal-${identity}`}
      destroyOnClose={true}
    >
      <div
      className='modal-merchants'
      onClick={() => {isOpenedDropdownCurrency && !visible ? setIsOpenedDropdownCurrency(false) : console.log('nothing to see')}}
      >
      <Form
        {...layout}
        name="basic"
        initialValues={{
          merchantNetwork: 'testnet',
          merchantComission: 'buyer',
          merchantComissionPays: 'merchant',
          merchantComissionReplenish: 'merchant',
        }}
        className="modal-form form-create-merchant"
      >
        <Form.Item label={t('merchants.merchantName')} name="merchantName" className="modal-form__label" onClick={() => setIsOpenedDropdownCurrency(false)}>
          <Input
            placeholder={t('merchants.merchantName')}
            className="modal-form__input"
            maxLength={30}
            onChange={e => {
              if (e.target.value.length > 30) {
                return false;
              } else {
                setMerchantName(e.target.value.trim());
              }
            }}
          />
          <div className="modal-form__chars-limit">{merchantName.length}/30</div>
        </Form.Item>

        <Form.Item
          label={t('merchants.chooseMerchantCurrency')}
          name="merchantCurrencies"
          className="modal-form__label"
        >
          <CurrencyDropdown
          isOpenedDropdownCurrency={isOpenedDropdownCurrency}
          setIsOpenedDropdownCurrency={setIsOpenedDropdownCurrency}
          visible={visible}
          setVisible={setVisible}
          setFunc={setMerchantCurrencies}
          multiple={true}
          isFiltersAvailable={true}
          activeMerchantCurrencies={currenciesIds} />
        </Form.Item>

        {/* withdrawal */}
        <Form.Item
          label={
            <div>
              {t('merchants.whoPaysComission')}
              <Popover
                overlayClassName="popover__wrapper"
                className="modal-merchants__popover"
                placement="rightTop"
                content={<p className="password-strength__popover-text">{t('merchants.whoPaysComissionTooltip')}</p>}
              >
                <img className="password-strength__popover-icon" src={helpCircleIcon} alt="Help popup" />
                <img className="password-strength__popover-icon_active" src={helpCircleActiveIcon} alt="Help popup" />
              </Popover>
            </div>
          }
          name="merchantComissionPays"
          className="modal-form__label"
          onClick={() => setIsOpenedDropdownCurrency(false)}
        >
          <Radio.Group className="modal-form__radio" onChange={e => setMerchantComissionPays(e.target.value)}>
            <Radio checked={merchantComissionPays === 'buyer'} value="buyer">
              {t('merchants.customer')}
            </Radio>
            <Radio checked={merchantComissionPays === 'merchant'} value="merchant">
              {t('merchants.me')}
            </Radio>
          </Radio.Group>
        </Form.Item>

        {/* replenish */}
        <Form.Item
          label={t('merchants.whoPaysComissionReplenish')}
          name="merchantComissionReplenish"
          className="modal-form__label"
          onClick={() => setIsOpenedDropdownCurrency(false)}
        >
          <Radio.Group className="modal-form__radio" onChange={e => setMerchantComissionPaysReplenish(e.target.value)}>
            <Radio checked={merchantComissionPaysReplenish === 'buyer'} value="buyer">
              {t('merchants.customer')}
            </Radio>
            <Radio checked={merchantComissionPaysReplenish === 'merchant'} value="merchant">
              {t('merchants.me')}
            </Radio>
          </Radio.Group>
        </Form.Item>

        {/* withdrawal */}
        <Form.Item label={
          <div>
            {t('merchants.merchantFee')}
            <Popover
              overlayClassName="popover__wrapper"
              className="modal-merchants__popover"
              placement="rightTop"
              content={<p className="password-strength__popover-text">{t('merchants.whoPaysComissionReplenishTooltip')}</p>}
            >
              <img className="password-strength__popover-icon" src={helpCircleIcon} alt="Help popup" />
              <img className="password-strength__popover-icon_active" src={helpCircleActiveIcon} alt="Help popup" />
            </Popover>
          </div>
        } name="merchantFee" className="modal-form__label" onClick={() => setIsOpenedDropdownCurrency(false)}>
          <InputNumber
            placeholder="0"
            min={0}
            max={100}
            onChange={value => setMerchantFee(value > 100 ? 100 : value)}
            className="modal-form__input"
          />
        </Form.Item>

        {/* replenishment */}
        <Form.Item
          label={t('merchants.merchantFeeReplenish')}
          name="merchantFeeReplenish"
          className="modal-form__label"
          onClick={() => setIsOpenedDropdownCurrency(false)}
        >
          <InputNumber
            placeholder="0"
            min={0}
            max={100}
            onChange={value => setMerchantFeeReplenish(value > 100 ? 100 : value)}
            className="modal-form__input"
          />
        </Form.Item>

        <p className={`modal-form__description modal-form__description-${identity}`}>{t('merchants.commissionDescription')}</p>

        {/* <div className="form-create-merchant__comission-info">{t('merchants.systemFee')}</div> */}
        {identity === 'cpay' && (
          <a
            href="https://docs.cpay.world/for-developers/general-concepts-about-cpay/"
            target="_blank"
            rel="noopener noreferrer"
            className="form-create-merchant__fee-docs"
          >
            {t('merchants.feeDocs')}
          </a>
        )}

        {identity === 'finvaro' && (
          <a
            href="https://docs.finvaro.com/for-developers/general-concepts-about-finvaro/"
            target="_blank"
            rel="noopener noreferrer"
            className="form-create-merchant__fee-docs-finvaro"
          >
            {t('merchants.feeDocs')}
          </a>
        )}

        {identity === 'clarnium' && (
          <a
            href="https://docs.clarnium.io/for-developers/general-concepts-about-clarnium/"
            target="_blank"
            rel="noopener noreferrer"
            className="form-create-merchant__fee-docs-clarnium"
          >
            {t('merchants.feeDocs')}
          </a>
        )}

        <Form.Item name="merchantNetwork" className="modal-form__label" onClick={() => setIsOpenedDropdownCurrency(false)}>
          <Select onChange={value => setMerchantNetwork(value)}>
            <Select.Option value="testnet">{t('testnet')}</Select.Option>
            <Select.Option value="mainnet">{t('mainnet')}</Select.Option>
          </Select>
        </Form.Item>
      </Form>
      <Row className="modal__buttons-wrapper merchant-buttons" onClick={() => setIsOpenedDropdownCurrency(false)}>
        <Col span="12" onClick={() => setIsOpenedDropdownCurrency(false)}>
          <Button
            type="primary"
            className={`modal__button modal__button-create modal__button modal__button-create-${identity} modal__button-create=${identity}`}
            loading={isFetching}
            onClick={!isFetching && handleOk}
          >
            {t('create')}
          </Button>
        </Col>
        <Col span="12">
          <Button type="primary" className="modal__button modal__button-cancel" onClick={handleCancel}>
            {t('cancel')}
          </Button>
        </Col>
      </Row>
      </div>
    </Modal>
  );
};

const mapStateToProps = state => ({
  isFetching: state.merchants.fetching,
  currencies: state.currencies.data,
});

const mapDispatchToProps = dispatch => ({
  createMerchant: createMerchant(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(MerchantCreateModal);
