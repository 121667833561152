import React from 'react';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import './style.scss';
import { identity } from '../../../utils/getIdentity';
import ReactPaginate from 'react-paginate';
import '../../Pagination/Pagination.scss';


const CheckoutsPagination = ({ page, pages, search, countItem, limit, getCheckoutListHandler, getPlanListHandler, type }) => {
  const { t } = useTranslation();

  const handlePageClick = (selectedPage) => {
    const pageNumber = selectedPage.selected + 1;
    if (type === 'plan') {
      getPlanListHandler(pageNumber, search);
    } else {
      getCheckoutListHandler(pageNumber, search);
    }
  };

  return countItem ? (
    <div className="checkouts-pagination">
      <Row align="middle">
        <Col span={2}>
          <div className="checkouts-pagination__position">
            <span>{t('pageCurrentOfTotal', { page, pages })}</span>
          </div>
        </Col>
        <Col span={20} className={`checkouts-pagination__wrapper checkouts-pagination__wrapper-${identity}`}>
          <ReactPaginate
            pageCount={pages}
            pageRangeDisplayed={5}
            marginPagesDisplayed={2}
            onPageChange={handlePageClick}
            containerClassName="pagination"
            activeClassName="active"
            previousLabel={"«"}
            nextLabel={"»"}
            breakLabel={"..."}
            forcePage={page - 1}
          />
        </Col>
      </Row>
    </div>
  ) : (
    ''
  );
};

export default CheckoutsPagination;
